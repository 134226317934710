import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://passby.com/wp-content/uploads/2023/08/logo-tight.png"
          className="w-full h-full object-contain"
          alt="pass_by Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            pass_by
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Geospatial Analytics</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://passby.com/" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Valuation Icon"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$2.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Latest Raise Icon"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">0.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Raised To Date Icon"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$0.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Funding Stage Icon"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about3A');
  };

  const navigateToScanPage = () => {
    navigate('/scan3A');
  };

  const navigateToContactPage = () => {
    navigate('/contact3A');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Advantage", "Barriers", "Potential", "Regulation"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col px-5 mb-10 text-xl font-bold leading-8 max-w-[841px] text-slate-800" style={{ alignSelf: 'center' }}>
      <div id="barriers" className="self-center tracking-normal max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          In the geospatial insights market, pass_by faces several barriers to entry that can potentially hinder new startups from successfully entering the market. One significant barrier is the high level of technological expertise required to develop and maintain AI-powered geospatial platforms like pass_by. Developing algorithms for geospatial data analysis, predictive analytics, and ground truth validation demands a deep understanding of artificial intelligence and geospatial science, which can be challenging for new entrants without prior experience or expertise.
        </p>
        <p>
          Moreover, pass_by has established a strong reputation for accuracy and reliability in delivering geospatial data. This reputation acts as a barrier for new entrants as building trust with customers and ensuring data quality comparable to pass_by's gold standard can be time-consuming and resource-intensive. Additionally, pass_by's extensive historical data access, including over 5 years of data, provides a competitive advantage that new startups would find difficult to replicate quickly.
        </p>
        <p>
          Regulatory considerations are also crucial for startups looking to enter the geospatial insights market. Data privacy laws, such as GDPR and CCPA, impose strict requirements on the collection, processing, and storage of location data. Compliance with these regulations requires significant investment in data security measures and legal expertise, posing a barrier for new entrants without the necessary resources.
        </p>
        <p>
          Furthermore, pass_by's partnerships with established companies like RetailNext and Clear Channel enhance its market position and create barriers for new entrants seeking to establish similar collaborations. These partnerships provide pass_by with access to a wider customer base and industry expertise, making it challenging for new startups to compete on the same level without comparable partnerships.
        </p>
        <p>
          Overall, the combination of technological expertise, reputation for data accuracy, regulatory compliance, and strategic partnerships presents formidable barriers to entry for new startups in the geospatial insights market, potentially limiting competition and consolidating pass_by's position as a market leader.
        </p>
      </div>

      <div id="advantage" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          pass_by is an AI-powered geospatial insights platform that revolutionizes how businesses understand and predict real-world behaviors and movements. The company offers products like almanac and foot_traffic, providing geospatial intelligence and predictive insights to aid in resource planning and investment decisions.
        </p>
        <p>
          pass_by prides itself on delivering data with exceptional accuracy and reliability. The meticulous approach to data validation ensures that clients have access to a gold standard in geospatial information, enabling them to make informed decisions based on trustworthy data. This commitment to data quality sets pass_by apart from competitors and enhances its competitive advantage.
        </p>
        <p>
          pass_by's products, such as almanac and foot_traffic for finance, offer unique solutions for various industries, including retail and finance. The platform provides structured large-scale geospatial data feeds, enabling enhanced real-world analysis with billions of daily data points. The ability to predict consumer demand and measure retail performance accurately gives pass_by a competitive edge in the market.
        </p>
        <p>
          pass_by has established strategic partnerships with industry leaders like RetailNext, enhancing its capabilities in providing in-store analytics and AI-powered predictions for retailers. Collaborations with renowned brands and organizations showcase pass_by's credibility and expertise in the geospatial data industry, further strengthening its competitive advantage.
        </p>
        <p>
          pass_by has made a significant impact on businesses by transforming operations and informing critical decisions, from optimizing retail store locations to guiding financial strategies. The company's innovative approach to geospatial data analysis, predictive insights, and AI-powered solutions positions it as a leader in the industry, driving growth and innovation for its clients.
        </p>
        <p>
          Overall, pass_by's competitive advantage lies in its commitment to data accuracy, innovative product offerings, strategic partnerships, and industry impact. By providing reliable geospatial intelligence, predictive insights, and actionable data, pass_by empowers businesses to make informed decisions and stay ahead of the competition in understanding and predicting real-world behaviors and movements.
        </p>
      </div>

      <div id="potential" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          The exit potential for pass_by, an AI-powered geospatial insights company, appears to be promising based on the information provided. The company offers innovative products such as almanac and mobility_events that provide geospatial intelligence to businesses, enabling them to understand and predict real-world behaviors and movements. With a focus on accuracy and reliability in data validation, pass_by has positioned itself as a trusted partner for businesses seeking actionable insights for growth and innovation.
        </p>
        <p>
          One key indicator of the company's potential for exit is its strategic partnerships and collaborations. For example, pass_by recently announced a partnership with RetailNext, a leading in-store analytics provider used by over 560 top retail brands. This partnership enhances pass_by's capabilities in providing comprehensive insights to retailers, which could attract potential acquirers or investors looking to tap into the retail analytics market.
        </p>
        <p>
          Additionally, pass_by's expansion into the finance sector with its foot_traffic for Finance Dataset further diversifies its customer base and revenue streams. The dataset enables financial institutions to measure and predict retail performance accurately, offering valuable insights for investment decisions. This move could attract interest from financial technology companies or investors looking to leverage geospatial data for financial analysis and decision-making.
        </p>
        <p>
          Furthermore, pass_by's focus on data accuracy, extensive brand coverage, and historical data access could make it an attractive target for acquisition by larger technology companies or data analytics firms seeking to enhance their geospatial capabilities.
        </p>
        <p>
          In conclusion, pass_by's strong market positioning, strategic partnerships, and expansion into new sectors indicate a favorable exit potential. Potential exit routes for the company could include acquisition by a larger technology or data analytics firm, strategic partnerships with industry leaders, or a successful initial public offering (IPO) to capitalize on its innovative geospatial intelligence offerings.
        </p>
      </div>
    </div>
  );
}


function Scan2() {
  return (
    <div className="flex flex-col px-5 mb-10 text-xl font-bold leading-8 max-w-[841px] text-slate-800" style={{ alignSelf: 'center' }}>
      <div id="founders" className="self-center tracking-normal max-md:max-w-full">
        Founders
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          The founding team of pass_by comprises visionary individuals with diverse expertise, driving the company's success in revolutionizing geospatial intelligence. Sam Amrani, the Founder and CEO, is a prominent figure in the industry, recognized as a Forbes 30 under 30. His leadership and strategic vision have been instrumental in shaping pass_by's trajectory. Amrani's commitment to innovation and excellence is evident in the company's focus on delivering accurate and reliable geospatial data.
        </p>
        <p>
          Joining Amrani is a team of seasoned professionals, including investors and advisors such as Rob Lowe and Brent Hoberman, CBE, who bring a wealth of experience and strategic insights to the table. Their involvement underscores pass_by's commitment to excellence and industry leadership.
        </p>
        <p>
          The team's collective efforts have led to significant impact across various sectors, from optimizing retail store locations to informing high-stakes financial decisions. Anecdotal evidence, such as the partnership with RetailNext, showcases pass_by's ability to provide actionable insights that drive growth and innovation in the retail sector.
        </p>
        <p>
          Moreover, pass_by's recent announcement of the updated Foot Traffic for Finance Dataset highlights the team's dedication to staying at the forefront of the industry. The dataset's extensive coverage of brands and historical data availability further demonstrates the team's commitment to providing cutting-edge solutions to clients.
        </p>
        <p>
          Overall, the founding team of pass_by embodies a blend of expertise, innovation, and strategic foresight, positioning the company as a leader in the geospatial intelligence space. Their collective efforts have not only transformed businesses but also set new standards for accuracy and reliability in geospatial data analytics.
        </p>
      </div>

      <div id="finances" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Finances
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          pass_by, an AI-powered geospatial insights company, has made significant strides in the finance sector with its innovative offerings. The company recently introduced its updated Foot Traffic for Finance Dataset, which provides daily data delivery with minimal time lag, covering data on 6,840 brands and 1,039 publicly traded companies. This dataset is mapped to 489 stock tickers, offering historical data access of over five years starting from January 2019. This initiative showcases pass_by's commitment to delivering accurate and reliable geospatial information to aid financial institutions in measuring and predicting retail performance.
        </p>
        <p>
          Moreover, pass_by's foot_traffic for finance leverages mobile location data and ground truth validation from in-store sensors to provide accurate footfall data, enabling predictions up to 90 days into the future for almost 2 million US retail locations. This comprehensive approach to data collection and analysis positions pass_by as a leader in geospatial intelligence, empowering businesses to make informed decisions based on trustworthy data.
        </p>
        <p>
          The company's partnership with RetailNext further enhances its capabilities in providing in-store analytics to over 560 top retail brands, showcasing pass_by's commitment to driving growth and innovation in the retail sector. By offering unique insights into consumer behavior and retail performance, pass_by is instrumental in transforming the operations of businesses and informing critical financial decisions.
        </p>
        <p>
          Overall, pass_by's focus on delivering high-quality geospatial data, its strategic partnerships, and innovative solutions for the finance sector underscore its financial stability and growth potential. The company's ability to provide actionable insights based on accurate data sets it apart in the geospatial intelligence industry, positioning it as a trusted partner for businesses seeking to understand and predict real-world behaviors and movements.
        </p>
      </div>

      <div id="regulation" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          Industry regulations play a crucial role in shaping the operations and compliance requirements for companies like pass_by, an AI-powered geospatial insights platform. As a startup operating in the data and analytics industry, pass_by must navigate various regulatory considerations to ensure legal compliance and data integrity.
        </p>
        <p>
          One key regulatory consideration for pass_by is data privacy laws, such as the General Data Protection Regulation (GDPR) in the European Union and the California Consumer Privacy Act (CCPA) in the United States. These regulations govern how companies collect, store, process, and share personal data, imposing strict requirements on data handling practices to protect consumer privacy.
        </p>
        <p>
          Additionally, as pass_by deals with geospatial data and consumer behavior insights, it must also adhere to location-based services regulations and guidelines. These regulations ensure that companies obtain proper consent before collecting location data from users and use it responsibly to avoid privacy violations.
        </p>
        <p>
          Furthermore, given pass_by's focus on the finance sector, it is essential for the startup to comply with industry-specific regulations, such as those set by financial regulatory bodies like the Securities and Exchange Commission (SEC) in the U.S. These regulations aim to safeguard financial data, prevent fraud, and ensure transparency in financial transactions.
        </p>
        <p>
          Moreover, pass_by's partnership with RetailNext and its involvement in retail analytics may subject the company to additional industry-specific regulations related to consumer data protection, marketing practices, and competition laws.
        </p>
        <p>
          In conclusion, pass_by must proactively address and comply with a complex web of industry regulations to operate ethically, protect consumer data, and maintain trust with its clients. By staying informed about evolving regulatory landscapes and implementing robust compliance measures, pass_by can continue to revolutionize geospatial insights while upholding legal standards and best practices in the data and analytics industry.
        </p>
      </div>
    </div>
  );
}



  

function Scan3() {
  return (
    <div className="flex flex-col px-5 mb-10 text-xl font-bold leading-8 max-w-[841px] text-slate-800" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="self-center tracking-normal max-md:max-w-full">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          Pass_by is a groundbreaking startup that is revolutionizing the geospatial intelligence industry with its AI-powered geospatial insights platform. The company offers innovative products such as almanac and mobility_events that provide businesses with instant insights on over 2 million points of interest and structured large-scale geospatial data feeds, respectively. Pass_by's meticulous approach to data validation ensures high accuracy and reliability, making it a trusted source for geospatial information.
        </p>
        <p>
          One of the key innovations of pass_by is its foot_traffic for Finance dataset, which enables financial institutions to accurately measure and predict retail performance for almost 2 million US retail locations, covering 1,039 publicly traded retail brands. This dataset combines mobile location data with ground truth validation from in-store sensors to provide the most accurate footfall data in the market and predict it up to 90 days into the future. The dataset includes extensive brand coverage, historical data access, and mapping to stock tickers, offering valuable insights for financial decision-making.
        </p>
        <p>
          Pass_by's partnership with RetailNext further enhances its capabilities by providing in-store analytics used by over 560 top retail brands. This partnership equips retailers with comprehensive insights to understand current and historical trends, unlock 90-day forecasts, and gain deeper insights into their competition. The collaboration showcases pass_by's commitment to driving growth and innovation in the retail industry through data-driven solutions.
        </p>
        <p>
          Overall, pass_by's innovative approach to geospatial intelligence, its focus on accuracy and reliability, and its strategic partnerships demonstrate its commitment to transforming businesses by providing actionable insights that drive growth and innovation. The company's unique technology aspects, such as its advanced data validation processes and predictive analytics capabilities, position it as a leader in the geospatial insights space.
        </p>
      </div>

      <div id="market" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Market Size
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          The global data analytics market size was USD 30 billion in 2022 and is projected to reach around USD 393.35 billion by 2032, with a projected CAGR of 29.4% during the forecast period. The market is witnessing significant growth due to various factors such as the increasing trend of edge computing, IoT device connectivity, machine learning algorithms, the internet of things, and artificial intelligence. Edge computing allows for real-time data processing and faster response times, particularly in the industrial IoT sector. However, the market faces challenges related to growing security concerns, including real-time security, data privacy, and client data security.
        </p>
        <p>
          The market offers opportunities driven by the rising consumption of data, propelled by advancements in mobile technology like smartphones, tablets, mobile networks, and Wi-Fi. In 2022, large companies held over 60% of the market share, making them the dominant players in the data analytics market. Key companies in the market include Alteryx, IBM Corporation, SiSense Inc, Microsoft, Zoho Corporation Pvt, Datameer, and SAS Institute Inc.
        </p>
        <p>
          The startup, pass_by, operates in the geospatial insights and predictive analytics space, offering AI-powered solutions to businesses for understanding and predicting real-world behaviors and movements. The startup's focus on geospatial data aligns with the broader trends in the data analytics market, where companies are increasingly relying on data-driven insights for decision-making.
        </p>
        <p>
          pass_by's partnership with RetailNext and its foot traffic data offerings for finance showcase the startup's capabilities in providing accurate and reliable data for retail and financial institutions. The market size projection for data analytics indicates a growing demand for data-driven solutions, presenting a favorable environment for pass_by to expand its offerings and capture a larger market share.
        </p>
        <p>
          As the market continues to evolve with advancements in technology and increasing data consumption, pass_by can leverage its expertise in geospatial data to capitalize on the growing opportunities in the data analytics market. By staying innovative, maintaining data accuracy, and adapting to market trends, pass_by can position itself as a key player in the competitive landscape of the data analytics industry.
        </p>
      </div>

      <div id="market" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Market Trends
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        <p>
          pass_by is an AI-powered geospatial insights platform that focuses on revolutionizing how businesses understand and predict real-world behaviors and movements. The company offers products like almanac, foot_traffic, and predictive solutions tailored for various industries such as retail, commercial real estate, and finance. pass_by's data is meticulously validated for accuracy and reliability, providing clients with actionable insights for decision-making.
        </p>
        <p>
          One significant trend in the market is the increasing demand for geospatial data and predictive analytics. Companies are leveraging AI and geospatial insights to optimize operations, enhance consumer understanding, and drive growth. pass_by's partnership with RetailNext, a leading in-store analytics provider, showcases the importance of combining geospatial data with retail analytics to offer comprehensive insights to retailers.
        </p>
        <p>
          Another trend is the use of alternative data sources, such as mobile location data, to measure and predict consumer behavior accurately. pass_by's foot_traffic for Finance Dataset, with extensive brand coverage and historical data access, enables financial institutions to make informed decisions based on real-time insights. This trend highlights the shift towards data-driven decision-making in the finance industry.
        </p>
        <p>
          The market trends indicate a growing reliance on geospatial data and AI-powered analytics across industries. For pass_by, these trends present both opportunities and challenges. By continuing to provide accurate and reliable geospatial insights, the company can capitalize on the increasing demand for data-driven solutions in retail, finance, and other sectors. Collaborations with industry leaders like RetailNext further enhance pass_by's credibility and market presence.
        </p>
        <p>
          However, the market is becoming more competitive, with the emergence of new players offering similar geospatial intelligence solutions. To stay ahead, pass_by must continue to innovate, expand its product offerings, and maintain a strong focus on data accuracy and validation. By adapting to market trends and addressing evolving customer needs, pass_by can solidify its position as a trusted provider of geospatial insights and predictive analytics.
        </p>
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;