
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/search')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}



function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">Salekh Mahmood</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}



function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/searchpage')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Filters
      </div>
    </div>
  );
}



function InvestmentOverTime() {
  return (
    <div className="flex flex-col items-center px-5 mt-8 max-w-[900px] bg-slate-100">
      <div className="text-sm font-bold text-center text-zinc-800">
        Investment Over Time
      </div>
      <div className="flex gap-3 items-center mt-6 text-xs text-center whitespace-nowrap text-zinc-500">
        <div className="self-stretch my-auto">1M</div>
        <div className="shrink-0 self-stretch w-px h-5 border border-solid bg-zinc-500 bg-opacity-50 border-zinc-500 border-opacity-50" />
        <div className="self-stretch my-auto text-teal-500">6M</div>
        <div className="shrink-0 self-stretch w-px h-5 border border-solid bg-zinc-500 bg-opacity-50 border-zinc-500 border-opacity-50" />
        <div className="self-stretch my-auto">1Y</div>
        <div className="shrink-0 self-stretch w-px h-5 border border-solid bg-zinc-500 bg-opacity-50 border-zinc-500 border-opacity-50" />
        <div className="self-stretch my-auto">5Y</div>
        <div className="shrink-0 self-stretch w-px h-5 border border-solid bg-zinc-500 bg-opacity-50 border-zinc-500 border-opacity-50" />
        <div className="self-stretch my-auto">Max</div>
      </div>
      <div className="flex gap-5 self-stretch mt-4 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col grow shrink-0 self-end mt-8 basis-0 w-fit max-md:max-w-full">
          <div className="flex gap-5 justify-between items-end max-md:flex-wrap">
            <div className="shrink-0 mt-32 bg-teal-500 rounded-md h-[105px] w-[77px] max-md:mt-10" />
            <div className="shrink-0 mt-8 bg-teal-500 rounded-md h-[203px] w-[78px]" />
            <div className="shrink-0 mt-40 bg-teal-500 rounded-md h-[76px] w-[76px] max-md:mt-10" />
            <div className="shrink-0 self-stretch bg-teal-500 rounded-md h-[235px] w-[77px]" />
            <div className="shrink-0 mt-12 bg-teal-500 rounded-md h-[185px] w-[77px] max-md:mt-10" />
            <div className="shrink-0 mt-36 bg-teal-500 rounded-md h-[84px] w-[78px] max-md:mt-10" />
          </div>
          <div className="flex gap-5 mx-6 mt-3.5 text-xs text-center whitespace-nowrap text-zinc-500 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full" style={{ justifyContent: "space-between" }}>
            <div>Oct</div>
            <div>Nov</div>
            <div>Dec</div>
            <div>Jan</div>
            <div>Feb</div>
            <div>Mar</div>
          </div>
        </div>
        <div className="flex gap-1.5 items-start whitespace-nowrap">
          <div className="flex flex-col self-end mt-36 text-xs text-center text-zinc-500 max-md:mt-10">
            <div className="shrink-0 bg-teal-500 rounded-md h-[120px]" />
            <div className="self-center mt-4">April</div>
          </div>
          <div className="flex flex-col self-start text-xs font-light text-right text-slate-800 max-md:hidden">
            <div>70m</div>
            <div className="mt-8">60m</div>
            <div className="mt-8">50m</div>
            <div className="mt-8">40m</div>
            <div className="mt-8">30m</div>
            <div className="mt-8">20m</div>
            <div className="mt-8">10m</div>
            <div className="self-start mt-8 ml-3.5 max-md:ml-2.5">0</div>
          </div>
        </div>
      </div>
    </div>
  );
}




function Companies() {
  const navigate = useNavigate();
  const initialSavedState = JSON.parse(localStorage.getItem('savedCompanies')) || new Array(3).fill(false);
  const [saved, setSaved] = useState(initialSavedState);

  useEffect(() => {
    localStorage.setItem('savedCompanies', JSON.stringify(saved));
  }, [saved]);

  const handleSave = (index) => {
    const newSaved = [...saved];
    newSaved[index] = !newSaved[index];
    setSaved(newSaved);
  };

  return (
    <div className="flex flex-col px-5 mt-4 max-w-[800px] bg-slate-100">
      <div className="self-start text-lg font-bold text-gray-950">
        Companies (230)
      </div>
      <div className="flex gap-4 pt-4 -mt-1 w-full max-md:flex-wrap max-md:max-w-full">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow pt-6 pr-4 pl-4 pb-2 rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-4">
              <img
                loading="lazy"
                src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${index === 0 ? "a5c3893348c0467618968c24121eeb63bab74f9893bc894d2c6b5dac4d899b1d" : index === 1 ? "114cbefc00a0a4c0e20094b7a22351234c0d9b2a4556f80d7a4930a66cb35d74" : "111d36319abce3b6f44654482b7c19ed5eb3b0d0ec19683435babf6e1e87e4ec"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
                className="aspect-[3.23] w-[120px] max-w-full"
              />
              <div className="mt-6 text-xl font-semibold tracking-normal leading-9 text-slate-800">
                {index === 0 ? "Biotechnology" : index === 1 ? "FinTech" : "Consumer"}
              </div>
              <div className="flex gap-4 mt-6">
                <button
                  className="flex-1 justify-center px-4 py-3 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-full text-slate-800 cursor-pointer"
                  style={{ minWidth: '120px' }}
                  onClick={() => navigate(`/deep-dive/${index}`)}
                >
                  Deep Dive
                </button>
                <button
                  className="flex justify-center items-center p-3 bg-white rounded-full w-[52px] cursor-pointer"
                  onClick={() => handleSave(index)}
                >
                  <img
                    loading="lazy"
                    src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${saved[index] ? "00541e27c428c910cf25e81d02549dbd5c0aa876fd5dfe1b0d25e289a9f2cb71" : "d01a82025f07e0184772b673b93e19292be5681e5e8f4c733522c87c24cbbb1d"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
                    className={`w-7 aspect-[1.12] stroke-[1.5px] ${saved[index] ? 'stroke-red-500' : 'stroke-gray-700'}`}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          className="justify-center px-2 py-2 my-auto text-lg font-medium leading-7 text-gray-700 bg-white rounded-lg border-0 border border-solid shadow-sm max-md:px-2 cursor-pointer"
          style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}
          onClick={() => navigate('/companysearch')}
        >
          See All
        </button>
      </div>
    </div>
  );
}




function Investors() {
  const navigate = useNavigate();
  const initialSavedState = JSON.parse(localStorage.getItem('savedInvestors')) || new Array(3).fill(false);
  const [saved, setSaved] = useState(initialSavedState);

  useEffect(() => {
    localStorage.setItem('savedInvestors', JSON.stringify(saved));
  }, [saved]);

  const handleSave = (index) => {
    const newSaved = [...saved];
    newSaved[index] = !newSaved[index];
    setSaved(newSaved);
  };

  return (
    <div className="flex flex-col px-5 mt-4 max-w-[800px] bg-slate-100">
      <div className="self-start text-lg font-bold text-gray-950">
        Investors (6)
      </div>
      <div className="flex gap-4 pt-4 -mt-1 w-full max-md:flex-wrap max-md:max-w-full">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow pt-6 pr-4 pl-4 pb-2 rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-4">
              <img
                loading="lazy"
                src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${index === 0 ? "1910f413d8cadf488cbd871e222cfecbef54385b5c88cbc31254298c33f26226" : index === 1 ? "6d06ec7f3d8faef4e943dedbb55156ed1922d1550d0221594f1d38ed70fa353d" : "13e71bad72cbf32c2dea79f1e1622cf60bec8a2d7a250c474f49213d8f5cbb65"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
                className="aspect-[3.23] w-[120px] max-w-full"
              />
              <div className="mt-6 text-xl font-semibold tracking-normal leading-9 text-slate-800">
                {index === 0 ? "Growth Stage" : index === 1 ? "Pre-Seed" : "Late Stage"}
              </div>
              <div className="flex gap-4 mt-6">
                <button
                  className="flex-1 justify-center px-4 py-3 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-full text-slate-800 cursor-pointer"
                  style={{ minWidth: '120px' }}
                  onClick={() => navigate(`/investor-details/${index}`)}
                >
                  Deep Dive
                </button>
                <button
                  className="flex justify-center items-center p-3 bg-white rounded-full w-[52px] cursor-pointer"
                  onClick={() => handleSave(index)}
                
                >
                  <img
                    loading="lazy"
                    src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${saved[index] ? "00541e27c428c910cf25e81d02549dbd5c0aa876fd5dfe1b0d25e289a9f2cb71" : "d01a82025f07e0184772b673b93e19292be5681e5e8f4c733522c87c24cbbb1d"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
                    className={`w-7 aspect-[1.12] stroke-[1.5px] ${saved[index] ? 'stroke-red-500' : 'stroke-gray-700'}`}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          className="justify-center px-2 py-2 my-auto text-lg font-medium leading-7 text-gray-700 bg-white rounded-lg border-0 border border-solid shadow-sm max-md:px-2 cursor-pointer"
          style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}
          onClick={() => navigate('/investorsearch')}
        >
          See All
        </button>
      </div>
    </div>
  );
}



function Rankings() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col text-xs font-bold max-w-[241px] text-zinc-500" style={{ marginTop: '4cm' }}>
      <div className="flex flex-col py-5 pl-3 w-full rounded-lg bg-slate-100">
        <div className="text-sm text-center text-zinc-800">Top Companies</div>
        {[
          { name: "Amazon.com, Inc.", ticker: "AMZN", image: "59d2a0084288897521a62b2212d68d6bdfb491bb771a7baa2614e37ba97d759a" },
          { name: "Coca-Cola Co", ticker: "KO", image: "45cd69aaa94396dee9a58d38f24592ff703294447a0fe00567f3ddf84a71fe7c" },
          { name: "Bayerische Motoren Werke AG", ticker: "BMW", image: "244389a1036f563f6329791b463ee91ed0d97d7d35407ef9ea0caae580f57d0a" },
          { name: "Microsoft Corp", ticker: "MSFT", image: "e977bddd4031da39bdddfcae20ea5f16ea5c0d84de8ab33c241b6e37f971cb8b" },
          { name: "United Parcel Service, Inc.", ticker: "UPS", image: "914e72521501328dd35584aa1d2428ae2b8441f1b2c36eb40f6d3b9463d26958" },
          { name: "Mastercard Inc", ticker: "MA", image: "8bd890bc8f56d2143df87bf562877966b2db2d45028491a8e82ed6adc5034959" }
        ].map((company, index) => (
          <div key={index} className="flex gap-1.5 mt-4 cursor-pointer" onClick={() => navigate(`/specificresult`)}>
            <img
              loading="lazy"
              src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${company.image}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div>
              {company.name}
              <br />
              <span className="text-zinc-500">{company.ticker}</span>
            </div>
          </div>
        ))}
        <div className="shrink-0 mt-4 h-px bg-violet-100 border border-violet-100 border-solid" />
        <div className="text-sm text-center text-zinc-800 mt-5">Top Investors</div>
        {[
          { name: "Berkshire Hathaway", ticker: "BRK.A", image: "f1ada83b2263996b09b2f9ed1b045d8579dda153d483573592d6e634f886aa8c" },
          { name: "Vanguard Group", ticker: "VGHCX", image: "45cd69aaa94396dee9a58d38f24592ff703294447a0fe00567f3ddf84a71fe7c" },
          { name: "BlackRock", ticker: "BLK", image: "9f70fadbe6c723172d6a0c5455d1982e18cf3acfd29aee6d10b95d4518c25bdd" },
          { name: "State Street Corporation", ticker: "STT", image: "20ec931a55790dd73487d14e4cbe91f9b2c2d6f091bfcf6ea58a670fc2599638" },
          { name: "Fidelity Investments", ticker: "FNF", image: "914e72521501328dd35584aa1d2428ae2b8441f1b2c36eb40f6d3b9463d26958" }
        ].map((investor, index) => (
          <div key={index} className="flex gap-1.5 mt-4 cursor-pointer" onClick={() => navigate(`/specificresult/${investor.ticker}`)}>
            <img
              loading="lazy"
              src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${investor.image}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div>
              {investor.name}
              <br />
              <span className="text-zinc-500">{investor.ticker}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


function ResultsPage() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
          <div className="flex justify-between">
            <SearchBar />
            <Name />
          </div>
        <BackTo />
        <InvestmentOverTime />
        <Companies />
        <Investors />
      </div>
      <div className="flex flex-col w-[241px] absolute right-0" style={{ top: 'calc(100px + 4cm)' }}> 
        <Rankings />
      </div>
    </div>
  );
}





export default ResultsPage;


