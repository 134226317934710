import * as React from "react";

function ForgotPassword() {
  return (
    <div className="flex flex-col items-center pt-10 bg-indigo-50">
      <div className="flex gap-5 px-5 w-full text-lg max-w-[1229px] max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto text-4xl font-bold text-teal-500 lowercase">
          scoutdd
        </div>
        <div className="flex gap-5 justify-between my-auto text-black max-md:flex-wrap max-md:max-w-full">
          <div>Products</div>
          <div>About Us</div>
          <div>Tour</div>
          <div>Get in Touch</div>
        </div>
        <div className="flex gap-5 self-start text-center leading-[135%]">
          <div className="flex flex-col justify-center font-semibold text-white bg-teal-500">
            <div className="justify-center px-7 py-2.5 rounded-md border border-teal-500 border-solid max-md:px-5">
              Sign Up
            </div>
          </div>
          <div className="flex flex-col justify-center text-teal-500">
            <div className="justify-center px-9 py-2.5 rounded-md border border-teal-500 border-solid max-md:px-5">
              Log In
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-5 justify-between pr-20 mt-80 max-w-full w-[1022px] max-md:flex-wrap max-md:pr-5 max-md:mt-10">
        <div className="flex justify-center items-center self-end px-6 py-4 mt-12 border border-solid border-zinc-400 rounded-[96px] max-md:mt-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/60077fd11167c9afa8f0698ea4a34c166540ece583efff6b2d108dc952320f8a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="w-5 aspect-square"
          />
        </div>
        <div className="flex flex-col px-16 text-center max-md:px-5 max-md:max-w-full">
          <div className="text-3xl font-semibold tracking-tighter leading-10 text-slate-800 max-md:max-w-full">
            Forgot Password?
          </div>
          <div className="mt-3 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:max-w-full">
            Enter the email address you used when you joined, and we'll send you
            instructions to reset your password.
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center px-16 mt-8 max-w-full text-base tracking-normal leading-6 rounded-3xl w-[552px] max-md:px-5">
        <div className="font-semibold text-gray-600 max-md:max-w-full">
          Email
        </div>
        <div className="shrink-0 mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full" />
        <div className="justify-center items-center px-8 py-4 mt-6 font-medium text-right text-white bg-teal-500 rounded-[96px] max-md:px-5 max-md:max-w-full">
          Send Password Reset Link
        </div>
      </div>
      <div className="flex justify-center items-center self-stretch px-16 py-10 mt-80 w-full text-teal-500 bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col w-full max-w-[1062px] max-md:max-w-full">
          <div className="flex z-10 gap-5 w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
            <div className="flex-auto self-start mt-3.5 text-4xl font-bold lowercase">
              scoutdd
            </div>
            <div className="flex gap-5 justify-between bg-white">
              <div className="flex flex-col text-lg tracking-tight leading-7">
                <div className="text-2xl font-medium tracking-tighter leading-8">
                  Menu
                </div>
                <div className="mt-6">Home</div>
                <div className="mt-6">About</div>
              </div>
              <div className="flex flex-col self-start text-base tracking-tight">
                <div className="text-2xl font-medium tracking-tighter leading-8">
                  Contact
                </div>
                <div className="mt-6">London</div>
                <div className="mt-4">info@scoutdd.com</div>
              </div>
            </div>
          </div>
          <div className="items-center px-16 pt-8 text-base font-medium tracking-normal leading-6 max-md:px-5 max-md:max-w-full">
            © 2023 scoutdd. All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;