import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearch')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://framerusercontent.com/images/DJHjD5SmUc89JSBdK8ehXziRGZk.png?scale-down-to=512"
          className="w-full h-full object-contain"
          alt="Upsolve AI Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            Upsolve AI
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <div>Customer Analytics</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <a href="https://framerusercontent.com/images/DJHjD5SmUc89JSBdK8ehXziRGZk.png?scale-down-to=512" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$2.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$0.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$0.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}



function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about2A');
  };

  const navigateToScanPage = () => {
    navigate('/scan2A');
  };

  const navigateToContactPage = () => {
    navigate('/contact2A');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}
function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Advantage", "Barriers", "Potential", "Regulation", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}

function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="founders" className="self-center tracking-normal">
        Founding Team
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>The founding team of Upsolve AI consists of two key members, Ka Ling Wu and Serguei Balanovich, who bring a wealth of experience and expertise to the startup. Ka Ling Wu serves as the Co-founder & CEO, bringing her background in product solution development from her previous role at nPlan, a Series A AI & Construction tech startup. Her focus on client expansion and product development showcases her strategic vision and leadership skills in driving business growth.</p>
        <p>Serguei Balanovich, the Co-founder & CTO, has a strong technical background with seven years of experience at Palantir, where he worked on Supply Chain and Manufacturing commercial projects. His ability to pioneer innovative solutions and productize complex work into successful products demonstrates his technical prowess and innovation in the field of data engineering and analytics.</p>
        <p>Together, Ka Ling and Serguei have a combined experience of over 15 years in data engineering and analytics, making them a formidable team in the industry. Their collaboration has led to the creation of Upsolve AI, a customer-facing analytics platform that aims to automate and augment analytics processes for businesses.</p>
        <p>The complementary skill sets of Ka Ling and Serguei position them well to lead Upsolve AI towards success. Ka Ling's expertise in product development and client relations, combined with Serguei's technical acumen and experience in commercializing data solutions, provide a strong foundation for the startup's growth and innovation in the AI analytics space. Their shared vision and dedication to creating a cutting-edge analytics platform make them a dynamic duo poised for success in the industry.</p>
      </div>
  
      <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI's monetization strategy revolves around offering a customer-facing analytics platform powered by AI to businesses. The platform enables companies to build and provide analytics to their customers efficiently and quickly, empowering users to answer data questions through AI technology. The startup leverages a subscription-based model to generate revenue, targeting businesses that require advanced analytics capabilities.</p>
        <p>One key aspect of Upsolve AI's monetization strategy is the pricing model. The company offers different pricing tiers based on the level of service and features required by customers. The pricing ranges from £60 to £110 GBP for the Full Stack Engineer position, with equity options varying from 0.15% to 1.25% based on experience and seniority. This tiered pricing structure allows Upsolve AI to cater to a diverse range of clients while maximizing revenue potential.</p>
        <p>Moreover, Upsolve AI emphasizes the value proposition of its platform, highlighting the ability to automate and augment analytics for businesses. By offering a turnkey customer-facing analytics solution, the startup aims to streamline the analytics process for companies, allowing them to focus on core product development while leaving the analytics to Upsolve AI. This value proposition is crucial in attracting and retaining customers willing to pay for the convenience and efficiency provided by the platform.</p>
        <p>Additionally, Upsolve AI leverages testimonials from satisfied customers to showcase the effectiveness of its platform in enhancing data insights and driving real results. By highlighting success stories and endorsements from industry professionals, the startup builds credibility and trust among potential clients, ultimately driving conversion and revenue growth.</p>
        <p>In conclusion, Upsolve AI's monetization strategy is centered around a subscription-based pricing model, tiered pricing structure, value-driven proposition, and customer testimonials. These elements collectively contribute to the startup's revenue generation efforts and position it as a competitive player in the customer-facing analytics market.</p>
      </div>
      
      <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Market Size
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The global data analytics market size was valued at USD 30 billion in 2022 and is projected to reach around USD 393.35 billion by 2032, with a forecasted CAGR of 29.4% during the period 2023-2032. The market is segmented by type (Big Data Analytics, Business Analytics, Customer Analytics, Risk Analytics, Statistical Analysis, Others), deployment (On-premise, Cloud), enterprise size (Large Enterprises, Small & Medium Enterprises), and end-use industries (BFSI, Government, Healthcare, Military & Defense, Others).</p>
        <p><strong>Key Market Drivers:</strong></p>
        <p>1. **Edge Computing and IoT**: The increasing adoption of edge computing and IoT devices, driven by machine learning algorithms and artificial intelligence, is fueling market growth. Edge computing enables real-time data processing and faster response times, particularly in industrial IoT applications.</p>
        <p><strong>Key Market Challenges:</strong></p>
        <p>1. **Security Concerns**: Growing security challenges, including real-time security needs, data privacy, and client data security, pose significant challenges to the market.</p>
        <p><strong>Key Market Opportunities:</strong></p>
        <p>1. **Rising Data Consumption**: The consumption of data is on the rise due to advancements in mobile technology, such as smartphones and tablets, and improvements in mobile networks and Wi-Fi connectivity.</p>
        <p><strong>Enterprise Size Insights:</strong></p>
        <p>1. **Large Enterprises Dominance**: In 2022, large companies held over 60% of the market share, primarily due to their infrastructure capabilities supporting advanced analytics and the increasing adoption of technologies like AI and machine learning.</p>
        <p>As a customer-facing analytics platform powered by AI, Upsolve AI operates in the broader data analytics market. The significant growth projected in the data analytics sector presents a favorable environment for Upsolve AI's expansion and market penetration. The increasing demand for analytics solutions across various industries, including BFSI, Government, Healthcare, and others, aligns with Upsolve AI's offerings.</p>
        <p>Moreover, the emphasis on edge computing and IoT connectivity aligns with Upsolve AI's focus on providing analytics solutions that leverage AI capabilities to deliver real-time insights. Upsolve AI can capitalize on the rising data consumption trend by offering tailored analytics solutions to meet the evolving needs of businesses.</p>
        <p>However, the company needs to address the growing security concerns in the data analytics space to ensure data privacy and security for its clients. Upsolve AI's ability to adapt to changing market dynamics, capitalize on emerging opportunities, and address key challenges will be crucial for its sustained growth and competitiveness in the dynamic data analytics market landscape.</p>
      </div>
    </div>
  );
}


function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI is a groundbreaking startup that offers a customer-facing analytics platform powered by AI. The company's innovation lies in its ability to provide businesses with a full data stack that enables them to quickly build and offer analytics to their customers. By leveraging AI technology, Upsolve AI empowers customers to answer any data-related questions efficiently.</p>
        <p>One of the key features of Upsolve AI is its AI-powered builder, which allows companies to ship analytics and embed them as native frontend components in their products. This streamlined process enables marketing companies to optimize campaigns and fintech companies to assist users in managing their accounts effectively. By automating and augmenting analytics, Upsolve AI simplifies the visualization creation process, making it easy for users to interact with data and derive actionable insights.</p>
        <p>Upsolve AI's platform also offers deep usage analytics, providing businesses with valuable insights into customer behavior and preferences. This data-driven approach allows companies to focus on developing product features that align with their users' needs and priorities. Additionally, Upsolve AI has received positive feedback from customers, highlighting the platform's ability to enhance engagement and stickiness by offering custom insights through intuitive AI features.</p>
        <p>Overall, Upsolve AI's innovative solution addresses the growing demand for advanced analytics capabilities in various industries. By combining AI technology with user-friendly interfaces, the startup is revolutionizing the way businesses interact with data and empowering them to make informed decisions based on actionable insights.</p>
      </div>
  
      <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Traction
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI is a customer-facing analytics as a service platform that aims to empower businesses to build and offer analytics to their customers quickly and efficiently through AI technology. The platform provides a range of features and benefits that cater to the needs of various industries, such as marketing and fintech companies.</p>
        <p>One key aspect of Upsolve AI's user traction is the positive feedback from customers. Testimonials from industry leaders, including Aditya Agashe from Fiber AI and Vicky Tam from Measurable AI, highlight the platform's ability to streamline analytics processes, customize insights, and enhance user engagement. This positive feedback indicates a high level of satisfaction among users, which is crucial for user retention and acquisition.</p>
        <p>Moreover, the platform's focus on providing deep usage analytics allows businesses to gain valuable insights into user behavior and preferences. By understanding how customers interact with their data, businesses can make informed decisions to improve their products and services, ultimately leading to higher user satisfaction and retention rates.</p>
        <p>Additionally, Upsolve AI's user traction is evident in its product features, such as the ability to build customer-facing analytics dashboards quickly and securely. The platform's AI-powered builder and embedded analytics components make it easy for businesses to integrate analytics into their products, enhancing the overall user experience.</p>
        <p>Overall, Upsolve AI's user traction is supported by positive testimonials, deep usage analytics, and user-friendly product features. The platform's ability to streamline analytics processes, customize insights, and enhance user engagement positions it as a valuable tool for businesses looking to offer advanced analytics solutions to their customers.</p>
      </div>
  
      <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Scalability
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Scalability Analysis:</strong></p>
        <p><strong>Technical Scalability:</strong></p>
        <p>Upsolve AI's technical scalability is evident in its ability to build customer-facing analytics dashboards quickly and efficiently using AI-powered tools. The platform allows businesses to create and offer analytics to their customers at lightning speed, enabling them to answer any data questions via AI. This technical scalability factor is crucial for companies looking to scale their analytics capabilities rapidly without compromising on performance or data accuracy.</p>
        <p><strong>User Scalability:</strong></p>
        <p>From a user scalability perspective, Upsolve AI offers a turnkey solution for embedding analytics for customers on day one, powered by AI. This feature enhances user experience by providing customizable insights tailored to each customer's unique needs. The platform's intuitive AI features enable end-users to drill down on data, get custom insights, and act on recommendations easily, enhancing engagement and stickiness. This user-centric approach contributes to the platform's scalability by catering to a diverse range of users with varying data requirements.</p>
        <p><strong>Operational Scalability:</strong></p>
        <p>Operational scalability is achieved through Upsolve AI's seamless integration process, allowing businesses to drop in React/Vue components and seamlessly incorporate analytics dashboards into their applications. This operational efficiency enables companies to focus on their core product development while leaving the analytics to Upsolve. Additionally, the platform provides deep usage analytics, empowering businesses to stay on top of customer interactions and develop product features based on user behavior insights.</p>
        <p><strong>Data Scalability:</strong></p>
        <p>Data scalability is a critical aspect of Upsolve AI's offering, as it ensures secure data handling, multi-tenancy support, and compatibility with existing authentication systems. The platform secures data in transit and at rest, providing a robust data infrastructure for businesses to build and offer analytics to their customers. This data scalability factor is essential for companies dealing with large volumes of data and diverse data sources, ensuring data integrity and reliability throughout the analytics process.</p>
        <p>In conclusion, Upsolve AI demonstrates strong scalability across technical, user, operational, and data dimensions, making it a comprehensive solution for businesses seeking to enhance their analytics capabilities efficiently and effectively.</p>
      </div>
    </div>
  );
}



function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI has a competitive advantage in the market due to its innovative approach to customer-facing analytics as a service, powered by AI. The startup offers a unique solution that enables businesses to quickly build and offer analytics to their customers, empowering them to answer any data questions through AI technology. This approach sets Upsolve AI apart from traditional analytics services by providing a fast and efficient way for companies to integrate advanced analytics capabilities into their products.</p>
        <p>One key aspect of Upsolve AI's competitive advantage is its focus on automation and augmentation of analytics processes. By leveraging AI technology, the platform streamlines the visualization creation process, allowing users to build customer-facing analytics dashboards simply by asking questions or using a point-and-click interface. This not only accelerates the analytics development process but also enhances the user experience by providing actionable insights and in-platform actions.</p>
        <p>Moreover, Upsolve AI's solution offers deep usage analytics, enabling businesses to gain valuable insights into customer behavior and preferences. This data-driven approach allows companies to tailor their products and services more effectively, leading to increased customer engagement and satisfaction.</p>
        <p>In addition, Upsolve AI has received positive feedback from customers and industry experts, highlighting the platform's effectiveness in simplifying analytics tasks and delivering customizable insights tailored to each customer's unique needs. Testimonials from co-founders and executives of companies like Fiber AI and Measurable AI attest to the value and impact of Upsolve AI's solution in enhancing their analytics capabilities.</p>
        <p>Overall, Upsolve AI's focus on AI-powered customer-facing analytics, automation of analytics processes, deep usage analytics, and positive customer feedback positions the startup as a strong player in the competitive landscape of analytics services. By offering a comprehensive and user-friendly solution, Upsolve AI has the potential to drive significant value for businesses seeking to enhance their data analytics capabilities.</p>
      </div>

      <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI operates in the market of customer-facing analytics as a service, powered by AI. The company offers a unique solution that enables businesses to build and offer analytics to their customers quickly and efficiently. The market for analytics services is highly competitive, with established players and new entrants vying for market share. However, there are several barriers to entry that new startups like Upsolve AI may face:</p>
        <p><strong>1. Expertise and Technology:</strong> Developing AI-powered analytics solutions requires specialized expertise in data science, machine learning, and software development. Upsolve AI's founders, Ka Ling Wu and Serguei Balanovich, have a background in building similar products at Palantir, giving them a competitive advantage. New entrants without this expertise may struggle to develop a comparable product.</p>
        <p><strong>2. Data Infrastructure:</strong> Building a full data stack that can handle large volumes of data and provide real-time analytics is a significant technical challenge. Upsolve AI's ability to scale the product to serve over 50 enterprise customers in a short period demonstrates their robust data infrastructure. New startups would need substantial investment in infrastructure to compete effectively.</p>
        <p><strong>3. Customer Relationships:</strong> Upsolve AI's founders have experience working with clients from various industries, including Fortune 10 companies. Building and maintaining strong relationships with customers is crucial in the analytics market, as businesses rely on these solutions for critical decision-making. New entrants may find it challenging to establish trust and credibility with potential clients.</p>
        <p>In addition to the market-specific barriers, startups in the analytics industry must also navigate regulatory considerations related to data privacy and security. As Upsolve AI deals with sensitive customer data, compliance with data protection laws such as GDPR and CCPA is essential. Ensuring data security, encryption, and compliance with industry standards will be critical for startups to gain the trust of customers and comply with regulatory requirements.</p>
        <p>Overall, while the market for customer-facing analytics services offers opportunities for innovative startups like Upsolve AI, the barriers to entry in terms of expertise, technology, and regulatory compliance pose significant challenges for new entrants looking to compete in this space.</p>
      </div>

      <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI is a promising startup in the field of customer-facing analytics as a service, powered by AI. The company's innovative approach to enabling businesses to build and offer analytics to their customers at lightning speed through AI technology presents a strong foundation for potential exits in the future.</p>
        <p><strong>Market Potential:</strong></p>
        <p>Upsolve AI operates in the AI and big data industry, which is experiencing rapid growth and high demand for advanced analytics solutions. The increasing reliance on data-driven decision-making across various sectors such as marketing, fintech, and more positions Upsolve AI well in a market with significant potential for expansion.</p>
        <p><strong>Product Differentiation:</strong></p>
        <p>The unique selling proposition of Upsolve AI lies in its ability to automate and augment analytics for businesses, providing customers with the superpower to answer any data questions via AI. This differentiation sets the company apart from traditional analytics solutions and enhances its attractiveness to potential acquirers or investors.</p>
        <p><strong>Customer Base and Testimonials:</strong></p>
        <p>The positive feedback from customers, as highlighted in the testimonials, indicates a strong market fit and customer satisfaction with Upsolve AI's offerings. The ability of the platform to amplify engagement and stickiness for end-users further strengthens its value proposition and attractiveness to potential stakeholders.</p>
        <p><strong>Team and Expertise:</strong></p>
        <p>The founding team's background and experience in leading roles at reputable companies such as nPlan and Palantir bring a wealth of knowledge and expertise to Upsolve AI. This factor enhances the company's credibility and potential for growth and successful exits.</p>
        <p><strong>Exit Routes:</strong></p>
        <p>Potential exit routes for Upsolve AI could include acquisition by larger tech companies looking to enhance their analytics capabilities, strategic partnerships with industry leaders, or even an initial public offering (IPO) if the company continues to demonstrate strong growth and market traction.</p>
        <p>In conclusion, Upsolve AI's innovative approach to customer-facing analytics, strong market potential, differentiated product offerings, positive customer feedback, experienced team, and various exit routes make it a compelling investment opportunity with significant exit potential in the future.</p>
      </div>

      <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Industry regulations play a crucial role in shaping the operations and compliance requirements for startups like Upsolve AI, which operates in the AI and big data industry. As a software startup providing customer-facing analytics as a service powered by AI, Upsolve AI must adhere to various regulatory considerations to ensure data privacy, security, and ethical use of AI technology.</p>
        <p>One of the key regulatory considerations for Upsolve AI is data protection laws, such as the General Data Protection Regulation (GDPR) in the European Union and the California Consumer Privacy Act (CCPA) in the United States. These regulations govern the collection, processing, and storage of personal data, requiring companies to obtain explicit consent from individuals, implement data security measures, and provide transparency in data handling practices.</p>
        <p>Additionally, in the AI industry, ethical considerations and regulations are becoming increasingly important. Upsolve AI must ensure that its AI algorithms are transparent, fair, and accountable to prevent bias or discrimination in decision-making processes. Compliance with guidelines such as the AI Ethics Guidelines by the European Commission and the AI Principles by organizations like the IEEE can help Upsolve AI maintain ethical standards in its AI-powered analytics services.</p>
        <p>Moreover, as a B2B SaaS company, Upsolve AI may need to comply with industry-specific regulations related to software services, intellectual property rights, and contractual agreements with clients. Understanding and adhering to these regulations can help Upsolve AI build trust with its customers and partners while mitigating legal risks.</p>
        <p>In conclusion, navigating industry regulations is essential for Upsolve AI to operate ethically, securely, and in compliance with legal requirements. By staying informed about data protection laws, ethical AI guidelines, and industry-specific regulations, Upsolve AI can build a strong foundation for its customer-facing analytics platform and establish itself as a trustworthy and compliant player in the AI and big data industry.</p>
      </div>
      
      <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Upsolve AI, a customer-facing analytics as a service platform powered by AI, appears to be a suitable candidate for Venture Capital investment based on several key factors. Firstly, the company is addressing a growing market demand for advanced analytics solutions that empower businesses to offer data insights to their customers efficiently. With the rise of AI technology and the increasing importance of data-driven decision-making, Upsolve AI's value proposition aligns well with current market trends.</p>
        <p>The company's founding team, comprised of Ka Ling Wu and Serguei Balanovich, brings relevant experience from leading tech companies such as nPlan and Palantir, indicating a strong technical and industry background. Their expertise in product development and commercial work in AI and tech sectors enhances the credibility and capability of Upsolve AI to deliver innovative solutions.</p>
        <p>Moreover, the testimonials from satisfied customers, including Fiber AI and Measurable AI, highlight the positive impact of Upsolve AI's platform on enhancing analytics capabilities and customer engagement. Such endorsements serve as social proof of the company's value proposition and market fit.</p>
        <p>Additionally, the company's focus on providing a turnkey customer-facing analytics solution, coupled with features like deep usage analytics and customizable insights, demonstrates a commitment to meeting customer needs and driving user engagement. This customer-centric approach is crucial for attracting and retaining clients in a competitive market landscape.</p>
        <p>Overall, Upsolve AI's innovative product offering, experienced founding team, positive customer feedback, and market relevance position it as a promising investment opportunity for Venture Capital firms seeking exposure to the AI analytics space. With a clear value proposition and a scalable business model, Upsolve AI has the potential to attract funding and achieve growth in the dynamic tech market.</p>
      </div>

      <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Risk Assessment
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Key risks for Upsolve AI include technological challenges, regulatory hurdles, and market acceptance.</p>
        <p>Technological risk involves ensuring that their analytics software is reliable, secure, and scalable.</p>
        <p>Regulatory risk centers around compliance with data protection laws and industry-specific regulations.</p>
        <p>Market risk involves the acceptance of their AI-powered analytics platform by businesses and the potential competition from more established players in the analytics space.</p>
      </div>

      <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Mitigation Strategies
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>To mitigate technological risks, Upsolve AI could continue partnering with established tech companies for technical expertise and validation.</p>
        <p>For regulatory risks, proactive engagement with regulatory authorities and participation in industry forums is crucial.</p>
        <p>Market risks could be mitigated by diversifying the platform's applications across different industries and forming strategic partnerships to expand market reach.</p>
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}



function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;