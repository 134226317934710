import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">Salekh Mahmood</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}



function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackToFilters() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/results')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Filters
      </div>
    </div>
  );
}

function ChangeView() {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSortOptionClick = (option) => {
    console.log(`Sorting by: ${option}`); // Replace with actual sorting logic
    setSelectedOption(option);
    setShowDropdown(false);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (event.target.className !== 'dropdown-toggle') {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  return (
    <div className="flex gap-5 justify-between self-end mt-8 mr-20 max-w-full w-[677px] max-md:flex-wrap max-md:mr-2.5">
      <div className="flex gap-5 justify-between my-auto text-xs tracking-tight leading-6 uppercase whitespace-nowrap text-zinc-500">
        <button
          className="hover:text-teal-500 hover:font-bold cursor-pointer"
          onClick={() => navigate('/results')}
          style={{ outline: 'none' }}
        >
          SUMMARY
        </button>
        <button
          className="hover:text-teal-500 hover:font-bold cursor-pointer"
          onClick={() => navigate('/companysearch')}
          style={{ outline: 'none' }}
        >
          COMPANIES
        </button>
        <div className="flex flex-col justify-end text-center text-teal-500 cursor-default">
          <button
            style={{ outline: 'none' }}
            onClick={() => navigate('/investorsearch')} // Reloads the same page
            className="hover:font-bold"
          >
            INVESTORS
          </button>
          <div className="shrink-0 h-0.5 bg-teal-500" />
        </div>
      </div>
      <div className="relative flex gap-1 py-1 pr-2 pl-1 text-sm font-bold leading-5 text-center text-teal-500 bg-white rounded-2xl border border-teal-500 border-solid cursor-pointer">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0410b4c2162e63a0ba8752c36e41adf8172bfd8a5b8004434c58a1ce863f9094?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 self-start w-5 aspect-square"
        />
        <div className="dropdown-toggle" onClick={() => setShowDropdown(!showDropdown)}>Sort By</div>
        {showDropdown && (
          <div className="absolute right-0 mt-1 py-1 w-52 bg-white rounded-md shadow-lg z-10">
            <ul className="text-gray-700">
              {["Location (nearest to furthest)", "Founded date (oldest to newest)", "Founded date (newest to oldest)", "Stage (earliest to latest)", "Stage (latest to earliest)", "Number of employees (least to most)", "Number of employees (most to least)"].map((option) => (
                <li key={option} className="flex justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-teal-500" onClick={() => handleSortOptionClick(option)}>
                  {option}
                  {selectedOption === option && <span className="text-teal-500">✔</span>}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}


function Results() {
  const navigate = useNavigate();
  const initialLikes = JSON.parse(localStorage.getItem('investorLikes')) || new Array(5).fill(false);
  const [likes, setLikes] = useState(initialLikes);

  useEffect(() => {
    localStorage.setItem('investorLikes', JSON.stringify(likes));
  }, [likes]);

  const toggleLike = (index) => {
    const newLikes = [...likes];
    newLikes[index] = !newLikes[index];
    setLikes(newLikes);
  };

  return (
    <div className="flex flex-col pr-20 pl-4 mt-3 max-md:pr-5 max-md:max-w-full">
      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index} className="flex flex-col px-6 pt-4 pb-1 mt-3 rounded-2xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc3535dc21c0cd094065834605ce155e0e351258438be382d8ce4af2170b315a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="max-w-full aspect-[3.23] w-[104px]"
          />
          <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-slate-800 max-md:max-w-full">
            Growth Stage Investor
          </div>
          <div className="flex gap-1 self-start mt-1 text-sm text-neutral-400">
            <div className="flex flex-col justify-center px-2 py-1 rounded border border-teal-500 border-solid bg-slate-50">
              <div className="flex gap-1">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/686c5e947a903fdd771c4b1f554e50806d53d68c9be361a5a5d5552270b0df3b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 my-auto w-3 aspect-square"
                />
                <div>San Francisco</div>
              </div>
            </div>
            <div className="flex flex-col justify-center px-2 py-1 text-justify whitespace-nowrap bg-white rounded border border-teal-500 border-solid">
              <div className="flex gap-1">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc14ecba1321df96b7c252f73e1c046f71257d8c9baee7ad53b82bd490f02ef2?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 my-auto w-3 aspect-square"
                />
                <div>B2B</div>
              </div>
            </div>
            <div className="flex flex-col justify-center px-2 py-1 text-justify rounded border border-teal-500 border-solid bg-slate-50">
              <div className="flex gap-1">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/df175bcb38620021b6cfc913e991ad6a3450f44fc06ec249930b429951f10e3a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 my-auto w-3 aspect-square"
                />
                <div>243 Investors</div>
              </div>
            </div>
          </div>
          <div className="mt-1 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:max-w-full">
            Description about the investor’s focus and past achievements.
          </div>
          <div className="flex gap-3 mt-4 max-md:flex-wrap max-md:max-w-full">
            <button
              className="flex-1 justify-center items-center px-8 py-4 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5 max-md:max-w-full cursor-pointer"
              onClick={() => navigate('/scan')}
            >
              Deep Dive
            </button>
            <button
              className="flex justify-center items-center p-2.5 bg-white h-[53px] rounded-[444px] w-[53px] cursor-pointer"
              onClick={() => toggleLike(index)}
            >
              <img
                loading="lazy"
                src={likes[index] ? "https://cdn.builder.io/api/v1/image/assets/TEMP/5eb869764436c0e7042d1bf2e3eb7e65132d977ed39da7ee3515913bac617bcc?apiKey=f2701ed1217a4202b9e82b22944a8abc&" : "https://cdn.builder.io/api/v1/image/assets/TEMP/c74504d66ec52fae9389e5fab19b93ae40fc20147feea0a3b151b86b6b0e0b92?apiKey=f2701ed1217a4202b9e82b22944a8abc&"}
                className={`aspect-[1.12] ${likes[index] ? 'fill-rose-500 stroke-rose-500' : 'fill-transparent stroke-gray-500'} stroke-[1.5px] w-[19px]`}
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}




function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackToFilters />
        <ChangeView />
        <Results className="mt-4" />
      </div>
    </div>
  );
}


export default ParentLayout;