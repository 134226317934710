import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
    const [showDropdown, setShowDropdown] = React.useState(false);
    
    const toggleDropdown = () => setShowDropdown(!showDropdown);
  
    return (
      <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
        <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
          <div className="flex gap-1">
            <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
            <div className="my-auto">User Name</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 my-auto w-5 aspect-square"
          />
        </div>
        {showDropdown && (
          <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
            <ul>
              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    );
  }
  

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}


function CompanyLogo() {
    return (
      <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
        <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
          <img
            loading="lazy"
            src="https://assets-global.website-files.com/64b7471c44695f55834b7e14/64b8dc7a2a0ea1a5e36827b2_ELYOS%20ENERGY%20(500%20%C3%97%20200px)%20(190%20%C3%97%2040px)%20(500%20%C3%97%20200px)%20(1)-p-500.png"
            className="w-full h-full object-contain"
            alt="Elyos Energy Logo"
          />
        </div>
        <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center w-[295px]">
            <div className="text-3xl text-white">
              Elyos Energy
            </div>
            <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
              <div className="flex items-center whitespace-nowrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Team Size Icon"
                />
                <div>Small Team</div>
              </div>
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Industry Icon"
                />
                <div>Energy</div>
              </div>
              <div className="flex items-center text-teal-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Website Icon"
                />
                <a href="https://www.elyosenergy.com/" target="_blank" rel="noopener noreferrer" className="ml-2">
                  Visit website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  
  
function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$10M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$3M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$3M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about1O');
  };

  const navigateToScanPage = () => {
    navigate('/Scan1O');
  };

  const navigateToContactPage = () => {
    navigate('/contact1O');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation",];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="barriers" className="self-center tracking-normal">
          Barriers to Entry Analysis
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
          <p>The energy management market, as exemplified by Elyos Energy, presents several barriers to entry that may hinder new entrants from successfully establishing themselves in the industry. One significant barrier is the high level of technological expertise required to develop and implement energy management solutions. Elyos Energy leverages advanced technologies such as machine learning, APIs, and software to optimize energy consumption for commercial buildings. New entrants without the necessary technical capabilities may struggle to compete effectively in this space.</p>
          <p>Moreover, the market demands a deep understanding of energy systems, renewable energy integration, and grid management, which can be complex and require specialized knowledge. Elyos Energy's focus on energy flexibility and demand response indicates a sophisticated approach to addressing the challenges of supply and demand in the energy sector. This expertise is a key barrier for new entrants seeking to compete with established players like Elyos Energy.</p>
          <p>Another barrier to entry is the significant capital investment required to develop and scale energy management solutions. Elyos Energy raised a $3 million seed round to build energy resilience, highlighting the financial resources needed to fund research, development, and market expansion. New entrants may struggle to secure adequate funding to support the development of innovative energy management technologies and compete with well-funded incumbents like Elyos Energy.</p>
          <p>Furthermore, regulatory barriers can pose challenges for new entrants in the energy management market. Compliance with energy regulations, data privacy laws, and industry standards is crucial for operating in this sector. Elyos Energy's focus on international growth and expansion into new regions underscores the importance of navigating regulatory complexities to establish a presence in diverse markets.</p>
          <p>In conclusion, the energy management market, as exemplified by Elyos Energy, presents formidable barriers to entry related to technological expertise, capital requirements, and regulatory compliance. Overcoming these barriers will be essential for new entrants seeking to compete in this dynamic and rapidly evolving industry.</p>
        </div>
  
        <div id="advantage" className="self-center tracking-normal">
          Competitive Advantage Analysis
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Elyos Energy, as a platform for energy flexibility and demand response, offers a unique set of products and services that provide a competitive advantage in the energy management industry. The company's focus on reinventing the way energy is managed, visualized, and optimized across building portfolios sets it apart from traditional energy management solutions.</p>
          <p><strong>1. Product Differentiation:</strong> Elyos Energy's suite of products, including Manage, Optimise, Flex, and Respond, offers a comprehensive solution for energy management that covers various aspects such as energy usage visualization, optimization, flexibility, and demand response. This holistic approach distinguishes Elyos Energy from competitors who may offer only specific energy management services.</p>
          <p><strong>2. Innovation and Technology:</strong> Elyos Energy leverages advanced technologies such as live weather data, building pattern analysis, and integration of distributed energy resources (DERs) to provide cutting-edge energy management solutions. The company's focus on innovation and technology disruption in the energy sector positions it as a leader in the industry.</p>
          <p><strong>3. Market Focus and Scalability:</strong> Elyos Energy targets the commercial building sector in the UK, with a focus on the electrification of the energy system. By addressing the energy needs of 1.6 million commercial buildings and integrating DERs at mass-market scale, Elyos Energy demonstrates scalability and market penetration potential.</p>
          <p><strong>4. Strategic Partnerships and Funding:</strong> Elyos Energy has secured strategic partnerships with key players in the energy sector and has successfully raised funding, including a $3 million seed round. These partnerships and financial backing enhance the company's credibility, market reach, and ability to drive innovation.</p>
          <p><strong>5. Environmental Impact and Sustainability:</strong> Elyos Energy's emphasis on reducing carbon intensity, supporting grid stability, and promoting energy efficiency aligns with the growing global focus on sustainability and environmental responsibility. This commitment to sustainability not only differentiates Elyos Energy but also attracts environmentally conscious customers.</p>
          <p>In conclusion, Elyos Energy's competitive advantage lies in its innovative products, technology-driven solutions, market focus, strategic partnerships, and commitment to sustainability. By offering a comprehensive energy management platform and addressing the evolving needs of the energy sector, Elyos Energy is well-positioned to lead the industry and drive positive change in energy management practices.</p>
        </div>
  
        <div id="potential" className="self-center tracking-normal">
          Exit Potential Analysis
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>The exit potential for Elyos Energy appears promising based on the information provided. The company has successfully raised a $3 million seed round from notable investors such as Rebel Fund, Y Combinator, Zeno Partners, and Endgame Capital. This initial funding indicates investor confidence in the company's business model and growth prospects.</p>
          <p>Elyos Energy's focus on energy optimization for commercial buildings, particularly in enabling energy flexibility and demand response, addresses a critical need in the market. By helping commercial buildings reduce peak-time energy consumption and transition to renewable power sources, Elyos Energy is positioned to capture a significant market share in the energy sector.</p>
          <p>The company's innovative technology, which connects to devices in commercial buildings to shift energy consumption outside of peak hours, has the potential to deliver substantial cost savings for businesses. With the energy consumption of commercial buildings accounting for a significant portion of global electricity usage, Elyos Energy's solutions have a wide-reaching impact and scalability.</p>
          <p>Furthermore, the founders' backgrounds in leading energy companies such as OVO Energy and Bulb, as well as their experience in launching energy-flexibility offerings for the UK market, provide a strong foundation for Elyos Energy's success.</p>
          <p>In terms of possible exit routes, Elyos Energy could consider options such as acquisition by a larger energy company looking to expand its energy optimization capabilities, a strategic partnership with a key player in the energy sector, or even an initial public offering (IPO) if the company continues to demonstrate strong growth and market traction.</p>
          <p>Overall, Elyos Energy's innovative technology, experienced team, and market potential position the company well for a successful exit in the future, whether through acquisition, partnership, or going public.</p>
        </div>
      </div>
    );
  }
  
  function Scan2() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="finances" className="self-center tracking-normal">
          Finances
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Elyos Energy, a London-based startup, has shown promising growth in its fundraising efforts and financial performance. The company raised a $3 million seed round from notable investors such as Rebel Fund, Zeno Partners, and Y Combinator. This funding was crucial for Elyos Energy to launch its energy flexibility and demand response solutions for commercial buildings, aiming to revolutionize energy management and contribute to building a more resilient energy system.</p>
          <p>The company's focus on energy optimization for commercial buildings is strategic, considering that commercial buildings account for a significant portion of electricity consumption. By offering energy flexibility and demand response services, Elyos Energy aims to help businesses save on electricity bills and support the transition to renewable energy sources. The potential cost savings for businesses, such as hotels and hospitals, are substantial, with the ability to save up to 15% on electricity bills by shifting peak-time consumption.</p>
          <p>Elyos Energy's innovative approach to energy management aligns with the growing demand for sustainable solutions in the energy sector. The company's platform provides real-time data analysis, weather insights, and building pattern analysis to optimize energy usage effectively. By leveraging technology to shift energy consumption outside of peak hours, Elyos Energy not only benefits its commercial clients but also contributes to reducing strain on the grid and supporting grid modernization efforts.</p>
          <p>Overall, Elyos Energy's financial milestones, including successful fundraising rounds and strategic partnerships, indicate a positive trajectory for the company. With a focus on innovation, sustainability, and market expansion, Elyos Energy is well-positioned to drive further growth and make a significant impact in the energy management industry.</p>
        </div>
  
        <div id="founders" className="self-center tracking-normal">
          Founding Team
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>The founding team of Elyos Energy consists of three key members: Adrian Johnston, Philippa Brown, and Panos Stravopodis. Adrian Johnston serves as the Co-founder and CEO of the company. With a background in energy and technology, Adrian brings a wealth of experience to the team. His leadership skills are evident in the company's strategic direction and successful fundraising efforts, such as securing a $3 million seed round led by reputable partners like Rebel Fund, Zeno Partners, and Y Combinator.</p>
          <p>Philippa Brown, another Co-founder, plays a crucial role in the company's mission to fight climate change by decarbonizing commercial buildings. Her expertise in sustainability and environmental initiatives adds depth to Elyos Energy's commitment to creating a more sustainable future. Panos Stravopodis, the third Co-founder, likely contributes technical expertise to the team, given the company's focus on energy management and optimization technologies.</p>
          <p>The team's collective efforts have led to significant achievements, including joining the prestigious Y Combinator accelerator program (#YCS23), launching innovative products that revolutionize commercial energy management, and gaining media coverage in reputable outlets like Financial Times and UKTN. Their ability to attract top-tier investors and talent showcases their credibility and potential for growth in the energy sector.</p>
          <p>Furthermore, the team's active engagement on social media platforms like LinkedIn demonstrates their commitment to transparency, communication, and industry networking. Adrian Johnston's posts reflect the company's milestones, partnerships, and participation in industry events, showcasing a strong brand presence and thought leadership within the energy sector.</p>
          <p>Overall, the Founding Team of Elyos Energy exhibits a diverse skill set, a clear vision for sustainability, and a track record of success that positions them as key players in the energy flexibility and demand response market. Their collaborative efforts and industry expertise bode well for the company's future growth and impact on the electrification of the grid.</p>
        </div>
  
        <div id="regulation" className="self-center tracking-normal">
          Industry Regulations
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Industry regulations play a crucial role in shaping the operations and compliance requirements for businesses, especially startups. In the context of the provided information, the focus is on energy-related products and medical device software, which are subject to specific regulatory considerations.</p>
          <p>For energy-related products, the Energy Information Regulations 2011 (as amended) in Great Britain and Northern Ireland set out the obligations for suppliers to ensure accurate energy labels, product information sheets, and technical documentation. Compliance with these regulations is essential for products such as air conditioners, dishwashers, lighting products, and more. The regulations also mandate the gradual transition to A-G energy rating classes and include specific requirements for energy labels placed on the GB market.</p>
          <p>On the other hand, medical device software, including apps, falls under the category of medical devices and is subject to regulations that define the types of software likely to be classified as medical devices. The regulations outline the criteria for software intended for diagnostic processes, patient health management, monitoring, and clinical decision-making. It is important for startups in this sector to understand the regulatory landscape to ensure compliance and market access.</p>
          <p>In the case of the startup Elyos Energy, which focuses on energy management for commercial buildings, regulatory considerations will be crucial for its operations. The company's platform, which aims to optimize energy usage and provide cost savings, must adhere to relevant energy efficiency regulations and standards. Additionally, as the company expands to Europe and the US, it will need to navigate the regulatory frameworks in those regions to ensure compliance and successful market entry.</p>
          <p>Overall, startups operating in regulated industries such as energy and healthcare must prioritize compliance with industry regulations to avoid legal issues, ensure product quality and safety, and maintain trust with customers and stakeholders. Staying informed about evolving regulations and seeking expert guidance can help startups navigate the complex regulatory landscape effectively.</p>
        </div>
      </div>
    );
  }
  
  
  function Scan3() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="innovation" className="self-center tracking-normal">
          Innovation
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>
            Elyos Energy is an innovative platform that is revolutionizing the way energy is managed in commercial buildings. The platform offers a comprehensive solution for energy management, visualization, optimization, flexibility, and response. By leveraging live weather data and building pattern analysis, Elyos Energy enables users to reduce energy consumption and shift energy usage to avoid peak electricity prices. This not only helps in saving on electricity bills but also supports the grid by reducing energy consumption during times of peak demand.
          </p>
          <p>
            One of the key aspects of Elyos Energy's innovation is its focus on energy flexibility at scale. By enabling the electrification of the energy system, Elyos Energy is addressing the critical issue of supply and demand imbalance in the energy sector. The platform targets the 1.6 million commercial buildings in the UK, offering a solution that can have a significant impact on cost savings and carbon emissions reduction.
          </p>
          <p>
            Elyos Energy has also raised a $3 million seed round to further develop its platform and expand its reach. The team behind Elyos Energy, including experienced founders and industry experts, is dedicated to driving innovation in the energy sector. The platform's ability to access wholesale electricity markets through flexibility aggregators further enhances its value proposition and revenue potential.
          </p>
          <p>
            Overall, Elyos Energy's innovative approach to energy management, flexibility, and demand response positions it as a key player in the transition towards a more sustainable and efficient energy system. With a strong focus on technology, data analytics, and customer value, Elyos Energy is well-positioned to drive positive change in the energy industry.
          </p>
        </div>
  
        <div id="market" className="self-center tracking-normal">
          Market Size
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>
            The Market Size analysis provides insights into the energy market, specifically focusing on electricity generation and energy management systems. The global power generation market size is projected to grow significantly, reaching USD 3.9 trillion by 2032, with a CAGR of 8.04% from 2023 to 2032. This growth is driven by factors such as increasing population, rapid urbanization, industrialization, and investments in energy generation.
          </p>
          <p>
            In contrast, the energy management system market is also expanding, with Elyos Energy, a startup focusing on energy flexibility and demand response, securing $3 million in seed funding. The company aims to help businesses reduce electricity costs and alleviate pressure on the national grid by optimizing energy consumption and participating in demand response programs.
          </p>
          <p>
            The energy market trends indicate a shift towards renewable energy sources, energy efficiency, and sustainability. The increasing adoption of smart grids, smart meters, and IoT technologies is driving market growth. Additionally, the market is influenced by local circumstances and macroeconomic factors, such as government policies, technological advancements, and the need for energy efficiency.
          </p>
          <p>
            Elyos Energy's innovative platform aligns with the market trends by offering solutions for energy flexibility and demand response, catering to the growing demand for efficient energy management systems. The company's focus on commercial buildings and integration of distributed energy resources demonstrates its commitment to sustainability and grid stability.
          </p>
          <p>
            Overall, the energy market is evolving rapidly, with a strong emphasis on renewable energy, energy efficiency, and smart technologies. Companies like Elyos Energy are at the forefront of this transformation, providing innovative solutions to address the challenges of energy consumption, grid reliability, and cost optimization. The market trends in the energy sector are showing a shift towards renewable energy sources and energy efficiency. The data from the Department for Energy Security and Net Zero in the United Kingdom indicates a focus on the supply and demand of coal, oil, gas, electricity, and renewables. The quarterly publications provide insights into the changing landscape of energy consumption and production.
          </p>
          <p>
            Technological shifts are evident in the rise of AI and its use in the energy sector, as well as the integration of IoT with smart grids and smart meters. These advancements are driving the adoption of energy management systems and software solutions to optimize energy usage and reduce costs.
          </p>
          <p>
            Market changes are driven by the increasing demand for energy-efficient solutions, especially in commercial buildings where energy consumption is significant. Companies like Elyos Energy are leveraging technology to provide energy flexibility and demand response services, helping businesses save costs and reduce carbon emissions.
          </p>
          <p>
            Overall, these factors indicate a growing emphasis on sustainability, energy efficiency, and the integration of renewable energy sources in the energy sector. Startups like Elyos Energy are well-positioned to capitalize on these market trends by offering innovative solutions that address the evolving needs of businesses and contribute to a more sustainable energy future.
          </p>
        </div>
  
        <div id="monetisation" className="self-center tracking-normal">
          Monetisation Strategy
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>
            The Monetisation Strategy of the startup, Elyos Energy, is centered around providing energy management solutions to commercial buildings in the UK. The company offers a range of products aimed at helping businesses manage, optimize, flex, and respond to their energy usage in order to save costs and reduce carbon emissions.
          </p>
          <p>
            One key aspect of their monetisation strategy is the pricing model they likely employ. Elyos Energy may charge a subscription fee for access to their platform, which allows businesses to visualize and manage their energy usage across multiple sites. Additionally, they may offer tiered pricing based on the size or energy consumption of the buildings, providing scalability for different types of customers.
          </p>
          <p>
            Another revenue stream for Elyos Energy could come from their Energy Flex and Respond services. By helping businesses shift their energy usage to avoid peak electricity prices and reduce consumption during times of high demand on the grid, Elyos Energy could potentially charge a fee based on the amount of energy saved or the value generated for the customer.
          </p>
          <p>
            Furthermore, the startup may also generate revenue through partnerships with energy providers or grid operators. By enabling electrification of the energy system and providing energy flexibility at scale, Elyos Energy could potentially receive incentives or payments for supporting the grid and contributing to a more sustainable energy ecosystem.
          </p>
          <p>
            Overall, Elyos Energy's Monetisation Strategy appears to be focused on providing value-added services to commercial buildings, leveraging technology and data analytics to help businesses optimize their energy usage and reduce costs. By offering a combination of subscription-based pricing, value-based fees for energy-saving services, and potential partnerships with energy stakeholders, Elyos Energy aims to establish a sustainable revenue model while driving positive impact in the energy sector.
          </p>
        </div>
  
        <div id="scalability" className="self-center tracking-normal">
          Scalability
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>
            Scalability is a critical aspect for Elyos Energy as it expands its energy management platform across borders. The company's focus on simplifying energy management for global customers requires a scalable technical solution that can adapt to various markets and geographies.
          </p>
          <p><strong>Technical Scalability:</strong> Elyos Energy has built its platform with expansion in mind, allowing for easy scalability as the market demands. The platform is designed to handle multiple currencies, markets, and locations, showcasing a robust technical infrastructure that can support growth. The integration of distributed energy resources (DERs) through APIs further demonstrates the technical scalability of the platform, enabling the management of diverse energy assets efficiently.
          </p>
          <p><strong>User Scalability:</strong> Elyos Energy targets a wide range of customers, including local councils, hospitals, real estate firms, and large enterprises with a global presence. By simplifying energy management across different customer segments and geographies, the platform ensures user scalability. The focus on customer needs and the comparison to the banking sector's user experience highlight Elyos Energy's commitment to providing a seamless and scalable solution for its diverse customer base.
          </p>
          <p><strong>Operational Scalability:</strong> The company's approach to building for the future and thinking about business expansion early on indicates a strategic focus on operational scalability. By embracing a global strategy and understanding market differences, Elyos Energy aims to better serve its customers and tap into new segments efficiently. This operational scalability approach allows the company to unlock innovative ideas and drive growth potential in different markets.
          </p>
          <p><strong>Data Scalability:</strong> Elyos Energy's platform covers multiple markets and is designed to handle the scalability of data effectively. By building technology that can handle future growth and expanding into more regions, the company ensures data scalability. This aspect is crucial for managing large volumes of energy data and delivering valuable insights to customers on a global scale.
          </p>
          <p><strong>Commercial Scalability:</strong> Elyos Energy's platform offers a solution for electrification of the energy system and energy flexibility at scale. By targeting the 1.6 million commercial buildings in the UK and beyond, the company showcases its commercial scalability potential. The focus on cost savings, carbon emissions reduction, and the ability to participate in demand response programs further emphasize the platform's commercial scalability and its value proposition in the energy sector.
          </p>
        </div>
      </div>
    );
  }
  

  

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;