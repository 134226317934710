import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}


function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://cdn.prod.website-files.com/6340702a42dd5b5ba0404a59/65ea07ffacb8bba977c91491_Logotype.svg"
          className="w-full h-full object-contain"
          alt="Radom Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            Radom
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Cryptocurrency</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://www.radom.com/" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$10M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$2.16M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$2.16M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about4F');
  };

  const navigateToScanPage = () => {
    navigate('/Scan4F');
  };

  const navigateToContactPage = () => {
    navigate('/contact4F');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="barriers" className="self-center tracking-normal">
        Barriers to Entry Analysis
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>The market for cryptocurrency payment solutions, as exemplified by Radom, presents several barriers to entry for new startups looking to compete in this space. One significant barrier is the high level of technological expertise required to develop and maintain a comprehensive crypto payment platform. Developing APIs, SDKs, and webhooks that facilitate cryptocurrency transactions demands a deep understanding of blockchain technology, encryption, and secure payment processing protocols. This technical complexity can deter new entrants without the requisite expertise or resources.</p>
        <p>Moreover, the regulatory environment surrounding cryptocurrencies adds another layer of complexity and barrier to entry. Compliance with anti-money laundering (AML) and know your customer (KYC) regulations is crucial in the cryptocurrency space to prevent illicit activities such as money laundering and terrorist financing. Startups must invest in robust compliance measures and legal expertise to navigate the evolving regulatory landscape, which can be costly and time-consuming.</p>
        <p>Additionally, establishing partnerships with major players in the cryptocurrency ecosystem, such as exchanges, wallets, and payment processors, is essential for a new entrant to gain credibility and access a wide range of cryptocurrencies and blockchains. Building these relationships requires time, effort, and a proven track record in the industry, making it challenging for startups to compete with established players like Radom.</p>
        <p>Furthermore, the network effects and economies of scale enjoyed by existing platforms like Radom create a barrier to entry for new startups. Radom's extensive network of users, developers, and supported cryptocurrencies provides a competitive advantage that can be difficult for newcomers to replicate.</p>
        <p>In conclusion, the barriers to entry in the cryptocurrency payment market are primarily driven by technological complexity, regulatory challenges, the need for strategic partnerships, and the dominance of established players. Overcoming these barriers requires a combination of technical expertise, regulatory compliance, industry connections, and a differentiated value proposition to compete effectively in this dynamic and rapidly evolving market.</p>
      </div>

      <div id="advantage" className="self-center tracking-normal">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Radom is a startup that offers a next-generation crypto payments platform, providing businesses with a comprehensive suite of solutions for cryptocurrency payments. The company's competitive advantage lies in several key areas:</p>
        <p><strong>1. Innovative Protocol:</strong> Radom has developed the first protocol of its kind that enables complex cryptocurrency payments for businesses in a permissionless manner, down to the payment notification layer. This innovative approach sets Radom apart from traditional payment processors and positions it as a pioneer in the crypto payments space.</p>
        <p><strong>2. Experienced Team:</strong> The startup boasts a team of experienced entrepreneurs and tech professionals with decades of expertise in Web3 technologies. The leadership team, including the CEO, COO, CBO, and CTO, brings a wealth of knowledge and industry insights to drive Radom's growth and success.</p>
        <p><strong>3. Comprehensive Product Suite:</strong> Radom offers a wide range of products, including Crypto Billing, Crypto Checkout, Crypto Invoicing, Crypto Payment Links, and Crypto Payments, catering to various business needs. This comprehensive product suite provides flexibility and scalability for businesses looking to adopt crypto payments seamlessly.</p>
        <p><strong>4. API Integration:</strong> Radom provides easy-to-use APIs that mimic the functionality of traditional payment providers, making it simple for businesses to integrate crypto payments into their existing systems. This streamlined integration process sets Radom apart from competitors and enhances its appeal to a wide range of businesses.</p>
        <p><strong>5. Global Reach:</strong> With support for millions of crypto customers worldwide, Radom enables businesses to tap into a global market and scale their services internationally. This global reach positions Radom as a key player in the crypto payments ecosystem and opens up opportunities for businesses to expand their customer base.</p>
        <p>Overall, Radom's competitive advantage lies in its innovative protocol, experienced team, comprehensive product suite, API integration capabilities, and global reach. By offering a seamless and user-friendly crypto payments platform, Radom is well-positioned to capitalize on the growing demand for cryptocurrency payments in the business world.</p>
      </div>

      <div id="potential" className="self-center tracking-normal">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Radom, a cryptocurrency payment gateway founded in 2023 by Chris Wilson, has significant exit potential based on its innovative approach to integrating cryptocurrency payments into businesses. The company offers a suite of APIs, webhooks, and SDKs that facilitate seamless cryptocurrency transactions, addressing the pain point of brands wanting to embrace cryptocurrency payments but struggling to find a suitable solution.</p>
        <p>The market for cryptocurrency payments is rapidly expanding, with more businesses and consumers adopting digital currencies for transactions. Radom's focus on providing a user-friendly experience and maintaining high conversion rates sets it apart from traditional fiat payment gateways. This unique value proposition positions Radom well for potential acquisition or IPO opportunities in the future.</p>
        <p>One possible exit route for Radom could be through acquisition by a larger fintech company looking to expand its cryptocurrency payment capabilities. By acquiring Radom, a company could quickly enhance its offerings and enter the growing crypto payment market. Potential acquirers could include established payment processors, blockchain companies, or even traditional financial institutions looking to diversify their services.</p>
        <p>Another exit strategy for Radom could involve going public through an initial public offering (IPO). As the cryptocurrency market continues to gain mainstream acceptance, investors may see value in a publicly traded cryptocurrency payment gateway like Radom. Going public would provide Radom with access to additional capital for further expansion and development of its products and services.</p>
        <p>Overall, Radom's innovative approach to crypto payments, experienced team, and strong market potential make it an attractive candidate for various exit opportunities. Whether through acquisition or IPO, Radom has the potential to deliver significant returns for investors and stakeholders in the future.</p>
      </div>
    </div>
  );
}

function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="finances" className="self-center tracking-normal">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Radom, a cryptocurrency payment gateway founded in 2023, has positioned itself as a pioneer in enabling businesses to seamlessly integrate cryptocurrency payments into their existing checkout processes. The company offers a suite of APIs, Webhooks, and SDKs that facilitate cryptocurrency transactions, catering to a wide range of billing use cases in the cryptocurrency space. Radom's focus on providing a user-friendly experience and maintaining high conversion rates akin to traditional fiat payment gateways sets it apart in the market.</p>
        <p>In terms of fundraising history, Radom has attracted investments from various funds, including tax-efficient funds like the Haatch EIS Fund and SEIS Fund, as well as institutional funds such as the Haatch D2N2 ESAIF and British Business Investments. These investments indicate a strong interest from investors in supporting Radom's growth and development within the cryptocurrency payment sector.</p>
        <p>While specific financial performance metrics are not provided in the information, Radom's emphasis on empowering businesses with lower fees compared to traditional fiat payment gateways suggests a focus on cost efficiency and competitive pricing strategies. The company's ability to offer zero chargebacks and instantaneous international payments further enhances its value proposition to businesses looking to embrace cryptocurrency payments.</p>
        <p>Overall, Radom's innovative approach to crypto payments, coupled with its strategic fundraising efforts and commitment to providing efficient and cost-effective solutions, positions it as a promising player in the evolving landscape of digital payments. Continued growth in user adoption and strategic partnerships could further solidify Radom's position as a key player in the cryptocurrency payment gateway market.</p>
      </div>

      <div id="founders" className="self-center tracking-normal">
        Founding Team
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The founding team of Radom comprises individuals with diverse expertise and experience, making them well-equipped to lead the startup towards success in the cryptocurrency payments industry.</p>
        <p>Chris Wilson, as the Chief Executive Officer, brings leadership skills and a strategic vision to the team. With a background in entrepreneurship, Chris is likely responsible for setting the overall direction and goals of the company, as well as overseeing its operations and growth.</p>
        <p>Scott Osman, the Chief Operating Officer, is instrumental in ensuring the smooth functioning of the company's day-to-day operations. His role likely involves managing resources, optimizing processes, and driving efficiency across different departments.</p>
        <p>Rikki Janae, serving as the Chief Branding Officer, is responsible for shaping Radom's brand identity and market positioning. With expertise in branding and marketing, Rikki plays a crucial role in building brand awareness, creating a strong brand image, and driving customer engagement.</p>
        <p>Mariel Yonnadam, the Chief Technology Officer, brings technical expertise to the team. As the CTO, Mariel is likely responsible for overseeing the development and implementation of Radom's technology solutions, ensuring they align with the company's strategic objectives and meet market demands.</p>
        <p>The advisory board, consisting of professionals with backgrounds in business, finance, software, and marketing, provides valuable guidance and expertise to the founding team. Advisors like Benjamin Whitby, Lilia Severina, and Prashant Malik bring a wealth of knowledge and experience to help steer Radom in the right direction.</p>
        <p>Overall, the founding team of Radom appears to be well-rounded, with a mix of skills and experiences that are essential for driving innovation, growth, and success in the competitive cryptocurrency payments industry. Their collective expertise positions Radom as a promising player in the market, poised for future growth and expansion.</p>
      </div>

      <div id="regulations" className="self-center tracking-normal">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Industry regulations play a crucial role in shaping the operations and compliance requirements for companies like Radom, a cryptocurrency payment gateway. As a fintech startup operating in the cryptocurrency space, Radom must navigate a complex regulatory landscape to ensure legal compliance and mitigate risks associated with financial crimes.</p>
        <p>One of the key regulatory considerations for Radom is Anti-Money Laundering (AML) compliance. In the UK, AML regulations are primarily governed by the Proceeds of Crime Act and the Electronic Identification and Trust Services for Electronic Transactions Regulations. These regulations are designed to prevent money laundering and terrorist financing activities within the financial sector, including cryptocurrency transactions. Radom must implement robust AML procedures to verify customer identities, monitor transactions for suspicious activities, and report any potential money laundering activities to the authorities.</p>
        <p>Additionally, Radom needs to adhere to the Payment Services Regulations 2017 and the Electronic Money Regulations 2011. These regulations define the legal framework for payment services and electronic money issuance in the UK. As a crypto payment processor, Radom must ensure compliance with these regulations to operate lawfully and protect the interests of its customers. This includes obtaining the necessary authorizations or registrations from the Financial Conduct Authority (FCA) to issue electronic money and provide payment services.</p>
        <p>Furthermore, Radom should stay abreast of regulatory developments and updates in the cryptocurrency industry to adapt its compliance practices accordingly. Given the evolving nature of fintech regulations, Radom must maintain a proactive approach to compliance management and risk mitigation. Engaging with legal advisors and compliance experts can help Radom navigate the regulatory complexities and ensure sustainable growth in the competitive crypto payments market.</p>
        <p>In conclusion, regulatory compliance is a critical aspect of Radom's business operations, and the company must prioritize adherence to AML regulations, Payment Services Regulations, and Electronic Money Regulations to build trust with customers, partners, and regulatory authorities. By establishing robust compliance frameworks and staying informed about regulatory changes, Radom can position itself as a reliable and compliant player in the cryptocurrency payments industry.</p>
      </div>
    </div>
  );
}

  
function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="self-center tracking-normal">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Radom is a startup that has introduced a groundbreaking protocol, the first of its kind, aimed at revolutionizing cryptocurrency payments for businesses. The innovation lies in providing a permissionless approach right down to the payment notification layer, offering a seamless and efficient way for businesses to accept complex cryptocurrency payments. The team behind Radom comprises experienced entrepreneurs and tech professionals with decades of expertise in Web3 technologies, ensuring a strong foundation for the startup's success.</p>
        <p>One of the key technological aspects of Radom is its comprehensive crypto payment gateway, which covers a range of services from checkout to invoicing. This platform enables businesses to accept a variety of cryptocurrencies, including popular options like Bitcoin, Ethereum, USDC, and USDT, catering to a wide range of customers and payment preferences. The startup's API integration and no-code solutions make it easy for businesses to adopt crypto payments swiftly and efficiently.</p>
        <p>Radom's Checkout feature stands out as it allows businesses to set up their crypto checkout in minutes, with transparent pricing per transaction and no hidden fees. The startup also offers automation of payments through an easy-to-use API, providing a user-friendly experience akin to traditional payment providers. Moreover, Radom supports millions of crypto customers globally, offering unparalleled flexibility in payment options to match diverse business needs.</p>
        <p>Overall, Radom's innovative approach to crypto payments, robust technology infrastructure, experienced team, and user-friendly solutions position it as a leading player in the cryptocurrency payment processing industry. With its focus on simplifying crypto payments for businesses of all sizes, Radom has the potential to drive significant growth and adoption in the evolving landscape of digital payments.</p>
      </div>

      <div id="market" className="self-center tracking-normal">
        Market Size
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Market Size Analysis:</p>
        <p>The global blockchain market was estimated to be approximately $7.4 billion in 2022 and is projected to reach over $94.0 billion by the end of 2027, with a compound annual growth rate (CAGR) of around 66.2% during the forecast period. The increasing venture capital funding and investments in blockchain technology have been key drivers for the market's growth. Blockchain technology has gained significant popularity in various business applications such as payments, exchanges, smart contracts, documentation, and digital identity.</p>
        <p>North America is expected to hold the highest market share in the blockchain market during the forecast period, driven by the presence of a large number of blockchain suppliers in the region. Key players in the global blockchain industry include IBM, AWS, Oracle, Huawei, Accenture, Wipro, Infosys, TCS, SAP, Intel, and others.</p>
        <p>The market segmentation includes components (platforms and services), providers (application, middleware, and infrastructure), types (public, private, and hybrid), organization size (SMEs and large organizations), applications, and regions (North America, Europe, Asia Pacific, Middle East & Africa, and Latin America).</p>
        <p>The blockchain market is witnessing key trends such as rising demand for real-time data analysis, enhanced visibility, proactive maintenance, increasing government initiatives to boost demand for blockchain platforms and services, uncertain regulatory and compliance environment, high adoption of blockchain solutions for payments, smart contracts, and digital identities, and simplification of processes with transparency, security, and immutability due to blockchain.</p>
        <p>Overall Analysis:</p>
        <p>The rapid growth and adoption of blockchain technology globally present significant opportunities for startups like Radom, which offer crypto payment solutions. As the blockchain market expands, the demand for innovative payment solutions, such as those provided by Radom, is likely to increase. Radom's focus on providing comprehensive crypto payment solutions for subscriptions, invoices, and payments aligns well with the market trends towards digital transactions and blockchain technology.</p>
        <p>With the increasing popularity of cryptocurrencies and the need for secure, efficient payment solutions, Radom is well-positioned to capitalize on the growing market size. By offering a user-friendly dashboard, APIs, and automation of recurring crypto payments, Radom can cater to a wide range of businesses looking to adopt crypto payments. Additionally, Radom's ability to support any billing use case in cryptocurrency provides a competitive edge in the market.</p>
        <p>Overall, the positive market trends, increasing adoption of blockchain technology, and the demand for crypto payment solutions indicate a favorable environment for Radom's growth and success in the evolving blockchain market.</p>
      </div>

      <div id="monetisation" className="self-center tracking-normal">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The Monetisation Strategy of Radom revolves around providing a comprehensive suite of cryptocurrency payment solutions to businesses, enabling them to accept crypto payments seamlessly. Radom offers various products such as Crypto Billing, Crypto Checkout, Crypto Invoicing, Crypto Payment Links, and Crypto Payments, catering to different business needs. The startup aims to capture a share of the growing market for crypto payments by providing a user-friendly platform that supports any billing use case in cryptocurrency.</p>
        <p>One key aspect of Radom's Monetisation Strategy is its pricing model. The startup offers flexibility in pricing options, allowing businesses to choose a plan that suits their needs. Radom's pricing structure is not explicitly provided in the information, but it mentions a free version and a free trial, indicating a freemium model where users can access basic features for free and upgrade to premium plans for advanced functionalities. This approach can attract a wide range of businesses, from startups to enterprises, looking to integrate crypto payments into their operations.</p>
        <p>Moreover, Radom's focus on automation through APIs and a user-friendly dashboard enhances its Monetisation Strategy. By providing easy-to-use tools for product and engineering teams, Radom aims to streamline the process of accepting crypto payments, reducing the barriers for businesses to adopt cryptocurrencies. This automation aspect can be a key selling point for Radom, as it enables businesses to scale their operations efficiently and cater to a global audience.</p>
        <p>Additionally, Radom's emphasis on supporting multiple cryptocurrencies, wallets, and payment methods aligns with its Monetisation Strategy of catering to a diverse customer base. By enabling seamless crypto commerce for all customer types, Radom aims to position itself as a one-stop solution for businesses looking to embrace cryptocurrency payments.</p>
        <p>In conclusion, Radom's Monetisation Strategy revolves around offering a range of crypto payment solutions, flexible pricing models, automation through APIs, and support for various cryptocurrencies. These strategic elements are designed to attract businesses of all sizes and facilitate the adoption of crypto payments in the global market.</p>
      </div>

      <div id="scalability" className="self-center tracking-normal">
        Scalability
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Scalability is a crucial aspect for any technology platform, especially in the rapidly evolving cryptocurrency industry. Radom, as a cryptocurrency payment gateway, must ensure scalability across various dimensions to support its growing user base and transaction volume effectively.</p>
        <p><strong>Technical Scalability:</strong> Radom's technical scalability is evident in its ability to handle a large number of transactions seamlessly. The platform offers APIs, Webhooks, and SDKs that facilitate cryptocurrency transactions, allowing for easy integration into existing checkout flows. The platform's documentation emphasizes simplicity and ease of integration, enabling developers to quickly adopt crypto payments for their businesses. The use of an API-based approach also ensures flexibility and adaptability to changing requirements, contributing to technical scalability.</p>
        <p><strong>User Scalability:</strong> User scalability is crucial for Radom to support millions of crypto customers worldwide. The platform's user-friendly dashboard and no-code solutions cater to a wide range of users, from freelancers and startups to enterprise companies. By providing a seamless user experience and supporting various billing use cases in cryptocurrency, Radom can attract and retain a diverse user base, contributing to user scalability.</p>
        <p><strong>Operational Scalability:</strong> Operational scalability is essential for Radom to efficiently manage its operations as the business grows. The platform's comprehensive crypto payment gateway, from checkout to invoicing, ensures that services are accessible worldwide, enabling the scaling of businesses from zero to one. Additionally, Radom's focus on lower fees than traditional fiat payment gateways and zero chargebacks enhances operational efficiency and cost-effectiveness, supporting operational scalability.</p>
        <p><strong>Data Scalability:</strong> Data scalability is critical for handling the increasing volume of transactions and user data on the platform. Radom's ability to support various cryptocurrencies, wallets, and payment methods demonstrates its readiness to scale data infrastructure to accommodate diverse customer preferences. The platform's API-driven approach and emphasis on automation further enhance data scalability by streamlining data processing and management tasks.</p>
        <p>In conclusion, Radom demonstrates strong scalability across technical, user, operational, and data dimensions, positioning itself as a reliable and efficient cryptocurrency payment gateway for businesses of all sizes. By focusing on simplicity, flexibility, and cost-effectiveness, Radom is well-equipped to support its growth and meet the evolving needs of the cryptocurrency industry.</p>
      </div>

      <div id="traction" className="self-center tracking-normal">
        User Traction
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>User traction for Radom, a cryptocurrency payment gateway, appears to be gaining momentum based on the information provided. The company offers a suite of APIs and tools to facilitate cryptocurrency transactions for businesses, promising lower fees and international payment capabilities.</p>
        <p>Radom's user traction can be analyzed through various aspects:</p>
        <p><strong>1. Product Offering:</strong> Radom provides a range of products such as Crypto Billing, Crypto Checkout, Crypto Invoicing, and Crypto Payment Links, catering to different business needs. This diverse product offering indicates a comprehensive approach to serving various segments of the market.</p>
        <p><strong>2. User-Friendly Solutions:</strong> The company emphasizes easy integration with its "no-code solutions" and straightforward code integration options. This user-centric approach is likely attracting businesses looking for seamless cryptocurrency payment solutions.</p>
        <p><strong>3. API Integration:</strong> Radom offers developers documentation and API references, highlighting its focus on developers and technical users. This indicates a strategy to attract businesses that require customized solutions and integration capabilities.</p>
        <p><strong>4. Global Reach:</strong> Radom claims to support millions of crypto customers worldwide, showcasing its scalability and potential for widespread adoption. This global reach is essential for expanding user traction and market presence.</p>
        <p><strong>5. Industry Pain Point:</strong> Radom addresses a common pain point in the industry where brands struggle to find effective crypto payment solutions. By positioning itself as a solution that works like traditional payment gateways, Radom is likely resonating with businesses seeking reliable crypto payment options.</p>
        <p><strong>6. Social Media Presence:</strong> The company's active presence on Twitter, sharing updates, product launches, and developer resources, indicates a proactive approach to engaging with users and building a community around its products.</p>
        <p>Overall, Radom's user traction seems promising, with a focus on user experience, developer support, and addressing industry challenges. Continued growth in user adoption, positive feedback from businesses implementing Radom's solutions, and further expansion of its product offerings could contribute to sustained traction and market success.</p>
      </div>

      <div id="vcfit" className="self-center tracking-normal">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Based on the information provided, Radom appears to be a promising company for Venture Capital investment due to several key factors:</p>
        <p><strong>1. Innovative Technology:</strong> Radom has developed a unique protocol that enables complex cryptocurrency payments for businesses in a permissionless manner. This innovative approach to crypto payments sets Radom apart in the market and indicates a strong potential for growth and disruption in the fintech industry.</p>
        <p><strong>2. Experienced Team:</strong> The company boasts a team of experienced entrepreneurs and tech professionals with decades of expertise in Web3 technologies. The leadership team, including the CEO, COO, CBO, and CTO, brings a wealth of knowledge and skills to drive the company's success.</p>
        <p><strong>3. Strong Advisory Board:</strong> Radom has assembled a diverse advisory board with backgrounds in business, finance, software, marketing, compliance, and sales. This indicates that the company has access to valuable expertise and networks that can support its growth strategy and market penetration.</p>
        <p><strong>4. Comprehensive Product Offerings:</strong> Radom offers a wide range of crypto payment solutions, including billing, checkout, invoicing, payment links, and presales. This comprehensive suite of products caters to various business needs and demonstrates the company's ability to address different market segments effectively.</p>
        <p><strong>5. Market Potential:</strong> The increasing adoption of cryptocurrencies and the growing demand for crypto payment solutions present a significant market opportunity for Radom. With support for multiple cryptocurrencies and wallets, Radom is well-positioned to capitalize on the expanding crypto economy.</p>
        <p>Overall, Radom's combination of innovative technology, experienced team, strong advisory support, comprehensive product offerings, and market potential make it a suitable candidate for Venture Capital investment. The company's focus on simplifying crypto payments and providing scalable solutions aligns well with the current trends in the fintech industry, making it an attractive investment opportunity for potential investors.</p>
      </div>
    </div>
  );
}


function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;