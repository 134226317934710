import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LogIn from './LogIn';
import LandingPage from './LandingPage';
import ForgotPassword from './ForgotPassword';
import Preferences from './Preferences';
import Settings from './Settings';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import CompanySearch from './CompanySearch';
import CompanySearchAnalytics from './CompanySearchAnalytics';
import CompanySearchOther from "./CompanySearchOther";
import CompanySearchFinance from "./CompanySearchFinance";
import CompanySearchHealth from "./CompanySearchHealth";
import InvestorSearch from './InvestorSearch';
import Results from './Results';
import Saved from './Saved';
import Scan from './Scan';
import Scan1F from "./startups/finance/startup1/scan1F";
import About1F from "./startups/finance/startup1/about1F";
import Contact1F from "./startups/finance/startup1/contact1F";
import Scan2F from "./startups/finance/startup2/Scan2F"; 
import About2F from "./startups/finance/startup2/about2F";
import Contact2F from "./startups/finance/startup2/contact2F";
import Scan3F from "./startups/finance/startup3/scan3F"; 
import About3F from "./startups/finance/startup3/about3F";
import Contact3F from "./startups/finance/startup4/contact4F";
import Scan4F from "./startups/finance/startup4/scan4F"; 
import About4F from "./startups/finance/startup4/about4F";
import Contact4F from "./startups/finance/startup4/contact4F";
import Scan1A from "./startups/analytics/startup1/scan1A";
import About1A from "./startups/analytics/startup1/about1A";
import Contact1A from "./startups/analytics/startup1/contact1A";
import Scan2A from "./startups/analytics/startup2/scan2A";
import About2A from "./startups/analytics/startup2/about2A";
import Contact2A from "./startups/analytics/startup2/contact2A";
import Scan3A from "./startups/analytics/startup3/scan3A";
import About3A from "./startups/analytics/startup3/about3A";
import Contact3A from "./startups/analytics/startup3/contact3A";
import Scan4A from "./startups/analytics/startup4/scan4A";
import About4A from "./startups/analytics/startup4/about4A";
import Contact4A from "./startups/analytics/startup4/contact4A";
import Scan1O from "./startups/other/startup1/scan1O";
import About1O from "./startups/other/startup1/about1O";
import Contact1O from "./startups/other/startup1/contact1O";
import Scan2O from "./startups/other/startup2/scan2O";
import About2O from "./startups/other/startup2/about2O";
import Contact2O from "./startups/other/startup2/contact2O";
import Scan3O from "./startups/other/startup3/scan3O";
import About3O from "./startups/other/startup3/about3O";
import Contact3O from "./startups/other/startup3/contact3O";
import ScanTest from './ScanTest';
import SearchPage from './SearchPage';
import SpecificResult from './SpecificResult';
import SpecificResultTest from './SpecificResultTest';
import ContactPage from './ContactPage';

function App({showNavigation}) {
  return (
    <Router>
      <div>
        {/* Navigation Links */}
        {showNavigation && (  
        <nav>
          <ul>
            {/* Add Link components for navigation */}
            <li><Link to="/login">Log In</Link></li>
            <li><Link to="/landingpage">Landing Page</Link></li>
            {/* Add new links */}
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to="/companysearchanalytics">Company Search</Link></li>
            <li><Link to="/companysearchother">Company Search Analytics</Link></li>
            <li><Link to="/companysearchfinance">Company Search Energy</Link></li>
            <li><Link to="/companysearchfinance">Company Search Finance</Link></li>
            <li><Link to="/companysearchhealth">Company Search Health</Link></li>
            <li><Link to="/investorsearch">Investor Search</Link></li>
            <li><Link to="/results">Results</Link></li>
            <li><Link to="/saved">Saved</Link></li>
            <li><Link to="/scan">Scan</Link></li>
            <li><Link to="/about1F">About</Link></li>
            <li><Link to="/contact1F">Contact</Link></li>
            <li><Link to="/scan1F">Scan</Link></li>
            <li><Link to="/about2F">About</Link></li>
            <li><Link to="/contact2F">Contact</Link></li>
            <li><Link to="/scan2F">Scan</Link></li>
            <li><Link to="/about3F">About</Link></li>
            <li><Link to="/contact3F">Contact</Link></li>
            <li><Link to="/scan3F">Scan</Link></li>
            <li><Link to="/about4F">About</Link></li>
            <li><Link to="/contact4F">Contact</Link></li>
            <li><Link to="/scan4F">Scan</Link></li>
            <li><Link to="/about1A">About</Link></li>
            <li><Link to="/contact1A">Contact</Link></li>
            <li><Link to="/scan1A">Scan</Link></li>
            <li><Link to="/about2A">About</Link></li>
            <li><Link to="/contact2A">Contact</Link></li>
            <li><Link to="/scan2A">Scan</Link></li>
            <li><Link to="/about3A">About</Link></li>
            <li><Link to="/contact3A">Contact</Link></li>
            <li><Link to="/scan3A">Scan</Link></li>
            <li><Link to="/about4A">About</Link></li>
            <li><Link to="/contact4A">Contact</Link></li>
            <li><Link to="/scan4A">Scan</Link></li>
            <li><Link to="/scan1O">Scan</Link></li>
            <li><Link to="/about1O">About</Link></li>
            <li><Link to="/contact1O">Contact</Link></li>
            <li><Link to="/scan2O">Scan</Link></li>
            <li><Link to="/about2O">About</Link></li>
            <li><Link to="/contact2O">Contact</Link></li>
            <li><Link to="/scan3O">Scan</Link></li>
            <li><Link to="/about3O">About</Link></li>
            <li><Link to="/contact3O">Contact</Link></li>
            <li><Link to="/scantest">ScanTest</Link></li>
            <li><Link to="/searchpage">Search Page</Link></li>
            <li><Link to="/preferences">Preferences</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/signup">Sign Up</Link></li>
            <li><Link to="/forgotpassword">Forgot Password</Link></li>
            <li><Link to="/specificresult">Specific Result</Link></li>
            <li><Link to="/specificresulttest">Specific Result Test</Link></li>
            <li><Link to="/contactpage">Contact Page</Link></li>
            {/* Continue adding links for new pages */}
          </ul>
        </nav>
        )}

        {/* Route Configuration */}
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/signup" element={<SignUp />} />
          {/* New Routes */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/companysearch" element={<CompanySearch />} />
          <Route path="/companysearchanalytics" element={<CompanySearchAnalytics />} />
          <Route path="/companysearchother" element={<CompanySearchOther />} />
          <Route path="/companysearchfinance" element={<CompanySearchFinance />} />
          <Route path="/companysearchhealth" element={<CompanySearchHealth />} />
          <Route path="/investorsearch" element={<InvestorSearch />} />
          <Route path="/results" element={<Results />} />
          <Route path="/saved" element={<Saved />} />
          <Route path="/about1F" element={<About1F/>} />
          <Route path="/contact1F" element={<Contact1F/>} />
          <Route path="/scan" element={<Scan/>} />
          <Route path="/scan1F" element={<Scan1F/>} />
          <Route path="/about2F" element={<About2F/>} />
          <Route path="/contact2F" element={<Contact2F/>} />
          <Route path="/scan2F" element={<Scan2F/>} />
          <Route path="/about3F" element={<About3F/>} />
          <Route path="/contact3F" element={<Contact3F/>} />
          <Route path="/scan3F" element={<Scan3F/>} />
          <Route path="/about4F" element={<About4F/>} />
          <Route path="/contact4F" element={<Contact4F/>} />
          <Route path="/scan4F" element={<Scan4F/>} />
          <Route path="/about1A" element={<About1A/>} />
          <Route path="/contact1A" element={<Contact1A/>} />
          <Route path="/scan" element={<Scan/>} />
          <Route path="/scan1A" element={<Scan1A/>} />
          <Route path="/about2A" element={<About2A/>} />
          <Route path="/contact2A" element={<Contact2A/>} />
          <Route path="/scan2A" element={<Scan2A/>} />
          <Route path="/about3A" element={<About3A/>} />
          <Route path="/contact3A" element={<Contact3A/>} />
          <Route path="/scan3A" element={<Scan3A/>} />
          <Route path="/about4A" element={<About4A/>} />
          <Route path="/contact4A" element={<Contact4A/>} />
          <Route path="/scan4A" element={<Scan4A/>} />
          <Route path="/about1O" element={<About1O/>} />
          <Route path="/contact1O" element={<Contact1O/>} />
          <Route path="/scan1O" element={<Scan1O/>} />
          <Route path="/about2O" element={<About2O/>} />
          <Route path="/contact2O" element={<Contact2O/>} />
          <Route path="/scan2O" element={<Scan2O/>} />
          <Route path="/about3O" element={<About3O/>} />
          <Route path="/contact3O" element={<Contact3O/>} />
          <Route path="/scan3O" element={<Scan3O/>} />
          <Route path="/scantest" element={<ScanTest />} />
          <Route path="/searchpage" element={<SearchPage />} />
          <Route path="/specificresult" element={<SpecificResult />} />
          <Route path="/specificresulttest" element={<SpecificResultTest />} />
          <Route path="/contactpage" element={<ContactPage />} />
          {/* Default Route */}
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;