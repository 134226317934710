import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
                  style={{ filter: 'grayscale(100%) brightness(2)' }} // Grey icon for Settings
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function Settings() {
  const [photo, setPhoto] = useState(localStorage.getItem('userPhoto') || '');
  const [name, setName] = useState(localStorage.getItem('userName') || '');
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');

  useEffect(() => {
    localStorage.setItem('userName', name);
    localStorage.setItem('userEmail', email);
  }, [name, email]);

  const uploadPhoto = () => {
    // Simulate photo upload logic, this needs actual implementation
    setPhoto('path/to/new/photo.jpg'); // Update with actual path after upload
    localStorage.setItem('userPhoto', photo);
  };

  const deletePhoto = () => {
    setPhoto('');
    localStorage.removeItem('userPhoto');
  };

  return (
    <div className="flex flex-col gap-2 px-5 mt-8 text-base font-medium tracking-normal leading-6 max-w-[966px] max-md:flex-wrap">
      <div className="flex gap-4 justify-center self-start text-right max-md:flex-wrap">
        <div className="shrink-0 gap-0 py-8 w-32 bg-neutral-100 h-[117px] rounded-[1000px]">
          {photo ? <img src={photo} alt="User Photo" className="w-full h-full rounded-full object-cover" /> : null}
        </div>
        <div className="flex gap-3 justify-center my-auto">
          <button className="justify-center px-6 py-4 border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5" onClick={uploadPhoto}>
            Upload New Photo
          </button>
          <button className="justify-center px-6 py-4 text-red-500 bg-rose-50 rounded-[96px] max-md:px-5" onClick={deletePhoto}>
            Delete Photo
          </button>
        </div>
      </div>
      <div className="mt-6 w-full text-xl font-semibold tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Your Info
      </div>
      <div className="mt-6 w-full font-semibold text-gray-600 max-md:flex-wrap max-md:max-w-full">
        Full Name
      </div>
      <input
        className="justify-center px-5 py-4 mt-2 rounded-xl border-2 border-gray-200 border-solid text-slate-800 max-md:flex-wrap max-md:max-w-full"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="mt-4 w-full font-semibold text-gray-600 max-md:flex-wrap max-md:max-w-full">
        Email Address
      </div>
      <input
        className="px-5 pt-4 pb-2 mt-2 whitespace-nowrap rounded-xl border-2 border-gray-200 border-solid text-slate-800 max-md:flex-wrap max-md:max-w-full"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
  );
}

function Update() {
  const handleCancel = () => {
    window.location.reload(); // Reloads the page to revert any unsaved changes
  };

  const handleUpdate = () => {
    alert('Changes saved successfully!');
  };

  return (
    <div className="flex justify-end items-center w-full mt-5">  
      <div className="flex gap-3 text-sm font-medium tracking-normal leading-5 text-center whitespace-nowrap max-w-[412px]">
        <button
          className="flex justify-center items-center px-8 py-2 border border-solid border-zinc-400 rounded-[96px] text-slate-800"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="flex justify-center items-center px-8 py-2 text-white bg-teal-500 rounded-[96px]"
          onClick={handleUpdate}
        >
          Update
        </button>
      </div>
    </div>
  );
}





function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <Settings className="mt-4" />
        <Update />
      </div>
    </div>
  );
}


export default ParentLayout;