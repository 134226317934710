import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'grayscale(100%) brightness(2)' }} // Grey icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}


function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}






function DashboardPage() {
  const navigate = useNavigate();
  const initialSavedState = JSON.parse(localStorage.getItem('savedCompanies')) || new Array(4).fill(false);
  const [saved, setSaved] = useState(initialSavedState);

  useEffect(() => {
    localStorage.setItem('savedCompanies', JSON.stringify(saved));
  }, [saved]);

  const handleSave = (index) => {
    const newSaved = [...saved];
    newSaved[index] = !newSaved[index];
    setSaved(newSaved);
  };

  // Define companies for Finance and Analytics rows
  const avenir = {
    name: 'Avenir',
    img: 'https://cdn.prod.website-files.com/661e965fa0ea3712f7d8aea3/661e9fb996e7cae77a611ef8_Layer_1.svg',
    industry: 'Wealth Management',
    id: 1,
  };

  const paynetx = {
    name: 'PayNetX',
    img: 'https://static.wixstatic.com/media/fcd170_2fee051485764e8996766772a704a7e6~mv2.png/v1/crop/x_65,y_0,w_930,h_1060/fill/w_85,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PayNetX%20Logo.png',
    industry: 'FinTech',
    id: 2,
  };

  const zyggy = {
    name: 'Zyggy',
    img: 'https://images.squarespace-cdn.com/content/v1/63d8070df90eaf03f4104837/7887c6f0-2bf9-469a-9867-36bc088042ec/Zyggy+Logo02.png?format=1500w',
    industry: 'FinTech',
    id: 3,
  };

  const radom = {
    name: 'Radom',
    img: 'https://cdn.prod.website-files.com/6340702a42dd5b5ba0404a59/65ea07ffacb8bba977c91491_Logotype.svg',
    industry: 'Cryptocurrency',
    id: 4,
  };

  const contextAI = {
    name: 'Context.ai',
    img: 'https://with.context.ai/assets/full_logo-d7c8d4f1d8637fe46c18bfe516ff24f0e7054bf429df4621ef78fa279e4f1a53.svg',
    industry: 'Data Analytics',
    id: 5,
  };

  const upsolveAI = {
    name: 'Upsolve.ai',
    img: 'https://framerusercontent.com/images/DJHjD5SmUc89JSBdK8ehXziRGZk.png?scale-down-to=512',
    industry: 'Customer Analytics',
    id: 6,
  };

  const passBy = {
    name: 'Pass-by',
    img: 'https://passby.com/wp-content/uploads/2023/08/logo-tight.png',
    industry: 'Geospatial',
    id: 7,
  };

  const tabbx = {
    name: 'TABBx',
    img: 'https://media.licdn.com/dms/image/D4E0BAQFoPeVeKzopxQ/company-logo_200_200/0/1691616045081?e=1726099200&v=beta&t=4ioGS-IbSjih_p3YjsaCNXS0Or9gAZMSMvFwBC8LuIY',
    industry: 'Big Data',
    id: 8,
  };

  // Define Elyos Energy, Kaikaku AI, and Altelium
  const elyosEnergy = {
    name: 'Elyos Energy',
    img: 'https://assets-global.website-files.com/64b7471c44695f55834b7e14/64b8dc7a2a0ea1a5e36827b2_ELYOS%20ENERGY%20(500%20%C3%97%20200px)%20(190%20%C3%97%2040px)%20(500%20%C3%97%20200px)%20(1)-p-500.png',
    industry: 'Other',
    subIndustry: 'Energy',
    id: 9,
  };

  const kaikakuAI = {
    name: 'Kaikaku AI',
    img: 'https://media.licdn.com/dms/image/D4D0BAQGh9cGONqdnWA/company-logo_200_200/0/1684836542701/kaikaku_ai_logo?e=1726704000&v=beta&t=SGCXJZ4o8NZ4PsO5B1CsIq3VrL0OEagv_UTCDSXviKs', // Kaikaku AI logo URL
    industry: 'Other',
    subIndustry: 'Robotics',
    id: 10,
  };

  const altelium = {
    name: 'Altelium',
    img: 'https://media.licdn.com/dms/image/D4E0BAQHGNPmC8xj6pA/company-logo_200_200/0/1705231283890/altelium_logo?e=1726704000&v=beta&t=9Ciriucf4liiBtHaPeb12-RasHm8Q0sBg6vw6v2Srvs', // Altelium logo URL
    industry: 'Other',
    subIndustry: 'Technology',
    id: 11,
  };

  // Update the "Other" row to include Elyos Energy, Kaikaku AI, and Altelium
  const otherCompanies = [
    elyosEnergy,
    kaikakuAI,
    altelium,
  ];

  const financeCompanies = [avenir, paynetx, zyggy, radom];
  const analyticsCompanies = [contextAI, upsolveAI, passBy, tabbx];

  const industries = [
    { name: 'Finance', companies: financeCompanies },
    { name: 'Analytics', companies: analyticsCompanies },
    { name: 'Other', companies: otherCompanies },
  ];

  return (
    <div className="flex bg-slate-100 min-h-screen">
      {/* Sidebar component */}
      <Sidebar />
      <div className="flex flex-col grow pl-[220px] items-center">
        <div className="flex justify-between w-full">
          {/* SearchBar component */}
          <SearchBar />
          {/* Name component */}
          <Name />
        </div>
        <div className="flex flex-col px-6 py-4 items-center w-full">
          <div className="text-2xl font-bold mb-4 text-center">Top Industries & Companies</div>
          {industries.map((industry, industryIndex) => (
            <div key={industryIndex} className="flex flex-col px-5 mt-4 w-full max-w-[900px] bg-slate-100">
              <div className="self-center text-lg font-bold text-teal-500">
                {industry.name} Companies
              </div>
              <div className="flex gap-4 pt-4 -mt-1 justify-center w-full max-md:flex-wrap max-md:max-w-full">
                {industry.companies.map((company, index) => (
                  <div key={index} className="flex flex-col w-[23%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow pt-6 pr-4 pl-4 pb-2 rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-4">
                      <img
                        loading="lazy"
                        src={company.img}
                        className="aspect-[3.23] w-[150px] max-w-full"
                        alt="Company Logo"
                      />
                      <div className="mt-6 text-xl font-semibold tracking-normal leading-9 text-slate-800">
                        {company.name}
                      </div>
                      {company.industry && (
                        <div className="text-sm text-gray-500 mt-1">
                          {company.industry}
                        </div>
                      )}
                      <div className="flex grow" />
                      <div className="flex gap-4 mt-auto mb-2">
                        <button
                          className="flex-1 justify-center px-4 py-3 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-full text-slate-800 cursor-pointer"
                          style={{ minWidth: '120px' }}
                          onClick={() => {
                            switch (company.id) {
                              case 1:
                                navigate('/about1F');
                                break;
                              case 2:
                                navigate('/about2F');
                                break;
                              case 3:
                                navigate('/about3F');
                                break;
                              case 4:
                                navigate('/about4F');
                                break;
                              case 5:
                                navigate('/about1A');
                                break;
                              case 6:
                                navigate('/about2A');
                                break;
                              case 7:
                                navigate('/about3A');
                                break;
                              case 8:
                                navigate('/about4A');
                                break;
                              case 9:
                                navigate('/about1O');
                                break;
                              case 10:
                                navigate('/about2O');
                                break;
                              case 11:
                                navigate('/about3O');
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          Deep Dive
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  className="justify-center px-2 py-2 my-auto text-lg font-medium leading-7 text-gray-700 bg-white rounded-lg border-0 border border-solid shadow-sm max-md:px-2 cursor-pointer"
                  style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    switch (industry.name) {
                      case 'Finance':
                        navigate('/CompanySearchFinance');
                        break;
                      case 'Analytics':
                        navigate('/companysearchanalytics');
                        break;
                      case 'Other':
                        navigate('/companysearchother');
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  See All
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}




export default DashboardPage;





