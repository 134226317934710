import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}



function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackToFilters() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/dashboard')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Dashboard
      </div>
    </div>
  );
}

// function ChangeView() {
//   const navigate = useNavigate();
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [selectedOption, setSelectedOption] = useState('');

//   const handleSortOptionClick = (option) => {
//     console.log(`Sorting by: ${option}`); // Replace with actual sorting logic
//     setSelectedOption(option);
//     setShowDropdown(false);
//   };

//   useEffect(() => {
//     const closeDropdown = (event) => {
//       if (event.target.className !== 'dropdown-toggle') {
//         setShowDropdown(false);
//       }
//     };

//     document.addEventListener('click', closeDropdown);
//     return () => document.removeEventListener('click', closeDropdown);
//   }, []);

//   return (
//     <div className="flex gap-5 justify-between self-end mt-8 mr-20 max-w-full w-[677px] max-md:flex-wrap max-md:mr-2.5">
//       <div className="flex gap-5 justify-between my-auto text-xs tracking-tight leading-6 uppercase whitespace-nowrap text-zinc-500">
//         <button
//           className="hover:text-teal-500 hover:font-bold cursor-pointer"
//           onClick={() => navigate('/results')}
//           style={{ outline: 'none' }}
//         >
//           SUMMARY
//         </button>
//         <div className="flex flex-col justify-end text-center cursor-default">
//           <button
//             className="text-teal-500 hover:text-teal-500 hover:font-bold"
//             style={{ outline: 'none' }}
//             onClick={() => {}}  // Does nothing as it's already the active page
//           >
//             COMPANIES
//           </button>
//           <div className="shrink-0 h-0.5 bg-teal-500" />
//         </div>
//         <button
//           className="hover:text-teal-500 hover:font-bold cursor-pointer"
//           onClick={() => navigate('/investorsearch')}
//           style={{ outline: 'none' }}
//         >
//           INVESTORS
//         </button>
//       </div>
//       <div className="relative flex gap-1 py-1 pr-2 pl-1 text-sm font-bold leading-5 text-center text-teal-500 bg-white rounded-2xl border border-teal-500 border-solid cursor-pointer">
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/0410b4c2162e63a0ba8752c36e41adf8172bfd8a5b8004434c58a1ce863f9094?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
//           className="shrink-0 self-start w-5 aspect-square"
//         />
//         <div className="dropdown-toggle" onClick={() => setShowDropdown(!showDropdown)}>Sort By</div>
//         {showDropdown && (
//           <div className="absolute right-0 mt-1 py-1 w-52 bg-white rounded-md shadow-lg z-10">
//             <ul className="text-gray-700">
//               {["Location (nearest to furthest)", "Founded date (oldest to newest)", "Founded date (newest to oldest)", "Stage (earliest to latest)", "Stage (latest to earliest)", "Number of employees (least to most)", "Number of employees (most to least)"].map((option) => (
//                 <li key={option} className="flex justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-teal-500" onClick={() => handleSortOptionClick(option)}>
//                   {option}
//                   {selectedOption === option && <span className="text-teal-500">✔</span>}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }


function Results() {
  const navigate = useNavigate();
  const initialLikes = JSON.parse(localStorage.getItem('likes')) || new Array(4).fill(false);
  const [likes, setLikes] = useState(initialLikes);

  useEffect(() => {
    localStorage.setItem('likes', JSON.stringify(likes));
  }, [likes]);

  const toggleLike = (index) => {
    const newLikes = [...likes];
    newLikes[index] = !newLikes[index];
    setLikes(newLikes);
  };

  const avenirDescription = "Avenir is a UK-based wealth management solutions provider that leverages cutting-edge technology and artificial intelligence to offer personalized wealth management solutions at scale.";
  const paynetxDescription = "PayNetX is a FinTech company focused on developing innovative payment solutions using Distributed Ledger Technology and AI to enhance global financial transactions.";
  const zyggyDescription = "Zyggy is a FinTech startup providing cross-border payment solutions for Africans in the diaspora and within Sub-Saharan Africa, focusing on P2P payments and virtual cards.";
  const radomDescription = "Radom is a cutting-edge cryptocurrency payment gateway, offering businesses a comprehensive suite of solutions for cryptocurrency payments.";

  return (
    <div className="flex flex-col pr-20 pl-4 mt-3 max-md:pr-5 max-md:max-w-full">
      {/* Avenir */}
      <div className="flex flex-col px-6 pt-4 pb-1 mt-3 mb-4 rounded-2xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.prod.website-files.com/661e965fa0ea3712f7d8aea3/661e9fb996e7cae77a611ef8_Layer_1.svg"
          className="max-w-full aspect-[3.23] w-[104px] object-contain"
          alt="Avenir Logo"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-slate-800 max-md:max-w-full">
          Wealth Management
        </div>
        <div className="flex gap-1 self-start mt-1 text-sm text-neutral-400">
          <div className="flex flex-col justify-center px-2 py-1 rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/686c5e947a903fdd771c4b1f554e50806d53d68c9be361a5a5d5552270b0df3b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>UK</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify whitespace-nowrap bg-white rounded border border-teal-500 border-solid">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc14ecba1321df96b7c252f73e1c046f71257d8c9baee7ad53b82bd490f02ef2?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>2023</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/df175bcb38620021b6cfc913e991ad6a3450f44fc06ec249930b429951f10e3a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>Seed</div>
            </div>
          </div>
        </div>
        <div className="mt-1 mr-10 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:mr-2.5 max-md:max-w-full">
          {avenirDescription}
        </div>
        <div className="flex gap-3 mt-4 mb-4 max-md:flex-wrap max-md:max-w-full">
          <button
            className="flex-1 justify-center items-center px-8 py-4 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5 max-md:max-w-full cursor-pointer"
            onClick={() => navigate('/about1F')}
          >
            Deep Dive
          </button>
        </div>
      </div>

      {/* PayNetX */}
      <div className="flex flex-col px-6 pt-4 pb-1 mt-3 mb-4 rounded-2xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://static.wixstatic.com/media/fcd170_2fee051485764e8996766772a704a7e6~mv2.png/v1/crop/x_65,y_0,w_930,h_1060/fill/w_85,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PayNetX%20Logo.png"
          className="max-w-full aspect-[3.23] w-[104px] object-contain"
          alt="PayNetX Logo"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-slate-800 max-md:max-w-full">
          FinTech
        </div>
        <div className="flex gap-1 self-start mt-1 text-sm text-neutral-400">
          <div className="flex flex-col justify-center px-2 py-1 rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/686c5e947a903fdd771c4b1f554e50806d53d68c9be361a5a5d5552270b0df3b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>UK</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify whitespace-nowrap bg-white rounded border border-teal-500 border-solid">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc14ecba1321df96b7c252f73e1c046f71257d8c9baee7ad53b82bd490f02ef2?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>2023</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/df175bcb38620021b6cfc913e991ad6a3450f44fc06ec249930b429951f10e3a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>Pre-Seed</div>
            </div>
          </div>
        </div>
        <div className="mt-1 mr-10 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:mr-2.5 max-md:max-w-full">
          {paynetxDescription}
        </div>
        <div className="flex gap-3 mt-4 mb-4 max-md:flex-wrap max-md:max-w-full">
          <button
            className="flex-1 justify-center items-center px-8 py-4 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5 max-md:max-w-full cursor-pointer"
            onClick={() => navigate('/about2F')}
          >
            Deep Dive
          </button>
        </div>
      </div>

      {/* Zyggy */}
      <div className="flex flex-col px-6 pt-4 pb-1 mt-3 mb-4 rounded-2xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://images.squarespace-cdn.com/content/v1/63d8070df90eaf03f4104837/7887c6f0-2bf9-469a-9867-36bc088042ec/Zyggy+Logo02.png?format=1500w"
          className="max-w-full aspect-[3.23] w-[104px] object-contain"
          alt="Zyggy Logo"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-slate-800 max-md:max-w-full">
          FinTech
        </div>
        <div className="flex gap-1 self-start mt-1 text-sm text-neutral-400">
          <div className="flex flex-col justify-center px-2 py-1 rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/686c5e947a903fdd771c4b1f554e50806d53d68c9be361a5a5d5552270b0df3b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>UK</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify whitespace-nowrap bg-white rounded border border-teal-500 border-solid">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc14ecba1321df96b7c252f73e1c046f71257d8c9baee7ad53b82bd490f02ef2?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>2023</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/df175bcb38620021b6cfc913e991ad6a3450f44fc06ec249930b429951f10e3a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>Seed</div>
            </div>
          </div>
        </div>
        <div className="mt-1 mr-10 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:mr-2.5 max-md:max-w-full">
          {zyggyDescription}
        </div>
        <div className="flex gap-3 mt-4 mb-4 max-md:flex-wrap max-md:max-w-full">
          <button
            className="flex-1 justify-center items-center px-8 py-4 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5 max-md:max-w-full cursor-pointer"
            onClick={() => navigate('/about3F')}
          >
            Deep Dive
          </button>
        </div>
      </div>

      {/* Radom */}
      <div className="flex flex-col px-6 pt-4 pb-1 mt-3 mb-4 rounded-2xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.prod.website-files.com/6340702a42dd5b5ba0404a59/65ea07ffacb8bba977c91491_Logotype.svg"
          className="max-w-full aspect-[3.23] w-[104px] object-contain"
          alt="Radom Logo"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-slate-800 max-md:max-w-full">
          Cryptocurrency Payment
        </div>
        <div className="flex gap-1 self-start mt-1 text-sm text-neutral-400">
          <div className="flex flex-col justify-center px-2 py-1 rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/686c5e947a903fdd771c4b1f554e50806d53d68c9be361a5a5d5552270b0df3b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>UK</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify whitespace-nowrap bg-white rounded border border-teal-500 border-solid">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc14ecba1321df96b7c252f73e1c046f71257d8c9baee7ad53b82bd490f02ef2?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>2023</div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-2 py-1 text-justify rounded border border-teal-500 border-solid bg-slate-50">
            <div className="flex gap-1">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/df175bcb38620021b6cfc913e991ad6a3450f44fc06ec249930b429951f10e3a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="shrink-0 my-auto w-3 aspect-square"
              />
              <div>Seed</div>
            </div>
          </div>
        </div>
        <div className="mt-1 mr-10 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:mr-2.5 max-md:max-w-full">
          {radomDescription}
        </div>
        <div className="flex gap-3 mt-4 mb-4 max-md:flex-wrap max-md:max-w-full">
          <button
            className="flex-1 justify-center items-center px-8 py-4 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-[96px] text-slate-800 max-md:px-5 max-md:max-w-full cursor-pointer"
            onClick={() => navigate('/about4F')}
          >
            Deep Dive
          </button>
        </div>
      </div>
    </div>
  );
}


function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}


function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackToFilters />
        {/* <ChangeView /> */}
        <Results className="mt-4" />
        <RefactoredFooter />
      </div>
    </div>
  );
}


export default ParentLayout;