import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}


function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://media.licdn.com/dms/image/D4D0BAQGh9cGONqdnWA/company-logo_200_200/0/1684836542701/kaikaku_ai_logo?e=1726704000&v=beta&t=SGCXJZ4o8NZ4PsO5B1CsIq3VrL0OEagv_UTCDSXviKs"
          className="w-full h-full object-contain"
          alt="KaikakuAI Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            KaikakuAI
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Robotics</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://www.linkedin.com/company/kaikaku-ai/?originalSubdomain=uk" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


  
  
function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$10M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$1.8M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$1.8M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about2O');
  };

  const navigateToScanPage = () => {
    navigate('/Scan2O');
  };

  const navigateToContactPage = () => {
    navigate('/contact2O');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Traction", "Monetisation", "Advantage", "Barriers",];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="barriers" className="self-center tracking-normal">
        Barriers to Entry Analysis
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>The market for food services and technology solutions, as exemplified by companies like KAIKAKU, faces significant barriers to entry. One key barrier is the high level of expertise and specialization required in both the food and beverage (F&B) industry and technology sector. Companies like KAIKAKU, founded by Josef Chen, combine knowledge of F&B operations with technological innovation to create solutions that automate processes and reduce food waste. This specialized skill set can be difficult for new entrants to replicate without significant investment in talent and resources.</p>
        <p>Another barrier is the need for substantial capital investment to develop and implement technology solutions in the restaurant industry. Automation technologies, such as those developed by KAIKAKU, require upfront investment in research, development, and implementation. This can deter new entrants who may not have access to the necessary funding to compete effectively in the market.</p>
        <p>Furthermore, established players in the food services industry, such as Sweetgreen, are already implementing automated solutions to improve efficiency and reduce waste. This early adoption of technology by industry leaders can create a barrier for new entrants looking to introduce similar innovations. The competitive landscape may be dominated by companies with established technological infrastructure and customer bases, making it challenging for new entrants to gain a foothold in the market.</p>
        <p>Regulatory barriers, such as food safety standards and licensing requirements, also pose challenges for new entrants in the food services industry. Compliance with industry regulations can be complex and time-consuming, requiring additional resources and expertise to navigate effectively.</p>
        <p>In conclusion, the market for food services and technology solutions is characterized by significant barriers to entry, including the need for specialized expertise, high capital investment, competition from established players, and regulatory requirements. Overcoming these barriers will require new entrants to demonstrate innovation, differentiation, and a deep understanding of both the F&B industry and technology sector.</p>
      </div>
      
      <div id="advantage" className="self-center tracking-normal">
        Competitive Advantage Analysis
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The robotics industry in Europe is experiencing significant growth, with startups raising over $2 billion in funding in 2023. This sector is attracting interest from corporate investors, with a focus on service robots that assist humans in various tasks beyond traditional industrial applications.</p>
        <p><strong>1. Key Trends and Market Insights:</strong> Service robots, particularly those designed for healthcare and companion purposes, are gaining traction in the market. Startups like Akara Robotics and Intuition Robotics are developing robots for cleaning hospital rooms and providing companionship to older adults, respectively.</p>
        <p>Robotics startups are addressing challenges in various sectors, including manufacturing, healthcare, and logistics. Companies like GrayMatter Robotics and Energy Robotics are developing solutions to fill labor gaps and improve efficiency in these industries.</p>
        <p><strong>2. Corporate Investment:</strong> Corporate investors are showing renewed interest in robotics startups, with significant funding rounds being raised by companies like Figure and RoboEatz. However, securing investment in this sector remains challenging, and startups need to align their products with investor values to attract funding.</p>
        <p><strong>3. Competitive Landscape:</strong> RoboEatz, a kitchen robot maker, and GreyMatter Robotics, an industrial robot arm manufacturer, are among the startups exhibiting at the Global Corporate Venturing and Innovation Summit. Intuition Robotics, a developer of AI companion hardware for older adults, has received backing from corporate investors like Samsung Next, Toyota Ventures, and Woven Capital.</p>
        <p>Startups like Wandelbots, Energy Robotics, and HausBots are developing innovative robotics solutions for automation, fleet management, and inspection tasks, respectively.</p>
        <p>Overall, the robotics industry in Europe is witnessing a surge in innovation and investment, driven by the growing demand for robotic solutions across various sectors. Startups that can address key market trends and align with investor values are well-positioned to capitalize on the opportunities in this dynamic and evolving market.</p>
      </div>
      
      <div id="founders" className="self-center tracking-normal">
        Founding Team
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The founding team of the startup KAIKAKU consists of Josef Chen, the CEO and co-founder, Dr. David Sharp, and Piers Millar. Josef Chen brings a unique blend of expertise in both the food and beverage (F&B) industry and technology, stemming from his early exposure to the restaurant business in his family-owned Chinese restaurant. With a vision to revolutionize the restaurant industry, Josef aims to automate repetitive tasks in the kitchen to augment human capabilities and address chronic staff shortages and low job satisfaction prevalent in the sector.</p>
        <p>Dr. David Sharp, a former Ocado Technology Executive, adds valuable technological insights to the team, while Piers Millar, the UK's youngest McDonald's manager and LSE-dropout, contributes operational expertise. Together, they aim to transform every aspect of the restaurant experience, from ordering and food preparation to customer service, by leveraging robotics and AI to streamline processes and reduce food waste.</p>
        <p>Josef Chen's ambitious goal is to fully integrate and revolutionize the restaurant industry by introducing end-to-end automation and enhancing customer experiences. The team is focused on creating a future restaurant model where technology enhances efficiency while maintaining high-quality food standards. They are also working on innovative features like AI-driven food suggestions based on customer preferences, aiming to provide a delightful and personalized dining experience.</p>
        <p>KAIKAKU's philosophy emphasizes the importance of technology in creating enjoyable jobs and enhancing employee skills through education and training. By automating repetitive tasks, the team envisions a future where staff can focus on customer interactions while technology handles backend operations efficiently. Overall, the diverse expertise and shared vision of the founding team position KAIKAKU as a pioneering force in reshaping the restaurant industry through innovative technology solutions and customer-centric approaches.</p>
      </div>
      
      <div id="monetisation" className="self-center tracking-normal">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The monetisation strategy of the startup KAIKAKU focuses on leveraging technology to revolutionize the restaurant industry by automating repetitive tasks and reducing food waste. Founded in April 2023 by Josef Chen, the CEO and co-founder, KAIKAKU aims to augment human capabilities in the F&B sector through innovative solutions.</p>
        <p>One key aspect of KAIKAKU's strategy is to address chronic staff shortages and low job satisfaction in the industry by automating repetitive chores in the kitchen. By introducing technology that can learn customer preferences and suggest personalized food orders, KAIKAKU aims to enhance customer experience and increase operational efficiency.</p>
        <p>The startup's approach to monetization involves offering technology solutions to QSR restaurants to improve their processes and reduce food waste. By ensuring that restaurants do not overproduce or discard food due to human error, KAIKAKU aims to help businesses increase their profitability and sustainability.</p>
        <p>Furthermore, KAIKAKU's focus on creating jobs that people enjoy and providing education and training in the industry sets it apart from traditional approaches. The company's emphasis on leveraging technology for job creation and enhancing customer experience positions it as a forward-thinking player in the market.</p>
        <p>In terms of pricing models, KAIKAKU may adopt a subscription-based model for its technology solutions, offering different tiers of services based on the scale and requirements of the restaurant. Additionally, the startup could generate revenue through partnerships with QSR chains and licensing its technology to other players in the industry.</p>
        <p>Overall, KAIKAKU's monetisation strategy aligns with the growing trend of technology adoption in the F&B sector and presents a unique value proposition for restaurants looking to streamline operations and reduce food waste. By focusing on innovation and customer-centric solutions, KAIKAKU has the potential to drive significant growth and success in the market.</p>
      </div>
      
      <div id="traction" className="self-center tracking-normal">
        Traction
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The robotics startup KAIKAKU, founded by Josef Chen in April 2023, has already made significant strides in the food services industry. With a focus on reducing food waste and streamlining operations, KAIKAKU's solutions are gaining traction among QSR restaurants. The company's innovative approach to leveraging technology for job creation and customer experience enhancement is attracting attention and interest from industry players.</p>
        <p>Since its inception, KAIKAKU has been working on automating repetitive tasks in the kitchen to address chronic staff shortages and improve job satisfaction. By using technology to learn customer preferences and suggest personalized food orders, the startup aims to revolutionize the restaurant experience. KAIKAKU's solutions have the potential to significantly reduce food waste, increase efficiency, and enhance customer satisfaction.</p>
        <p>KAIKAKU's focus on providing end-to-end automation in the restaurant industry, from food preparation to customer service, positions it as a leading player in the market. The company's technology solutions are designed to create jobs that people enjoy and provide education and training to enhance employee skills. This approach sets KAIKAKU apart from traditional players in the industry and aligns with the growing trend of technology adoption in the food services sector.</p>
        <p>Overall, KAIKAKU's traction in the market is evident through its innovative technology solutions and customer-centric approach. The company's commitment to reducing food waste and improving job satisfaction in the industry positions it for continued growth and success in the future.</p>
      </div>
    </div>
  );
}

  

  

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
          <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;