import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
                  style={{ filter: 'grayscale(100%) brightness(2)' }} // Grey icon for Saved
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}



function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}




function ChangeView() {
  const [selectedView, setSelectedView] = useState('companies'); // Default to 'companies'

  return (
    <div className="flex justify-center items-center h-full w-full" style={{ marginTop: '2cm' }}>
      <div className="flex flex-col gap-5 items-center">
        <div className="flex gap-5 justify-center px-5 text-xs tracking-tight leading-6 uppercase whitespace-nowrap max-w-[203px]">
          <button
            className={`flex flex-col gap-0.5 justify-end text-center cursor-pointer ${selectedView === 'companies' ? 'text-teal-500' : 'text-zinc-500'}`}
            onClick={() => setSelectedView('companies')}
          >
            COMPANIES
            <div className={`shrink-0 gap-0 h-0.5 ${selectedView === 'companies' ? 'bg-teal-500' : 'bg-transparent'}`} />
          </button>
          <button
            className={`flex flex-col gap-0.5 justify-end text-center cursor-pointer ${selectedView === 'investors' ? 'text-teal-500' : 'text-zinc-500'}`}
            onClick={() => setSelectedView('investors')}
          >
            INVESTORS
            <div className={`shrink-0 gap-0 h-0.5 ${selectedView === 'investors' ? 'bg-teal-500' : 'bg-transparent'}`} />
          </button>
        </div>
        {/* {selectedView === 'companies' && <SavedCompanies />} */}
        {/* {selectedView === 'investors' && <SavedInvestors />} */}
      </div>
    </div>
  );
}

// function SavedCompanies() {
//   const navigate = useNavigate();
//   const initialSavedState = JSON.parse(localStorage.getItem('savedCompanies')) || new Array(3).fill(false);
//   const [saved, setSaved] = useState(initialSavedState);

//   useEffect(() => {
//     localStorage.setItem('savedCompanies', JSON.stringify(saved));
//   }, [saved]);

//   const handleSave = (index) => {
//     const newSaved = [...saved];
//     newSaved[index] = !newSaved[index];
//     setSaved(newSaved);
//   };

//   const savedCompanies = Array.from({ length: 3 }).filter((_, index) => !saved[index]);

//   return (
//     <div className="flex flex-col px-5 mt-4 max-w-[800px] bg-slate-100">
//       <div className="self-start text-lg font-bold text-gray-950" style={{ alignSelf: 'center' }}>
//         Saved Companies
//       </div>
//       <div className="flex gap-4 pt-4 -mt-1 w-full max-md:flex-wrap max-md:max-w-full">
//         {savedCompanies.map((_, index) => (
//           <div key={index} className="flex flex-col w-[100%] max-md:ml-0 max-md:w-full">
//             <div className="flex flex-col grow pt-6 pr-4 pl-4 pb-2 rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-4">
//               <img
//                 loading="lazy"
//                 src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${index === 0 ? "a5c3893348c0467618968c24121eeb63bab74f9893bc894d2c6b5dac4d899b1d" : index === 1 ? "114cbefc00a0a4c0e20094b7a22351234c0d9b2a4556f80d7a4930a66cb35d74" : "111d36319abce3b6f44654482b7c19ed5eb3b0d0ec19683435babf6e1e87e4ec"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
//                 className="aspect-[3.23] w-[120px] max-w-full"
//               />
//               <div className="mt-6 text-xl font-semibold tracking-normal leading-9 text-slate-800">
//                 {index === 0 ? "Biotechnology" : index === 1 ? "FinTech" : "Consumer"}
//               </div>
//               <div className="flex gap-4 mt-6">
//                 <button
//                   className="flex-1 justify-center px-4 py-3 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-full text-slate-800 cursor-pointer"
//                   style={{ minWidth: '120px' }}
//                   onClick={() => navigate(`/deep-dive/${index}`)}
//                 >
//                   Deep Dive
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// function SavedInvestors() {
//   const navigate = useNavigate();
//   const initialSavedState = JSON.parse(localStorage.getItem('savedInvestors')) || new Array(3).fill(false);
//   const [saved, setSaved] = useState(initialSavedState);

//   useEffect(() => {
//     localStorage.setItem('savedInvestors', JSON.stringify(saved));
//   }, [saved]);

//   const handleSave = (index) => {
//     const newSaved = [...saved];
//     newSaved[index] = !newSaved[index];
//     setSaved(newSaved);
//   };

//   const savedInvestors = Array.from({ length: 3 }).filter((_, index) => !saved[index]);

//   return (
//     <div className="flex flex-col px-5 mt-4 max-w-[800px] bg-slate-100">
//       <div className="self-start text-lg font-bold text-gray-950" style={{ Self: 'center' }}>
//         Saved Investors
//       </div>
//       <div className="flex gap-4 pt-4 -mt-1 w-full max-md:flex-wrap max-md:max-w-full">
//         {savedInvestors.map((_, index) => (
//           <div key={index} className="flex flex-col w-[100%] max-md:ml-0 max-md:w-full">
//             <div className="flex flex-col grow pt-6 pr-4 pl-4 pb-2 rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-4">
//               <img
//                 loading="lazy"
//                 src={`https://cdn.builder.io/api/v1/image/assets/TEMP/${index === 0 ? "1910f413d8cadf488cbd871e222cfecbef54385b5c88cbc31254298c33f26226" : index === 1 ? "6d06ec7f3d8faef4e943dedbb55156ed1922d1550d0221594f1d38ed70fa353d" : "13e71bad72cbf32c2dea79f1e1622cf60bec8a2d7a250c474f49213d8f5cbb65"}?apiKey=f2701ed1217a4202b9e82b22944a8abc&`}
//                 className="aspect-[3.23] w-[120px] max-w-full"
//               />
//               <div className="mt-6 text-xl font-semibold tracking-normal leading-9 text-slate-800">
//                 {index === 0 ? "Growth Stage" : index === 1 ? "Pre-Seed" : "Late Stage"}
//               </div>
//               <div className="flex gap-4 mt-6">
//                 <button
//                   className="flex-1 justify-center px-4 py-3 text-sm font-medium tracking-normal leading-5 text-center border border-solid border-zinc-400 rounded-full text-slate-800 cursor-pointer"
//                   style={{ minWidth: '120px' }}
//                   onClick={() => navigate(`/investor-details/${index}`)}
//                 >
//                   Deep Dive
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }


function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
        <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <ChangeView />
      </div>
    </div>
  );
}

export default ParentLayout;