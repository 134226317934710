import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import './App.css';  // Import your custom CSS file


function MyPage() {
  // Assuming showNavigation is a state variable or prop
  const showNavigation = true;

  return (
    <div>
      <h1>My Page</h1>
      {showNavigation && (
        <div class="mt-3">
          <nav>
            <ul>
              {/* Add Link components for navigation */}
              <li><Link to="/login">Log In</Link></li>
              <li><Link to="/landingpage">Landing Page</Link></li>
              {/* Add new links */}
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/companysearch">Company Search</Link></li>
              <li><Link to="/investorsearch">Investor Search</Link></li>
              <li><Link to="/results">Results</Link></li>
              <li><Link to="/saved">Saved</Link></li>
              <li><Link to="/scan">Scan</Link></li>
              <li><Link to="/searchpage">Search Page</Link></li>
              <li><Link to="/preferences">Preferences</Link></li>
              <li><Link to="/settings">Settings</Link></li>
              <li><Link to="/signup">Sign Up</Link></li>
              <li><Link to="/forgotpassword">Forgot Password</Link></li>
              <li><Link to="/specificresult">Specific Result</Link></li>
              {/* Continue adding links for new pages */}
            </ul>
          </nav>
        </div>
      )}
      {/* Your page content goes here */}
    </div>
  );
}

function StickyHeader() {
  return (
    <div className="sticky-header">
      <div className="flex gap-5 justify-around px-5 w-full text-lg bg-slate-100 max-w-full pt-4 fixed top-0 left-0 right-0 z-50">
        <button onClick={() => window.scrollTo(0, 0)} className="flex-auto text-4xl font-bold text-teal-500 lowercase" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          scoutdd
        </button>
        <div className="flex gap-12 justify-around self-start mt-2.5 text-black">
          <button onClick={() => console.log('Navigate to Products')}>Products</button>
          <button onClick={() => console.log('Navigate to About Us')}>About Us</button>
          <button onClick={() => console.log('Navigate to Tour')}>Tour</button>
          <button onClick={() => console.log('Navigate to Get in Touch')}>Get in Touch</button>
        </div>
        <div className="flex flex-row gap-5 justify-end pl-36 text-center leading-[135%]">
          <button className="flex justify-center items-center font-semibold text-white bg-teal-500 px-8 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/signup'}>
            Sign Up
          </button>
          <button className="flex justify-center items-center text-teal-500 overflow-auto px-10 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/login'}>
            Log In
          </button>
        </div>
      </div>
    </div>
  );
}

function RefactoredHeader() {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg" className="bg-slate-100 bg-light sticky-top">
    <div className="flex gap-5 justify-around px-5 w-full text-lg bg-slate-100 max-w-full pt-4 fixed top-0 left-0 right-0 z-50">
        <button onClick={() => navigate('/landingpage')} className="flex-auto text-4xl font-bold text-teal-500 lowercase" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          scoutdd
        </button>
        <div className="flex gap-12 justify-around self-start mt-2.5 text-black">
          <button onClick={() => console.log('Navigate to Products')}>Products</button>
          <button onClick={() => console.log('Navigate to About Us')}>About Us</button>
          <button onClick={() => console.log('Navigate to Tour')}>Tour</button>
          <button onClick={() => console.log('Navigate to Get in Touch')}>Get in Touch</button>
        </div>
        <div className="flex flex-row gap-5 justify-end pl-36 text-center leading-[135%]">
          <button className="flex justify-center items-center font-semibold text-white bg-teal-500 px-8 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/signup'}>
            Sign Up
          </button>
          <button className="flex justify-center items-center text-teal-500 overflow-auto px-10 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/login'}>
            Log In
          </button>
        </div>
      </div>
  </Navbar>
  );
}

function LogIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (email === 'salekh.mahmood@hotmail.com' && password === 'stevengerrard8') {
      window.location.href = '/dashboard'; // Redirect to dashboard page if credentials are correct
    } else {
      setError('Please enter valid details'); // Show error if credentials are incorrect
    }
  };

  return (
    <div className="flex justify-center items-center px-16 pt-12 pb-20 bg-slate-100 max-md:px-5">  
      <div className="flex gap-5 mt-14 max-md:flex-wrap max-md:mt-10">
        {/* <div className="flex justify-center items-center self-start p-4 mt-20 border border-solid border-zinc-400 rounded-[96px] max-md:mt-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/987e99e5b3a66a686b5c5a0855951b0c0b7b0966ac6cb6b5d325e7a328e7446b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="w-5 aspect-square"
          />
        </div> */}
        <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b034c1118642bcef7958ae3510d92bebff215970c7f77435297ea345ba272aed?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="self-center max-w-full aspect-[3.33] w-[125px]"
          />
          <div className="mt-9 text-3xl font-semibold tracking-tighter leading-10 text-center text-slate-800 max-md:max-w-full">
            Login
          </div>
          <div className="flex flex-col justify-center px-16 mt-9 rounded-3xl max-md:px-5 max-md:max-w-full">
            <button className="flex justify-center items-center px-6 py-4 text-base font-medium tracking-normal leading-6 text-gray-600 border border-solid border-zinc-400 rounded-[96px] max-md:px-5 max-md:max-w-full" onClick={() => alert('Select a Google account to sign in')}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e631c97960720e289aac1c57d12f2e938b6c0db397dfd90432fccdcf2c20364?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="mr-2 shrink-0 w-6 aspect-[0.96]"
              />
              <div>Sign In with Google</div>
            </button>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" className="mt-6 p-2 border-2 rounded-md w-full" />
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" className="mt-4 p-2 border-2 rounded-md w-full" />
            <button className="justify-center flex items-center px-8 py-4 mt-6 text-base font-medium tracking-normal leading-6 text-white bg-teal-500 rounded-[96px] max-md:px-5 max-md:max-w-full" onClick={handleLogin}>
              Log In
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <div className="text-center">
              <a href="/forgot-password" className="mt-1 text-sm text-blue-600 hover:text-blue-800">Forgot password?</a>
            </div>
            <div className="flex gap-1 justify-center px-20 mt-6 text-base tracking-normal leading-6 text-center max-md:flex-wrap max-md:px-5">
              <div className="font-medium text-gray-600">
                Don’t have an account?
              </div>
              <a href="/signup" className="font-semibold text-blue-600">Sign Up</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="flex justify-center items-center px-16 py-10 text-teal-500 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1054px] max-md:max-w-full">
        <div className="flex z-10 gap-5 self-center w-full whitespace-nowrap max-w-[1032px] max-md:flex-wrap max-md:max-w-full">
          <div className="flex-auto self-start mt-4 text-4xl font-bold lowercase">
            scoutdd
          </div>
          <div className="flex gap-5 justify-between bg-white">
            <div className="flex flex-col text-lg tracking-tight leading-7">
              <div className="text-2xl font-medium tracking-tighter leading-8">
                Menu
              </div>
              <div className="mt-6">Home</div>
              <div className="mt-6">About</div>
            </div>
            <div className="flex flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-8">
                Contact
              </div>
              <div className="mt-6">London</div>
              <div className="mt-4">info@scoutdd.com</div>
            </div>
          </div>
        </div>
        <div className="items-center px-16 pt-8 text-base font-medium tracking-normal leading-6 max-md:px-5 max-md:max-w-full">
          © 2023 scoutdd. All rights reserved
        </div>
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function LogInParent() {
  return (
      <div>
          {/* <StickyHeader /> */}
          <main className="main-content">
            <RefactoredHeader />
            <LogIn />
            {/* <Footer /> */}
            <RefactoredFooter />
          </main>
      </div>
  );
}

export default LogInParent;