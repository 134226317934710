import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
    return (
      <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
        <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
          <img
            loading="lazy"
            src="https://cdn.prod.website-files.com/661e965fa0ea3712f7d8aea3/661e9fb996e7cae77a611ef8_Layer_1.svg"
            className="w-full h-full object-contain"
            alt="Avenir Logo"
          />
        </div>
        <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center w-[295px]">
            <div className="text-3xl text-white">
              Avenir
            </div>
            <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
              <div className="flex items-center whitespace-nowrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Team Size Icon"
                />
                <div>Small Team</div>
              </div>
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Industry Icon"
                />
                <div>Wealth Management</div>
              </div>
              <div className="flex items-center text-teal-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Website Icon"
                />
                <a href="https://www.joinavenir.com/" target="_blank" rel="noopener noreferrer" className="ml-2">
                  Visit website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$10M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$420k</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$500k</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}

function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about1F');
  };

  const navigateToScanPage = () => {
    navigate('/scan1F');
  };

  const navigateToContactPage = () => {
    navigate('/contact1F');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="founders" className="self-center tracking-normal">
          Founding Team
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
          <p>The founding team of Avenir, a UK-based wealth management solutions provider, comprises individuals with a strong background in finance, technology, and entrepreneurship. CEO and Co-founder Jeremy Bensoussan leads the team with a vision to reshape the wealth management landscape through innovation and digitalization. Bensoussan's leadership is highlighted by his ability to secure significant pre-seed funding within just three months, reflecting the market's demand for personalized digital solutions in wealth management.</p>
          <p>Supporting Bensoussan is a team of experts with diverse experience in leading financial institutions such as FNZ, JP Morgan, and Morgan Stanley. This expertise brings credibility and industry knowledge to Avenir, allowing the startup to leverage cutting-edge technology and artificial intelligence for personalized wealth management solutions at scale. The team's ability to integrate clients' values into investment strategies and create custom portfolios and ETFs tailored to individual goals showcases their commitment to personalization and efficiency.</p>
          <p>The backing of leading investors including SFC Capital and Techstars further underscores the credibility and potential of Avenir in the industry. The team's successful completion of the ABN AMRO + Techstars Future of Finance accelerator program demonstrates their drive and expertise to make a significant impact in the financial industry.</p>
          <p>Overall, the Avenir founding team's combination of industry experience, technological expertise, and entrepreneurial drive positions them well to disrupt the wealth management sector. With a clear mission, strong investor support, and a focus on innovation, Avenir is poised to revolutionize traditional wealth management practices and deliver exceptional value to financial professionals and their clients.</p>
        </div>
  
        <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
          Finances
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Avenir, a UK-based wealth management solutions provider, has demonstrated a strong fundraising history and investment support. Founded in 2023, Avenir quickly secured £487k in pre-seed funding within three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. The company's funding was backed by leading investors such as SFC Capital, Techstars, and prominent angel investors with experience in leading financial institutions like FNZ, JP Morgan, and Morgan Stanley. This diverse investor base underscores Avenir's credibility and potential in the industry.</p>
          <p>Avenir's suite of tools integrates cutting-edge technology and artificial intelligence (AI) to provide personalized wealth management solutions at scale. The company's offerings include values mapping, direct indexing, and generating compliance reports based on sustainability metrics. By leveraging AI and advanced Direct Indexing technology, Avenir offers personalized investment solutions that reduce administrative time for advisors and deliver tangible impact beyond financial returns to clients.</p>
          <p>The company's ability to secure $500,000 in pre-seed funding further highlights market demand for personalized digital solutions in wealth management. With support from prominent investors like SFC Capital and Techstars, Avenir aims to disrupt the wealth management sector in the UK, targeting the £1.2 trillion in assets under management. CEO Jeremy Bensoussan's confidence in Avenir's ability to innovate and deliver exceptional value to financial professionals and their clients is evident in the company's strategic direction.</p>
          <p>Overall, Avenir's strong financial backing, innovative approach, and dedication to personalization and digitalization position it well for future growth and success in the competitive wealth management industry.</p>
        </div>
        
        <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
          Market Size
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p><strong>Market Size Analysis:</strong></p>
          <p>Avenir, a UK-based wealth management solutions provider, has secured significant pre-seed funding totaling £487k within three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. The company offers a comprehensive suite of tools using cutting-edge technology and artificial intelligence (AI) for personalized wealth management solutions at scale. Avenir's innovative approach and dedication to personalization and digitalization have attracted leading investors, including SFC Capital, Techstars, and prominent angel investors with experience in leading financial institutions such as FNZ, JP Morgan, and Morgan Stanley.</p>
          <p>The market for personalized digital solutions in wealth management is witnessing strong demand, as evidenced by Avenir's successful funding round. Avenir's use of AI and advanced Direct Indexing technology allows for personalized investment solutions that reduce administrative time for advisors and provide tangible impact beyond financial returns to clients. The company's ability to integrate clients' values into investment strategies and create customized portfolios tailored to their goals and principles sets it apart in the industry.</p>
          <p><strong>Competitive Analysis:</strong></p>
          <p>Avenir faces competition from traditional wealth management firms and other fintech startups offering similar personalized wealth management solutions. However, Avenir's focus on advanced technology, AI-driven tools, and personalized services gives it a competitive edge in the market. The company's strong investor backing and industry partnerships further enhance its position in the competitive landscape.</p>
          <p><strong>Market Trends:</strong></p>
          <p>The market trend towards digitalization and personalization in wealth management is expected to continue growing. Clients are increasingly seeking tailored investment solutions that align with their values and goals, driving the demand for innovative fintech companies like Avenir. The use of AI and advanced technology to streamline processes and enhance client experiences is becoming a standard in the industry.</p>
          <p><strong>Overall Analysis:</strong></p>
          <p>The market size for personalized digital solutions in wealth management is expanding, driven by increasing demand for customized investment services. Avenir's successful pre-seed funding round and strong investor support position the company well to capitalize on this growing market trend. By leveraging advanced technology, AI, and personalized services, Avenir is poised to disrupt the wealth management landscape and capture a significant share of the market. The company's innovative approach and focus on personalization set it apart from competitors and position it for long-term success in the evolving wealth management industry.</p>
        </div>
      </div>
    );
  }
  
  function Scan2() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Innovation
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Avenir, a UK-based startup founded in 2023, has introduced an innovative approach to wealth management solutions by leveraging cutting-edge technology and artificial intelligence (AI). The company recently secured £487k in pre-seed funding within three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. This funding, backed by leading investors such as SFC Capital, Techstars, and experienced angel investors from financial institutions like FNZ, JP Morgan, and Morgan Stanley, highlights Avenir's credibility and potential in the industry.</p>
          <p>Avenir's suite of tools empowers wealth managers and financial advisors to provide personalized wealth management solutions at scale. By integrating advanced technology, including AI and Direct Indexing technology, Avenir enables advisors to create custom portfolios and ETFs tailored to their clients' goals and values. This personalized approach not only enhances efficiency but also showcases tangible impact beyond financial returns.</p>
          <p>The startup's focus on reshaping the wealth management landscape through personalization and digitalization has garnered praise from investors and industry experts. Ed Stevenson, Investment Executive at SFC Capital, commended Avenir's innovative approach, while Allard Luchsinger, Managing Director of Techstars, highlighted the team's expertise and drive to make a significant impact in the financial industry.</p>
          <p>Avenir's successful pre-seed funding round reflects the market's demand for personalized digital solutions in wealth management. CEO Jeremy Bensoussan expressed confidence in the company's ability to disrupt the sector and deliver exceptional value to financial professionals and their clients. With further development of its B2B wealth management software suite, Avenir aims to capitalize on the £1.2 trillion assets under management in the UK wealth management sector while continuing to innovate and meet market demands.</p>
        </div>
  
        <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Traction
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>User traction for Avenir, a UK-based wealth management solutions provider, has been impressive based on the information provided. Avenir secured £487k in pre-seed funding within three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. The funding came from leading investors such as SFC Capital, Techstars, and prominent angel investors with experience in leading financial institutions like FNZ, JP Morgan, and Morgan Stanley. This indicates strong investor confidence in Avenir's mission and potential within the industry.</p>
          <p>Avenir, founded in 2023, offers wealth managers and financial advisors a comprehensive suite of tools using cutting-edge technology and artificial intelligence for personalized wealth management solutions at scale. The company's innovative approach focuses on efficiency, personalization, and impact, allowing advisors to integrate clients' values into investment strategies and create custom portfolios tailored to their goals and principles.</p>
          <p>The market's response to Avenir has been positive, with CEO Jeremy Bensoussan highlighting the demand for personalized digital solutions in wealth management. The company's ability to secure funding quickly reflects the market's interest in such solutions. Avenir's success in securing significant pre-seed funding and attracting support from prominent investors positions it well to disrupt the wealth management landscape and innovate within the sector.</p>
          <p>Overall, Avenir's user traction is evident through its rapid funding acquisition, investor backing, and innovative approach to wealth management solutions. The company's focus on personalization, digitalization, and leveraging advanced technology showcases its potential for growth and impact within the financial industry.</p>
        </div>
  
        <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Scalability
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p><strong>Scalability Analysis:</strong></p>
          <p><strong>Technical Scalability:</strong></p>
          <p>The technical scalability of Avenir's wealth management software is evident through its use of cutting-edge technology and artificial intelligence (AI) to provide personalized wealth management solutions at scale. The integration of advanced Direct Indexing technology allows for the creation of custom portfolios and ETFs tailored to clients' goals and principles. This technical innovation not only enhances efficiency but also showcases tangible impact beyond financial returns. The ability to seamlessly integrate clients' values into investment strategies demonstrates the software's adaptability and scalability in meeting diverse client needs.</p>
          <p><strong>User Scalability:</strong></p>
          <p>From a user perspective, Avenir's software offers a user-friendly interface that empowers wealth managers and financial advisors to provide personalized solutions efficiently. The software's ability to reduce administrative time for advisors while delivering customized wealth management solutions at scale enhances user experience. The positive feedback from investors and industry experts further validates the software's user scalability, as it addresses the market's demand for personalized digital solutions in wealth management.</p>
          <p><strong>Operational Scalability:</strong></p>
          <p>Operational scalability is evident in Avenir's rapid growth and success in securing significant pre-seed funding within a short period. The company's ability to close funding rounds quickly reflects its operational efficiency and market responsiveness. The support from leading investors and prominent angel investors underscores Avenir's operational scalability in attracting financial backing and industry recognition. The company's mission to reshape the wealth management landscape and disrupt the sector highlights its operational scalability in driving innovation and delivering exceptional value to clients.</p>
          <p><strong>Data Scalability:</strong></p>
          <p>Data scalability is crucial in wealth management software, and Avenir's use of AI and advanced technology to offer personalized investment solutions demonstrates its data scalability. The software's ability to process and analyze large volumes of data to generate regulatory compliance reports and score businesses based on sustainability showcases its data handling capabilities. The software's capacity to provide custom solutions on a large scale indicates its ability to manage and leverage data effectively to meet the evolving needs of clients and the market.</p>
          <p>In conclusion, Avenir's wealth management software exhibits strong technical, user, operational, and data scalability, positioning the company for continued growth and success in the industry.</p>
        </div>
  
        <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Monetisation Strategy
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>The Monetisation Strategy of Avenir, a UK-based wealth management solutions provider, is centered around offering personalised wealth management solutions to wealth managers and financial advisors through a suite of tools that leverage cutting-edge technology and artificial intelligence (AI). Avenir aims to disrupt the wealth management industry by providing customised portfolios and exchange-traded funds tailored to clients' goals and principles, integrating their values into investment strategies.</p>
          <p>One key aspect of Avenir's monetisation strategy is its pricing model, which likely involves charging a fee for the use of its software suite and services. The company may adopt a subscription-based model where wealth managers and financial advisors pay a recurring fee to access Avenir's tools for personalised wealth management. Additionally, Avenir could generate revenue through value-added services such as compliance reporting, sustainability scoring for businesses, and other premium features that enhance the user experience.</p>
          <p>The pre-seed funding rounds of £487k and $500,000 secured by Avenir indicate investor confidence in the company's mission and potential for growth. The backing of leading investors including SFC Capital, Techstars, and prominent angel investors with experience in financial institutions like FNZ, JP Morgan, and Morgan Stanley further validates Avenir's credibility and market potential.</p>
          <p>Avenir's focus on personalisation, digitalisation, and innovation in wealth management aligns with the market demand for tailored digital solutions in the industry. The company's use of AI and advanced technology to streamline administrative processes for advisors and deliver tangible impact beyond financial returns to clients positions it as a disruptive player in the sector.</p>
          <p>Overall, Avenir's Monetisation Strategy appears to be robust, leveraging technology, personalisation, and industry expertise to drive revenue growth and establish a strong foothold in the competitive wealth management market. The company's ability to attract significant funding and support from key investors underscores its potential for success in reshaping the wealth management landscape.</p>
        </div>
      </div>
    );
  }
  



function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Avenir's Competitive Advantage:
        <br />
        Avenir, a UK-based wealth management solutions provider, has established a strong competitive advantage in the financial industry through its innovative approach to reshaping the wealth management landscape. The startup secured £487k in pre-seed funding within just three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. This funding was backed by leading investors such as SFC Capital, Techstars, and prominent angel investors with extensive experience in financial institutions like FNZ, JP Morgan, and Morgan Stanley.
        <br />
        Avenir differentiates itself by empowering wealth managers and financial advisors with a comprehensive suite of tools that leverage cutting-edge technology and artificial intelligence (AI) for personalized wealth management solutions at scale. By integrating advanced technology, Avenir revolutionizes traditional practices to enhance efficiency, personalization, and impact in wealth management. The startup enables advisors to seamlessly incorporate their clients' values into investment strategies, create custom portfolios, and offer personalized exchange-traded funds (ETFs) tailored to clients' goals and principles.
        <br />
        Utilizing AI and advanced Direct Indexing technology, Avenir offers personalized investment solutions that not only save significant administrative time for advisors but also demonstrate tangible impact beyond financial returns to clients. The startup's ability to provide customized wealth management solutions on a large scale positions it as a disruptive player in the industry.
        <br />
        With strong support from investors like SFC Capital and Techstars, Avenir is well-positioned to further develop its B2B wealth management software suite and capitalize on the market demand for personalized digital solutions in wealth management. CEO Jeremy Bensoussan's confidence in Avenir's ability to innovate and disrupt the sector, coupled with the startup's successful funding rounds and strategic partnerships, solidifies its competitive advantage in the financial industry.
      </div>

      <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Avenir's Barriers to Entry:
        <br />
        In the wealth management industry, Avenir faces several barriers to entry that can impact its ability to compete effectively in the market. One significant barrier is the high level of capital required to enter the industry. Avenir's successful pre-seed funding round of £487k is a crucial step in overcoming this barrier, as it provides the necessary financial resources to develop its wealth management solutions and compete with established players.
        <br />
        Another barrier is the regulatory environment within the financial industry. Wealth management is a highly regulated sector, with strict compliance requirements and licensing procedures. Avenir's focus on providing regulatory compliance reports and scoring businesses based on sustainability shows its commitment to addressing this barrier. By ensuring that its software meets regulatory standards and helps advisors navigate complex compliance issues, Avenir can differentiate itself and gain a competitive advantage.
        <br />
        Moreover, the industry's reputation and trust are essential barriers to entry. Established wealth management firms have built strong relationships with clients over time, based on trust and reliability. Avenir's ability to leverage advanced technology, such as AI and Direct Indexing, to offer personalized investment solutions that showcase tangible impact beyond financial returns can help build trust with clients and overcome this barrier.
        <br />
        Additionally, the expertise and experience required to succeed in the wealth management industry can be a barrier to entry for new startups. Avenir's team, which includes founders with backgrounds at companies like Revolut and Mastercard, as well as support from prominent investors like SFC Capital and Techstars, demonstrates the necessary expertise to navigate the complexities of the industry and compete effectively.
        <br />
        In conclusion, while Avenir has made significant progress in securing funding, addressing regulatory considerations, and building a strong team, it will need to continue to innovate and differentiate itself to overcome the barriers to entry in the competitive wealth management market. By focusing on personalized digital solutions, regulatory compliance, and building trust with clients, Avenir can position itself for long-term success in the industry.
      </div>

      <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        The analysis of Avenir's exit potential is promising based on the information provided. Avenir, a UK-based wealth management solutions provider, has successfully secured significant pre-seed funding of £487k within three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. The funding was backed by leading investors including SFC Capital, Techstars, and prominent angel investors with experience in leading financial institutions such as FNZ, JP Morgan, and Morgan Stanley. This funding underscores Avenir's credibility and potential in the industry.
        <br />
        Avenir's innovative approach to wealth management, utilizing cutting-edge technology and artificial intelligence (AI) for personalized solutions at scale, positions the company well for future growth and potential exits. The company's suite of tools, including values mapping, direct indexing, and generating compliance reports, caters to the evolving needs of wealth managers and financial advisors.
        <br />
        The market's demand for personalized digital solutions in wealth management is evident, as reflected in Avenir's successful fundraising efforts. With a strong team led by CEO and Co-founder Jeremy Bensoussan, Avenir is well-equipped to disrupt the wealth management landscape and deliver exceptional value to financial professionals and their clients.
        <br />
        Possible exit routes for Avenir could include acquisition by a larger financial institution looking to enhance its digital wealth management capabilities, a strategic partnership with a key player in the industry, or even an initial public offering (IPO) if the company continues to demonstrate strong growth and market demand for its services.
        <br />
        In conclusion, Avenir's rapid fundraising success, innovative approach to wealth management, and strong investor backing position the company favorably for potential exits in the future. With a focus on personalized digital solutions and a commitment to reshaping the wealth management landscape, Avenir has the potential to achieve significant growth and success in the industry.
      </div>

      <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Industry regulations play a crucial role in shaping the operations and growth of companies, especially in sectors like wealth management that deal with sensitive financial information and investments. Avenir, a UK-based wealth management solutions provider, operates in a highly regulated industry where compliance with financial regulations is paramount to ensure trust, transparency, and accountability.
        <br />
        One key regulatory consideration for Avenir is the need to adhere to financial regulations governing wealth management practices. This includes compliance with laws related to investment advisory services, client suitability assessments, anti-money laundering (AML) regulations, and data protection laws such as the General Data Protection Regulation (GDPR) in the EU. Avenir must ensure that its suite of tools, which includes values mapping, direct indexing, and compliance reporting, align with these regulations to protect both the company and its clients.
        <br />
        Moreover, as Avenir leverages advanced technology and artificial intelligence (AI) for personalized wealth management solutions, it must also navigate regulatory frameworks related to the use of AI in financial services. This includes considerations around data privacy, algorithm transparency, and ethical AI practices to prevent bias or discrimination in investment decision-making processes.
        <br />
        Additionally, with the increasing focus on sustainability and environmental, social, and governance (ESG) factors in investment decisions, Avenir must stay abreast of evolving regulations and standards in this area. The company's software that scores businesses based on their sustainability aligns with the growing emphasis on responsible investing, but it must ensure compliance with ESG disclosure requirements and reporting standards.
        <br />
        In conclusion, Avenir's success in disrupting the wealth management industry will depend not only on its innovative technology and personalized solutions but also on its ability to navigate and comply with industry regulations effectively. By staying proactive in monitoring regulatory developments, maintaining transparency in its operations, and upholding the highest standards of compliance, Avenir can build trust with clients, investors, and regulators while driving sustainable growth in a highly regulated industry.
      </div>
      
      <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Avenir, a UK-based wealth management solutions provider, appears to be a suitable candidate for Venture Capital (VC) investment based on several key factors. Firstly, the company has successfully secured £487k in pre-seed funding within just three months after completing the ABN AMRO + Techstars Future of Finance accelerator program. This rapid fundraising success indicates strong investor interest and confidence in Avenir's business model and potential for growth.
        <br />
        Moreover, Avenir has attracted backing from leading investors such as SFC Capital, Techstars, and prominent angel investors with extensive experience in financial institutions like FNZ, JP Morgan, and Morgan Stanley. This diverse and experienced investor base not only provides financial support but also brings valuable industry knowledge and networks to help Avenir scale and succeed in the competitive wealth management sector.
        <br />
        Avenir's innovative approach to wealth management, leveraging cutting-edge technology and artificial intelligence (AI) for personalized solutions at scale, positions the company as a disruptor in the industry. By offering tools that empower wealth managers and financial advisors to create custom portfolios tailored to clients' goals and values, Avenir addresses a growing market demand for digital solutions in wealth management.
        <br />
        Furthermore, the company's focus on efficiency, personalization, and impact through AI and advanced Direct Indexing technology demonstrates a commitment to delivering tangible value to both advisors and clients. With a clear mission to reshape the wealth management landscape and a strong team led by CEO Jeremy Bensoussan, Avenir shows promise as a future-focused investment opportunity for VC firms looking to support innovative fintech solutions.
        <br />
        In conclusion, Avenir's rapid fundraising success, strong investor backing, innovative technology-driven approach, and experienced leadership team make it a compelling candidate for Venture Capital investment. The company's potential to disrupt the wealth management sector and deliver value to stakeholders positions it well for future growth and success in the market.
      </div>

      <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Risk Assessment
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Key risks for Avenir include technological challenges, regulatory hurdles, and market acceptance.
        <br />
        Technological risk involves ensuring that their wealth management software is reliable, safe, and scalable.
        <br />
        Regulatory risk centers around gaining certification and complying with various financial regulations.
        <br />
        Market risk involves the acceptance of their technology by wealth managers and financial advisors, and potential competition from more established players.
      </div>

      <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Mitigation Strategies
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        To mitigate technological risks, Avenir could continue partnering with established financial institutions for technical expertise and validation.
        <br />
        For regulatory risks, proactive engagement with financial authorities and participation in regulatory development is crucial.
        <br />
        Market risks could be mitigated by diversifying applications and creating strategic partnerships for market access.
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;