import * as React from "react";
import { useNavigate } from "react-router-dom";

function StickyHeader() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between px-5 w-full text-lg bg-slate-100 max-w-full pt-4 fixed top-0 left-0 right-0 z-50">
      <button
        onClick={() => window.scrollTo(0, 0)}
        className="text-4xl font-bold text-teal-500 lowercase"
        style={{ background: 'none', border: 'none', cursor: 'pointer' }}
      >
        scoutdd
      </button>
      <div className="flex flex-row gap-5 justify-end text-center leading-[135%]">
        <button
          className="flex justify-center items-center font-semibold text-white bg-teal-500 px-8 py-2.5 rounded-md border border-teal-500 border-solid"
          onClick={() => navigate('/dashboard')}
        >
          The Platform
        </button>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="flex justify-center items-center w-full py-4 bg-white text-gray-700">
      © 2024 scoutdd. All rights reserved.
    </div>
  );
}

function LPMainBody() {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubscribe = () => {
    if (/^\S+@\S+\.\S+$/.test(email)) {
      setMessage('Thank you!');
    } else {
      setMessage('Please enter a valid email.');
    }
  };

  return (
    <div className="flex flex-col items-center pt-3.5 pb-20 shadow-sm bg-slate-100">
      <div className="px-52 mt-32 mr-auto text-6xl tracking-tighter leading-[57px] text-zinc-900 max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10">
        Supercharge your sourcing and due diligence process with our revolutionary platform
      </div>
      <div className="pl-48 mt-8 mr-auto text-xl tracking-tighter leading-8 text-neutral-500 w-[576px] max-md:max-w-full">
        Designed to save VCs and Angels 100s of hours in scouting, initial screening, and due diligence
      </div>
      <div className="w-full bg-teal-500 rounded-lg min-h-[3px] max-md:max-w-full mt-8" />
      <div className="self-center mt-20 text-6xl tracking-tight text-center text-black max-md:mt-10 max-md:text-4xl">
        The Angel’s Angel
      </div>
      <div className="mt-24 w-full max-w-[1057px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-center max-md:mt-10">
              <div className="text-2xl tracking-tighter leading-9 text-zinc-900">
                Largest Collection of Startups
              </div>
              <div className="mt-4 text-base tracking-tight leading-6 text-neutral-500">
                Access to high quality deal flow, trained to scout and present from the widest of sources.
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/226c3664c38d9a4f7905546ef2b608b65268704a891a719a4d310a1a7292c836?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="self-center mt-1.5 max-w-full aspect-[1.56] w-[151px]"
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col text-center max-md:mt-10">
              <div className="text-2xl tracking-tighter leading-9 text-zinc-900">
                Comprehensive Due Diligence
              </div>
              <div className="mt-4 text-base tracking-tight leading-6 text-neutral-500">
                Advanced NLP to present deep dives across 20 features on all startups. Features including: history of founders, market trends, competitive advantage, PMF, scalability, monetisation strategy and more.
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow items-center text-center max-md:mt-10">
              <div className="text-2xl tracking-tighter leading-9 text-zinc-900">
                Monitoring
              </div>
              <div className="self-stretch mt-2.5 text-base tracking-tight leading-6 text-neutral-500">
                Continuous monitoring and regular updates on performance of startups and relevant industry insights.
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/226c3664c38d9a4f7905546ef2b608b65268704a891a719a4d310a1a7292c836?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="mt-2 max-w-full aspect-[1.56] w-[151px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shrink-0 self-stretch mt-12 bg-teal-500 rounded-lg h-[3px] max-md:mt-10 max-md:max-w-full" />
      <div className="mt-24 text-5xl tracking-tighter text-center leading-[57px] text-zinc-900 max-md:mt-10 max-md:text-4xl max-md:leading-[51px]">
        Over 90% of startups fail
      </div>
      <div className="mt-10 text-xl tracking-tighter leading-8 text-neutral-500 max-md:max-w-full">
        Imagine a platform that can not only present you with all the startups looking for funding, but help you separate the winners from the losers.
      </div>
      <div className="flex flex-col self-stretch px-9 mt-16 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="shrink-0 bg-teal-500 rounded-lg h-[3px] max-md:max-w-full" />
        <div className="mt-20 ml-12 text-5xl tracking-tighter leading-[57px] text-zinc-900 max-md:mt-10 max-md:text-4xl max-md:leading-[51px]">
          Subscribe and become a design partner in our road to MVP and keep updated with news about our tech, processes, and launch.
        </div>
        <div className="flex gap-3 self-start mt-12 ml-24 text-sm tracking-tight max-md:mt-10 max-md:ml-2.5">
          <input
            type="text"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex flex-col justify-center ml-0 text-teal-500 py-2.5 pr-48 pl-3.5 -mr-px rounded-xl border border-solid shadow-sm border-slate-800"
          />
          <button
            className="justify-center px-6 py-4 font-medium text-violet-100 whitespace-nowrap bg-teal-500 rounded-xl max-md:px-5"
            onClick={handleSubscribe}
          >
            Subscribe
          </button>
        </div>
        {message && (
          <div className="text-sm mt-2 ml-24 text-teal-500">{message}</div>
        )}
        <div className="shrink-0 mt-32 bg-teal-500 rounded-lg h-[3px] max-md:mt-10 max-md:max-w-full" />
      </div>
    </div>
  );
}

function LandingParent() {
  return (
    <div>
      <StickyHeader />
      <LPMainBody />
      <Footer />
    </div>
  );
}

export default LandingParent;

