import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://media.licdn.com/dms/image/D4D0BAQGh9cGONqdnWA/company-logo_200_200/0/1684836542701/kaikaku_ai_logo?e=1726704000&v=beta&t=SGCXJZ4o8NZ4PsO5B1CsIq3VrL0OEagv_UTCDSXviKs"
          className="w-full h-full object-contain"
          alt="KaikakuAI Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            KaikakuAI
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Robotics</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://www.linkedin.com/company/kaikaku-ai/?originalSubdomain=uk" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$10M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$1.8M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$1.8M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
    const navigate = useNavigate();
  
    const navigateToAbout2O = () => {
      navigate('/about2O');
    };
  
    const navigateToScan2O = () => {
      navigate('/Scan2O');
    };
  
    const navigateToContact2O = () => {
      navigate('/contact2O');
    };
  
    return (
      <div className="flex justify-center">
        <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
          <button onClick={navigateToAbout2O} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
            About
          </button>
          <button onClick={navigateToScan2O} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
            Scan
          </button>
          <button onClick={navigateToContact2O} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
            Contact
          </button>
        </div>
      </div>
    );
  }



function Scroller() {
  return (
    <div className="flex gap-2.5 mx-5 text-xs tracking-tight leading-6 uppercase whitespace-nowrap text-zinc-500 mt-4 mb-10" style={{ alignSelf: 'center' }}>
      <a href="#overview" className="flex flex-col justify-end text-center"  style={{ color: 'inherit', textDecoration: 'none' }}>
        <div>Overview</div>
        <div />
      </a>
      <a href="#problem"  style={{ color: 'inherit', textDecoration: 'none' }}>
        <div>Problem</div>
      </a>
      <a href="#solution"  style={{ color: 'inherit', textDecoration: 'none' }}>
        <div>Solution</div>
      </a>
    </div>
  );
}
function About() {
  return (
    <div className="flex flex-col px-5 mb-10 text-xl font-bold leading-8 max-w-[841px] text-slate-800" style={{ alignSelf: 'center' }}>
      <div id="overview" className="self-center tracking-normal max-md:max-w-full">
        Overview
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light tracking-normal leading-6 text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        Kaikaku AI is an innovative company dedicated to revolutionizing the food services industry through advanced technology solutions. Founded by Josef Chen, Kaikaku AI combines deep expertise in both the food and beverage (F&B) sector and cutting-edge technology to develop solutions that enhance efficiency, reduce food waste, and improve overall restaurant operations. By automating repetitive tasks and providing personalized customer experiences, Kaikaku AI aims to transform the dining experience and set new standards for the industry.
      </div>
      <div id="problem" className="self-center mt-5 tracking-normal max-md:max-w-full">
        Problem
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        The food services and technology solutions market faces significant barriers to entry, primarily due to the high level of specialization required in both sectors. Businesses often struggle with chronic staff shortages, high operational costs, and inefficiencies in managing food resources, which lead to significant waste. Additionally, the traditional food services industry is challenged by the need for substantial capital investment and compliance with stringent food safety regulations, which can be prohibitive for new entrants looking to innovate in this space.
      </div>
      <div id="solution" className="self-center mt-8 tracking-normal max-md:max-w-full">
        Solution
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full">
        Kaikaku AI addresses these challenges by offering a comprehensive suite of technology solutions designed to optimize food service operations and enhance customer experiences. Their innovative systems automate kitchen tasks, reducing labor costs and food waste while improving efficiency. By leveraging AI-driven insights, Kaikaku AI provides restaurants with tools to offer personalized food suggestions and streamline order processing, thus creating a more enjoyable and efficient dining experience. With a commitment to sustainability and operational excellence, Kaikaku AI is poised to lead the way in transforming the food services industry.
      </div>
    </div>
  );
}

  
  

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}




function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Scroller/>
        <About/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;