import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://with.context.ai/assets/full_logo-d7c8d4f1d8637fe46c18bfe516ff24f0e7054bf429df4621ef78fa279e4f1a53.svg"
          className="w-full h-full object-contain"
          alt="Context.ai Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            Context.ai
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Data Analytics</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://with.context.ai/" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Valuation Icon"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$20M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Latest Raise Icon"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$3.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Raised To Date Icon"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$3.5M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Funding Stage Icon"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about1A');
  };

  const navigateToScanPage = () => {
    navigate('/scan1A');
  };

  const navigateToContactPage = () => {
    navigate('/contact1A');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="founders" className="self-center tracking-normal">
        Founding Team
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>The founding team of Context.ai, led by Co-Founders Henry Scott-Green and Alex Gamble, showcases a blend of technical expertise, industry experience, and entrepreneurial vision. Henry Scott-Green, as the Co-Founder and CEO, brings a strong background in product development and analytics, having previously worked at Google as an Associate Product Manager. His experience in building LLM (Large Language Model) products is evident from his posts discussing the challenges and opportunities in the AI chat interface space.</p>
        <p>Henry's focus on understanding user behavior in AI chat interfaces and optimizing user experiences aligns well with the market demand for enhanced analytics tools in the evolving GenAI ecosystem. His emphasis on the importance of tracking trends and leveraging data to build products that align with future advancements demonstrates strategic foresight and a commitment to staying ahead in a rapidly evolving industry.</p>
        <p>Alex Gamble, although not detailed in the provided information, is likely to bring complementary skills to the team, possibly in areas such as technology development, operations, or business strategy. The collaborative efforts of Henry and Alex in launching Context.ai indicate a shared vision for addressing the emerging needs of businesses seeking to leverage AI chat interfaces effectively.</p>
        <p>The positive feedback received from design partners and the successful fundraising efforts reflect the team's ability to execute and attract support from investors. Additionally, the team's intention to hire a team of Founding Software Engineers in London underscores their commitment to scaling the company and further developing their product offerings.</p>
        <p>Overall, the Founding Team of Context.ai appears well-equipped to navigate the complexities of the AI analytics market, with Henry Scott-Green's leadership and expertise driving the company's strategic direction and product innovation. Their collective experience and proactive approach position them favorably for success in the competitive landscape of AI-driven solutions.</p>
      </div>

      <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Context.ai, a San Francisco-based company, recently secured $3.5 million in seed funding from Google Ventures and Theory Ventures to enhance its product analytics platform for applications powered by large language models (LLMs). The funding will be utilized to scale engineering efforts and cater to enterprise customers seeking improved AI products. Co-founders Henry Scott-Green and Alex Gamble developed Context.ai to address the challenge of understanding user behavior and measuring product performance in the AI space.</p>
        <p>The platform offers businesses the ability to track conversation topics, assess product performance, debug conversations, monitor brand risks, analyze user retention, and evaluate the impact of new releases. Notable companies like Cognosys, Lenny’s Newsletter, and Juicebox rely on Context.ai for product analytics to enhance their LLM-powered applications. The company's fundraising involved participation from various prominent figures in the tech industry, indicating strong support and interest in Context.ai's offerings.</p>
        <p>Context.ai's commitment to user security and privacy is highlighted by its achievement of SOC2 certification, ensuring information security standards are met. However, concerns regarding user data usage and privacy in AI analytics remain pertinent, emphasizing the need for transparency and ethical practices in the industry. As AI integration becomes more prevalent, addressing these concerns will be crucial for sustained success in the evolving landscape.</p>
        <p>Overall, Context.ai's fundraising success, strategic partnerships, and focus on enhancing user understanding and product performance through analytics position it as a promising player in the AI space. By addressing industry challenges and emphasizing user privacy, the company is poised to capitalize on the growing demand for advanced AI solutions in enterprise settings.</p>
      </div>
      
      <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Market 
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Market Size Analysis:</strong></p>
        <p>The data analytics market is experiencing significant growth, with a projected market size of over USD 393.35 billion by 2032, growing at a CAGR of 29.4% from 2023 to 2032. The market is segmented by type (Big Data Analytics, Business Analytics, Customer Analytics, Risk Analytics, Statistical Analysis, Others), deployment (On-premise, Cloud), enterprise size (Large Enterprises, Small & Medium Enterprises), and end-use industries (BFSI, Government, Healthcare, IT & Telecom, Military & Defense, Others).</p>
        <p>Key takeaways from the market include the dominance of big data analytics with a market share of over 35% in 2022, on-premise deployment accounting for over 45% market share, and large enterprises capturing over 60% of the market in 2022. The Asia Pacific region is expected to exhibit a noteworthy CAGR of 23.5% from 2023 to 2032.</p>
        <p>Factors driving the market growth include the growing trend of edge computing, increasing IoT device connectivity, and the rise in data loss incidents and cyberattacks. The market is also benefiting from the need for improved insights, rising disposable incomes, and competitive advantages. The adoption of SaaS-based big data analytics, the growth of virtual offices generating large data volumes, and increasing cloud technology expenditures are further propelling market growth.</p>
        <p>Competitive analysis reveals key players in the data analytics market such as Alteryx, Inc, IBM Corporation, SiSense Inc, Microsoft, Zoho Corporation Pvt, Datameer, Inc., and SAS Institute Inc.</p>
        <p>Overall, the market trends, increasing demand for data analytics across various industries, and the emergence of new technologies like social media analytics are expected to drive the growth of the data analytics market. The startup in question, if operating in the data analytics space, stands to benefit from these market trends by offering innovative solutions that cater to the evolving needs of businesses across different sectors. By leveraging emerging technologies and addressing key market drivers, the startup can position itself for success and growth in the dynamic data analytics market.</p>
      </div>
    </div>
  );
}

function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Context.ai is an innovative platform that offers a range of high-impact features aimed at enhancing the understanding of user interactions with AI products. The product updates introduced in September and October 2023 showcase the commitment of Context.ai to providing valuable insights and analytics to its users.</p>
        <p>One key feature highlighted is the support for transcript backfilling, enabling users to analyze their entire corpus of conversations from the inception of using Context.ai. Additionally, the platform now automatically detects messages in over 100 foreign languages and translates them into English, enhancing accessibility and usability for a global user base.</p>
        <p>The introduction of PII filtering is a significant step towards data protection and privacy, as Context.ai now detects and redacts personally identifiable information during transcript ingestion. This feature aligns with industry best practices and regulatory requirements, ensuring the secure handling of sensitive data.</p>
        <p>Furthermore, the integration with Haystack 2.0 expands the capabilities of Context.ai by providing developers with enhanced product analytics. This integration streamlines the process of exporting transcripts for analysis, enabling developers to monitor the performance of their LLM-powered products effectively.</p>
        <p>The platform's emphasis on fine-grained controls, sharing, and reporting features empowers users to configure analytics according to their specific needs, facilitating data review and post-launch evaluation processes. The ability to repeat LLM generations and evaluations enhances result certainty, while support for Mistral models and large test sets underscores the platform's versatility and scalability.</p>
        <p>Context.ai's commitment to enterprise trust and security, as evidenced by its SOC 2 Type II compliance and self-hosted deployment option, further solidifies its position as a reliable and secure solution for businesses with stringent data residency requirements.</p>
        <p>Overall, Context.ai's innovative features, advanced analytics capabilities, and focus on data security make it a valuable tool for businesses seeking to gain deeper insights into user behavior and optimize their AI products effectively.</p>
      </div>

      <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Traction
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>User traction for Context.ai appears to be strong based on the information provided. The platform has successfully raised $3.5 million in funding from reputable investors such as GV (Google Ventures) and Theory Ventures, indicating investor confidence in the product. The platform's focus on providing product analytics for LLM-powered applications has resonated with businesses looking to build better AI products.</p>
        <p>User feedback from companies like Cognosys, Lenny’s Newsletter, Juicebox, and ChartGPT highlights the value that Context.ai brings in helping them understand user behavior, measure product performance, and derive meaningful insights from data generated by their platforms. This positive feedback indicates that users are finding the platform useful in improving their LLM-powered applications.</p>
        <p>The platform's commitment to security and privacy, as evidenced by achieving SOC2 certification, further enhances user trust and confidence in using Context.ai. The integration with Haystack 2.0 also demonstrates the platform's continuous efforts to provide valuable features and integrations for developers.</p>
        <p>The diverse set of investors and operators participating in Context.ai's fundraise, including prominent figures in the tech industry, further validates the platform's potential for growth and success. The platform's model-agnostic approach and support for various foundational models make it versatile and appealing to a wide range of users.</p>
        <p>Overall, the user traction for Context.ai seems to be on a positive trajectory, with strong investor backing, positive user feedback, commitment to security and privacy, and continuous efforts to enhance the platform's features and capabilities.</p>
      </div>

      <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Scalability
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Scalability Analysis:</strong></p>
        <p><strong>Technical Scalability:</strong></p>
        <p>The technical scalability of Context.ai appears to be robust, as evidenced by the integration with Haystack 2.0, allowing for product analytics and easy export of transcripts. The ability to repeat LLM generations and evaluations, version custom evaluators, support large test sets, and add search functionalities indicate a focus on enhancing technical capabilities. These improvements suggest that the platform can handle increased data processing demands efficiently, showcasing technical scalability.</p>
        <p><strong>User Scalability:</strong></p>
        <p>From a user perspective, the platform's ability to cater to developers using Haystack and provide analytics features demonstrates user scalability. The emphasis on helping AI builders make informed decisions and monitor performance aligns with user needs for data-driven insights. The platform's mission to empower AI builders and its successful fundraising efforts further indicate a growing user base, showcasing user scalability.</p>
        <p><strong>Operational Scalability:</strong></p>
        <p>Operational scalability is evident in the platform's SOC 2 Type II certification, highlighting a commitment to user privacy and security. The platform's ability to handle data securely and comply with industry standards showcases operational scalability. Additionally, the platform's continuous updates, improvements to evaluations, and support for Mistral models indicate operational readiness to adapt to evolving requirements, enhancing operational scalability.</p>
        <p><strong>Data Scalability:</strong></p>
        <p>Data scalability is supported by the platform's use of AI models trained on user interactions with text interfaces to improve services. The collection of personal information and usage data, coupled with the implementation of cookies for data collection, indicates a data-driven approach to enhance services. The platform's ability to securely process and transfer personal information, along with adherence to international data transfer regulations, underscores data scalability.</p>
        <p>In conclusion, Context.ai demonstrates strong scalability across technical, user, operational, and data dimensions, as evidenced by its technical enhancements, user-focused features, operational certifications, and data-driven approach. These factors collectively contribute to the platform's ability to accommodate growth, handle increased demands, and deliver value to users effectively.</p>
      </div>

      <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The Monetisation Strategy of Context.ai appears to be focused on offering a freemium model with additional paid features for enterprise customers. The pricing structure includes a free tier for users to try out the product, which provides basic functionalities such as understanding natural language in LLM-powered products and suggesting relevant clusters of conversations. This free tier serves as a way to attract users and showcase the value of the platform.</p>
        <p>For enterprise customers, Context.ai offers a paid plan that includes advanced features such as Trust and Security for Enterprise. The platform is SOC 2 Type II compliant, ensuring that customer data is handled with the highest levels of security. Additionally, there is a self-hosted deployment option for customers with strict data residency requirements, providing flexibility and customization options.</p>
        <p>The testimonials from customers, such as Rod Smyth, CEO & Co-Founder at Glyde Talent, highlight the value proposition of Context.ai in providing actionable insights for sales, engineering, and product development. This positive feedback serves as a strong selling point for potential enterprise customers considering the paid plan.</p>
        <p>Overall, the Monetisation Strategy of Context.ai seems well-structured, with a clear differentiation between the free and paid tiers. By offering valuable features for enterprise customers, such as security compliance and self-hosted deployment options, the startup can attract and retain high-value clients willing to pay for premium services. The testimonials further validate the effectiveness of the platform, enhancing its credibility and value proposition in the market.</p>
      </div>
    </div>
  );
}

  



function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Context.ai has established a strong competitive advantage in the rapidly developing AI stack by offering a highly differentiated product that focuses on natural language understanding in LLM-powered products. This differentiation is crucial in a market where businesses often struggle with developing AI products without a clear understanding of user needs. By providing solutions that help uncover hidden behavior patterns and suggest relevant clusters of conversations, Context.ai addresses a critical pain point in the industry.</p>
        <p>Moreover, the company's commitment to trust and security for enterprises is evident through its SOC 2 Type II compliance, ensuring that customer data is handled with the highest levels of security. This compliance gives customers confidence in the safety and integrity of their data when using Context.ai's services.</p>
        <p>Additionally, the self-hosted deployment option offered by Context.ai caters to customers with strict data residency requirements, further enhancing the company's competitive advantage by providing flexibility and customization options to meet diverse customer needs.</p>
        <p>Testimonials from industry experts and customers, such as Rod Smyth and Glyde Talent, highlight the tangible benefits and value that Context.ai brings to businesses. The ability to provide actionable data for sales teams, insights for engineering teams to enhance user experiences, and confidence in the performance of changes before production are key differentiators that set Context.ai apart in the market.</p>
        <p>In a rapidly growing analytics industry, where data insights are becoming increasingly valuable, Context.ai's focus on natural language understanding, security compliance, and customer-centric solutions positions the company as a leader in delivering superior and safer user experiences. By staying ahead of industry trends and addressing critical market needs, Context.ai has a strong competitive advantage that sets it apart from other players in the analytics startup landscape.</p>
      </div>

      <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Barriers to entry in the market for AI products and services, such as those offered by Context.ai, can be influenced by various factors. One significant barrier is the complexity and specialized nature of AI technology itself. Developing AI solutions requires a high level of technical expertise, access to large datasets, and advanced algorithms, which can be challenging for new entrants without the necessary resources or capabilities.</p>
        <p>Moreover, the market for AI products is characterized by high research and development costs, which can act as a barrier to entry for smaller companies or startups. Companies like Context.ai, with established products and a track record of success, may have a competitive advantage in terms of resources and expertise, making it difficult for new entrants to compete effectively.</p>
        <p>Regulatory barriers also play a crucial role in the adoption of AI technologies. Compliance with data protection regulations, such as SOC 2 Type II compliance, is essential for AI companies to gain the trust of customers and ensure the security of their data. Meeting these regulatory requirements can be costly and time-consuming, creating barriers for new entrants who may not have the resources to navigate complex regulatory environments.</p>
        <p>Additionally, the need for continuous innovation and staying ahead of technological advancements poses a challenge for new entrants in the AI market. Established companies like Context.ai, with a focus on developing cutting-edge AI solutions and improving user experiences, may have a competitive edge over new players trying to enter the market.</p>
        <p>Overall, the barriers to entry in the AI market are multifaceted and include technical complexity, high R&D costs, regulatory requirements, and the need for continuous innovation. Companies like Context.ai, with a strong market presence and a differentiated product offering, are well-positioned to navigate these barriers and maintain a competitive advantage in the rapidly evolving AI landscape.</p>
      </div>

      <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Context.ai, a San Francisco-based company, recently closed a $3.5 million seed funding round to enhance its analytics capabilities for applications powered by large language models (LLMs). The funding will be utilized to scale engineering efforts and cater to enterprise customers, showcasing a strong growth trajectory for the company.</p>
        <p>The company's focus on providing sophisticated product analytics for LLM-powered products positions it well in the rapidly evolving AI landscape. By enabling businesses to understand user behavior, measure product performance, and gain crucial insights into user engagement, Context.ai addresses a critical need in the market.</p>
        <p>Potential exit routes for Context.ai could include acquisition by a larger tech company looking to enhance its AI analytics capabilities or a strategic partnership with a major player in the AI industry. Given the increasing demand for AI-powered solutions and the company's unique value proposition, an acquisition by a tech giant seeking to bolster its AI offerings could be a viable exit strategy.</p>
        <p>However, concerns around data privacy and ethical usage of AI analytics tools could impact Context.ai's exit potential. As AI ethics and data privacy regulations become more stringent, the company will need to navigate these challenges effectively to ensure sustained success and attractiveness to potential acquirers.</p>
        <p>In conclusion, Context.ai's strong positioning in the AI analytics space, coupled with its innovative product offerings and growth potential, make it an attractive candidate for a successful exit in the future. By addressing ethical and privacy concerns proactively and continuing to enhance its analytics capabilities, the company can maximize its exit potential and create value for investors and stakeholders.</p>
      </div>

      <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Industry regulations, especially in the realm of data compliance, are crucial for startups like Context.ai. The startup, offering solutions in e-Billing, Matter management, Vendor management, Reporting & analytics, and Learning center, must adhere to various data privacy regulations to avoid penalties and reputational damage.</p>
        <p>One key regulation to consider is the General Data Protection Regulation (GDPR), which applies to companies serving EU citizens. Even if the startup's customers are based in the United States, familiarity with GDPR regulations is essential. The Federal Trade Commission (FTC) issues fines for non-compliance with regulations, emphasizing the importance of adhering to data privacy laws.</p>
        <p>Additionally, the California Consumer Privacy Act (CCPA) is significant, as it may serve as a template for other states looking to protect residents' privacy. Startups should consider following CCPA regulations, even if not based in California, to align with evolving data privacy standards.</p>
        <p>Looking ahead, the possibility of federal data compliance regulations is on the horizon, which could impact startups significantly. International markets are also prioritizing data privacy, necessitating compliance with relevant regulations to protect customer data.</p>
        <p>Furthermore, the European Parliament's Artificial Intelligence Act introduces harmonized rules on AI development, marketing, and use, emphasizing the need for responsible innovation and safeguards. This regulation underscores the importance of ethical and trustworthy AI practices, aligning with the startup's focus on technology solutions.</p>
        <p>In conclusion, startups in the legal tech industry must prioritize compliance with data privacy regulations like GDPR, CCPA, and potential future federal regulations. Understanding and adhering to these regulations are essential for maintaining trust with customers, avoiding penalties, and fostering responsible innovation in the rapidly evolving regulatory landscape.</p>
      </div>
      
      <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The recent $3.5 million seed investment secured by San Francisco-based Context.ai marks a significant milestone for the company in its mission to bring sophisticated product analytics to applications powered by large language models (LLMs). Led by co-founder and CEO Henry Scott-Green, Context.ai aims to address the critical need for understanding user behavior and measuring product performance in the development of LLM-powered products.</p>
        <p>The funding will primarily be allocated towards scaling engineering efforts and expanding the company's capacity to cater to enterprise customers. Context.ai's platform offers businesses the ability to analyze user engagement, measure product performance, and gain deep insights into user behavior when interacting with LLM-powered products. This includes features such as conversation analysis, sentiment analysis, user feedback ratings, risky and competitor topic detection, and user engagement metrics.</p>
        <p>While Context.ai has positioned itself as a valuable solution provider for businesses seeking to enhance user experiences through data analytics, concerns have been raised regarding the privacy implications of analyzing chat transcripts. The company has taken steps to address these concerns by obtaining SOC2 certification and ensuring the removal of personally identifiable information from the data collected.</p>
        <p>As AI continues to play an increasingly prominent role in daily life, the ethical and privacy considerations surrounding data usage become paramount. Context.ai's success moving forward will not only hinge on its ability to deliver effective data analysis for businesses but also on its capacity to navigate and address the ethical and privacy challenges inherent in the analytics space.</p>
        <p>In conclusion, Context.ai's recent funding round underscores the growing importance of data analytics in the development of AI-powered products. The company's focus on understanding user behavior and improving product performance positions it well in the competitive landscape, provided it continues to prioritize ethical data practices and privacy considerations.</p>
      </div>

      <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Risk Assessment
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Key risks for Context.ai include technological challenges, regulatory hurdles, and market acceptance.</p>
        <p>Technological risk involves ensuring that their wealth management software is reliable, safe, and scalable.</p>
        <p>Regulatory risk centers around gaining certification and complying with various financial regulations.</p>
        <p>Market risk involves the acceptance of their technology by wealth managers and financial advisors, and potential competition from more established players.</p>
      </div>

      <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Mitigation Strategies
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>To mitigate technological risks, Context.ai could continue partnering with established financial institutions for technical expertise and validation.</p>
        <p>For regulatory risks, proactive engagement with financial authorities and participation in regulatory development is crucial.</p>
        <p>Market risks could be mitigated by diversifying applications and creating strategic partnerships for market access.</p>
      </div>
    </div>
  );
}


function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;