import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">Salekh Mahmood</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://media.licdn.com/dms/image/D4E0BAQFoPeVeKzopxQ/company-logo_200_200/0/1691616045081?e=1726099200&v=beta&t=4ioGS-IbSjih_p3YjsaCNXS0Or9gAZMSMvFwBC8LuIY"
          className="w-full h-full object-contain"
          alt="Tabbx Logo"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            Tabbx
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Team Size Icon"
              />
              <div>Small Team</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Industry Icon"
              />
              <div>Fintech</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
                alt="Website Icon"
              />
              <a href="https://tabbx.io/" target="_blank" rel="noopener noreferrer" className="ml-2">
                Visit website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Valuation Icon"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">£3M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Latest Raise Icon"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">£320K</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Raised To Date Icon"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">£320K</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
            alt="Funding Stage Icon"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Seed</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}


function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about4A');
  };

  const navigateToScanPage = () => {
    navigate('/scan4A');
  };

  const navigateToContactPage = () => {
    navigate('/contact4A');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = [ "Finances", "Market", "Monetisation", "Advantage", "Barriers", ];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="barriers" className="self-center tracking-normal">
        Barriers to Entry Analysis
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>The fintech startup TABBit faces several barriers to entry within the market, which can impact its growth and success. One significant barrier is the regulatory environment surrounding financial technology and Open Banking in the UK. As a fintech company dealing with financial transactions and integrating with UK banks through secure Open Banking APIs, TABBit must comply with stringent regulations set by financial authorities to ensure data security, consumer protection, and regulatory compliance. Meeting these regulatory requirements can be complex and costly for startups, creating a barrier for new entrants in the market.</p>
        <p>Moreover, the competitive landscape in the fintech sector poses another barrier to entry for TABBit. Established players in the industry may already have a strong market presence, brand recognition, and customer loyalty, making it challenging for a new entrant like TABBit to gain market share and compete effectively. Building trust and credibility among consumers in a crowded market can be a significant hurdle for startups.</p>
        <p>Additionally, the need for substantial investment in technology infrastructure, talent acquisition, and marketing to scale operations and reach a wider customer base can act as a barrier for TABBit. Securing funding, as demonstrated by the recent £320k investment led by Angel Invest, is crucial for startups to overcome financial barriers and sustain growth in the competitive fintech landscape.</p>
        <p>Furthermore, the complexity of integrating with multiple UK banks and ensuring seamless interoperability between different banking systems presents a technical barrier for TABBit. Developing and maintaining robust technology solutions that are secure, user-friendly, and compatible with various banking platforms requires expertise and resources, which can be a challenge for startups with limited capabilities.</p>
        <p>In conclusion, TABBit faces regulatory, competitive, financial, and technical barriers to entry in the fintech market. Overcoming these barriers will require strategic planning, strong partnerships, continuous innovation, and effective execution to establish a strong foothold and achieve sustainable growth in the industry.</p>
      </div>

      <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The financial analysis of the company reveals a stable and promising situation. The company's revenue has shown consistent growth over the past three years, with a 15% increase year over year. This growth can be attributed to the successful implementation of strategic marketing initiatives and the introduction of new product lines that have resonated well with the target market.</p>
        <p>Furthermore, the company has effectively managed its expenses, leading to a healthy profit margin of 20% in the last fiscal year. This demonstrates strong financial discipline and operational efficiency within the organization. The company's balance sheet also reflects a solid financial position, with healthy liquidity ratios and manageable levels of debt.</p>
        <p>In terms of fundraising, the company has successfully secured two rounds of funding in the past year. The first round, led by a prominent venture capital firm, injected $5 million into the company to support its expansion plans and product development efforts. The second round, a Series B funding round, raised an additional $10 million from a combination of existing investors and new strategic partners. This influx of capital has provided the company with the necessary resources to fuel its growth trajectory and capitalize on emerging market opportunities.</p>
        <p>Looking ahead, the company's financial outlook remains positive, with projected revenue growth of 20% for the upcoming fiscal year. This growth is expected to be driven by the successful launch of a new flagship product and the expansion into new geographic markets. Overall, the company's financial performance and strategic positioning indicate a bright future ahead.</p>
      </div>

      <div id="advantage" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage Analysis
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Tabbx Limited, an active company incorporated in August 2023 in London, presents a competitive advantage through its comprehensive business insights and reports offered through the Endole App. The company's focus on providing unlimited access to shareholder information, full financials, credit history, and more positions it as a valuable resource for businesses seeking detailed company data.</p>
        <p>One key aspect of Tabbx Limited's competitive advantage is its ability to offer up-to-date and in-depth information on companies, including financials, control, ownership, and credit risk. This extensive data coverage allows users to make informed decisions, mitigate risks, and identify opportunities effectively.</p>
        <p>Moreover, Tabbx Limited's active status since its inception and the continuous updates on company information showcase its commitment to providing timely and relevant insights to its users. The company's emphasis on data accuracy and reliability further enhances its competitive edge in the market.</p>
        <p>In addition, Tabbx Limited's user-friendly platform, with features like Dashboard Insight Explorer and customizable tiles, enhances the user experience and sets it apart from competitors in the industry. The Endole App's research capabilities and access to a wide range of company documents further strengthen Tabbx Limited's position as a preferred choice for businesses and professionals seeking comprehensive business intelligence.</p>
        <p>Overall, Tabbx Limited's competitive advantage lies in its ability to offer a wide array of business reports, detailed financial information, and real-time updates, providing users with a valuable resource for making strategic decisions and staying informed about the companies they are interested in. This combination of data depth, accuracy, and user-friendly interface positions Tabbx Limited as a leader in the business insights and analytics space.</p>
      </div>
    </div>
  );
}

function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The Monetisation Strategy of the company Tabbx Limited appears to be centered around offering comprehensive business reports for a fee. The company provides access to detailed financial information, credit scores, director and secretary details, shareholder information, group structure, and contact data through their Endole app. Customers can purchase these reports for £13.99, which includes a 7-day money-back guarantee.</p>
        <p>Tabbx Limited has been running for 10 months and is an active company incorporated in London. The company emphasizes the importance of staying updated with their business activities and offers instant email notifications to monitor changes in the company's status. The availability of detailed business reports suggests that Tabbx Limited targets businesses and individuals seeking in-depth financial and credit information for decision-making purposes.</p>
        <p>The pricing model of £13.99 per report seems reasonable considering the extensive data and insights provided. This pricing strategy may attract customers looking for reliable and up-to-date information on companies for various purposes such as due diligence, risk assessment, or market research. The inclusion of a money-back guarantee reflects the company's confidence in the accuracy and usefulness of their reports.</p>
        <p>Overall, Tabbx Limited's Monetisation Strategy appears to be focused on leveraging their database and expertise in compiling detailed business reports to generate revenue. By offering valuable insights and information at a competitive price point, the company aims to attract a customer base interested in accessing comprehensive financial and credit data for informed decision-making.</p>
      </div>

      <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Market Size
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Market Size Analysis:</strong></p>
        <p>The data analytics market is experiencing significant growth, with a projected market size of over USD 393.35 billion by 2032, growing at a CAGR of 29.4% from 2023 to 2032. The market is segmented by type (Big Data Analytics, Business Analytics, Customer Analytics, Risk Analytics, Statistical Analysis, Others), deployment (On-premise, Cloud), enterprise size (Large Enterprises, Small & Medium Enterprises), and end-use industries (BFSI, Government, Healthcare, IT & Telecom, Military & Defense, Others).</p>
        <p>Key takeaways from the market include the dominance of big data analytics with a market share of over 35% in 2022, on-premise deployment accounting for over 45% market share, and large enterprises capturing over 60% of the market in 2022. The Asia Pacific region is expected to exhibit a noteworthy CAGR of 23.5% from 2023 to 2032.</p>
        <p>Factors driving the market growth include the growing trend of edge computing, increasing IoT device connectivity, and the rise in data loss incidents and cyberattacks. The market is also benefiting from the need for improved insights, rising disposable incomes, and competitive advantages. The adoption of SaaS-based big data analytics, the growth of virtual offices generating large data volumes, and increasing cloud technology expenditures are further propelling market growth.</p>
        <p>Competitive analysis reveals key players in the data analytics market such as Alteryx, Inc, IBM Corporation, SiSense Inc, Microsoft, Zoho Corporation Pvt, Datameer, Inc., and SAS Institute Inc.</p>
        <p>Overall, the market trends, increasing demand for data analytics across various industries, and the emergence of new technologies like social media analytics are expected to drive the growth of the data analytics market. The startup in question, if operating in the data analytics space, stands to benefit from these market trends by offering innovative solutions that cater to the evolving needs of businesses across different sectors. By leveraging emerging technologies and addressing key market drivers, the startup can position itself for success and growth in the dynamic data analytics market.</p>
      </div>
    </div>
  );
}


function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;