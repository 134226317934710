import React, { useState } from 'react';


function StickyHeader() {
  return (
      <div className="flex gap-5 justify-around px-5 w-full text-lg bg-slate-100 max-w-full pt-4 fixed top-0 left-0 right-0 z-50">
          <button onClick={() => window.scrollTo(0, 0)} className="flex-auto text-4xl font-bold text-teal-500 lowercase" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              scoutdd
          </button>
          <div className="flex gap-12 justify-around self-start mt-2.5 text-black">
              <button onClick={() => console.log('Navigate to Products')}>Products</button>
              <button onClick={() => console.log('Navigate to About Us')}>About Us</button>
              <button onClick={() => console.log('Navigate to Tour')}>Tour</button>
              <button onClick={() => console.log('Navigate to Get in Touch')}>Get in Touch</button>
          </div>
          <div className="flex flex-row gap-5 justify-end pl-36 text-center leading-[135%]">
              <button className="flex justify-center items-center font-semibold text-white bg-teal-500 px-8 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/signup'}>
                  Sign Up
              </button>
              <button className="flex justify-center items-center text-teal-500 overflow-auto px-10 py-2.5 rounded-md border border-teal-500 border-solid" onClick={() => window.location.href = '/login'}>
                  Log In
              </button>
          </div>
      </div>
  );
}



function SignU() {
  return (
    <div className="flex flex-col items-center pt-9 mb-0">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5d279e2bbed0c8e1ca0582a94b442670e64c31c249baac1cdd285ae37cec7ad?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="mr-7 ml-8 w-32 max-w-full aspect-[3.23]"
      />
      <div className="self-center mt-3.5 text-3xl font-semibold tracking-tighter leading-10 text-center text-slate-800 max-md:max-w-full">
        Sign Up
      </div>
      <div className="flex flex-col justify-center px-16 mt-8 max-w-full rounded-3xl w-[552px] max-md:px-5">
        <div className="flex justify-center items-center px-6 py-4 text-base font-medium tracking-normal leading-6 text-right text-gray-600 border border-solid border-zinc-400 rounded-[96px] max-md:px-5 max-md:max-w-full">
          <div className="flex gap-3">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca5e3a654c4c8fbc00e8dcbb40e6954ad21bf2412652506051723d6507bba365?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 w-6 aspect-[0.96]"
            />
            <div>Sign Up with Google</div>
          </div>
        </div>
        <div className="flex gap-4 justify-center items-center mt-6 text-sm font-medium tracking-normal leading-5 text-center text-gray-600 max-md:flex-wrap">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e017f99194110c1ddfcd67215cc6e2711697e6d608c7deab6d12524f9f6c6783?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 self-stretch my-auto max-w-full border border-solid aspect-[100] border-zinc-400 stroke-[1px] stroke-zinc-400 w-[153px]"
          />
          <div className="self-stretch">Or with email</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e017f99194110c1ddfcd67215cc6e2711697e6d608c7deab6d12524f9f6c6783?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 self-stretch my-auto max-w-full border border-solid aspect-[100] border-zinc-400 fill-zinc-400 stroke-[1px] stroke-zinc-400 w-[152px]"
          />
        </div>
        <div className="mt-6 text-base font-semibold tracking-normal leading-6 text-gray-600 max-md:max-w-full">
          Full Name
        </div>
        <input
          type="text"
          placeholder="Enter your full name"
          className="shrink-0 mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-gray-600 max-md:max-w-full">
          Email
        </div>
        <input
          type="email"
          placeholder="Enter your email"
          className="shrink-0 mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        <div className="mt-4 text-base font-semibold tracking-normal leading-6 text-gray-600 max-md:max-w-full">
          Password
        </div>
        <input
          type="password"
          placeholder="Enter your password"
          className="shrink-0 mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        <button className="flex justify-center items-center px-6 py-4 mt-6 text-base font-medium tracking-normal leading-6 text-right text-white bg-teal-500 rounded-[96px] max-md:px-5 max-md:max-w-full">
          Sign Up
        </button>
        <div className="mt-3 text-base font-medium tracking-normal leading-6 text-center text-gray-600 max-md:max-w-full">
          By signing up, you agree to our Terms and Conditions.
        </div>
        <div className="flex gap-1 justify-center px-20 mt-6 text-base tracking-normal leading-6 text-center max-md:flex-wrap max-md:px-5">
          <div className="font-medium text-gray-600">
            Already have an account?
          </div>
          <div className="font-semibold text-slate-800">Sign In</div>
        </div>
      </div>
    </div>
  );
}


function BackButton() {
  return (
    <div className="flex justify-center items-center p-4 border border-solid border-zinc-400 rounded-[96px]">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4afb053b4333b3be818e1923ac22cd9feb8514cbda74dd389b12782117fad86?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="w-full aspect-square"
      />
    </div>
  );
}



function Footer() {
  return (
    <div className="flex justify-center items-center px-16 py-10 text-teal-500 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1054px] max-md:max-w-full">
        <div className="flex z-10 gap-5 self-center w-full whitespace-nowrap max-w-[1032px] max-md:flex-wrap max-md:max-w-full">
          <div className="flex-auto self-start mt-4 text-4xl font-bold lowercase">
            scoutdd
          </div>
          <div className="flex-auto justify-center items-center px-16 pt-8 text-base font-medium tracking-normal leading-6 max-md:px-5 max-md:max-w-full">
          © 2023 scoutdd. All rights reserved
          </div>
          <div className="flex gap-5 justify-between bg-white">
            <div className="flex-auto flex-col text-lg tracking-tight leading-7">
              <div className="text-2xl font-medium tracking-tighter leading-8">
                Menu
              </div>
              <div className="mt-6">Home</div>
              <div className="mt-6">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-8">
                Contact
              </div>
              <div className="mt-6">London</div>
              <div className="mt-4">info@scoutdd.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}


function SignUp() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [welcome, setWelcome] = useState(false);

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSignUp = () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email');
      return;
    }
    setError('');
    setWelcome(true);
  };

  const handleGoogleSignUp = () => {
    // Simulate Google account selection popup
    alert("Pretend this is a Google account selection popup.");
  };

  return (
    <div className="flex justify-center flex-col items-center pt-9 mb-0">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5d279e2bbed0c8e1ca0582a94b442670e64c31c249baac1cdd285ae37cec7ad?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="mr-7 ml-8 w-32 max-w-full aspect-[3.23]"
      />
      <div className="self-center mt-3.5 text-3xl font-semibold tracking-tighter leading-10 text-center text-slate-800 max-md:max-w-full">
        Sign Up
      </div>
      <div className="flex flex-col justify-center px-16 mt-8 max-w-full rounded-3xl w-[552px] max-md:px-5">
        <button className="flex justify-center items-center px-6 py-4 text-base font-medium tracking-normal leading-6 text-right text-gray-600 border border-solid border-zinc-400 rounded-[96px] max-md:px-5 max-md:max-w-full" onClick={handleGoogleSignUp}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca5e3a654c4c8fbc00e8dcbb40e6954ad21bf2412652506051723d6507bba365?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="mr-2 shrink-0 w-6 aspect-[0.96]"
          />
          Sign Up with Google
        </button>
        <div className="flex gap-4 justify-center items-center mt-6 text-sm font-medium tracking-normal leading-5 text-center text-gray-600 max-md:flex-wrap">
          <div>Or with email</div>
        </div>
        <input
          type="text"
          placeholder=" Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-6 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        <input
          type="email"
          placeholder=" Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        {error && <div className="text-red-500">{error}</div>}
        <input
          type="password"
          placeholder=" Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-2 h-14 rounded-xl border-2 border-gray-200 border-solid max-md:max-w-full"
        />
        <button className="flex justify-center items-center px-6 py-4 mt-6 text-base font-medium tracking-normal leading-6 text-right text-white bg-teal-500 rounded-[96px] max-md:px-5 max-md:max-w-full" onClick={handleSignUp}>
          Sign Up
        </button>
        {welcome && (
          <div className="flex flex-col items-center justify-center text-lg font-bold text-green-500">
              <span>Welcome!</span>
            <button className="text-blue-700 underline" onClick={() => alert("Continue to our platform")}>
              Continue to our platform
            </button>
          </div>
        )}
        <div className="mt-3 text-base font-medium tracking-normal leading-6 text-center text-gray-600 max-md:max-w-full">
          By signing up, you agree to our Terms and Conditions.
        </div>
        <div className="flex gap-1 justify-center px-20 mt-6 text-base tracking-normal leading-6 text-center max-md:flex-wrap max-md:px-5 mb-6">
          <div className="font-medium text-gray-600">
            Already have an account?
          </div>
          <button className="font-semibold text-slate-800" onClick={() => window.location.href = '/login'}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
}



function SignUpPage() {
  return (
    <div className="flex flex-col min-h-screen bg-slate-100">
      <StickyHeader />
      <main className="flex-grow">
        <SignUp />
      </main>
      <RefactoredFooter />
    </div>
  );
}

export default SignUpPage;