import React from "react";
import { useNavigate } from "react-router-dom";
import {useState}  from 'react';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/search')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'grayscale(100%) brightness(2)' }} // Grey icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}



function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}





function QuickSearch() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full mt-4">
      <div className="flex items-center w-full max-w-[992px] gap-4">
        <input
          type="text"
          placeholder="Quick Search..."
          className="flex-1 px-6 py-3 border-2 border-gray-200 rounded-[1000px] text-gray-600"
          style={{ minWidth: '300px' }}  // Ensures input doesn't become too small
        />
        <div
          className="cursor-pointer px-16 py-3 text-center text-white bg-teal-500 rounded-[96px]"
          onClick={() => navigate('/results')}
        >
          Search
        </div>
      </div>
      <div className="flex justify-between w-full px-5 mt-4">
        <div className="flex gap-3">
          {["All", "FinTech", "AI", "Finance", "Consumer", "Engineering", "Climate", "Manufacturing"].map(category => (
            <div key={category} className={`cursor-pointer justify-center px-6 py-3 rounded-[96px] ${category === "All" ? "text-white bg-teal-500" : "border border-solid border-zinc-400"}`} onClick={() => navigate('/results')}>
              {category}
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center self-start p-3 w-11 h-11 cursor-pointer bg-teal-500 border border-solid border-zinc-400 rounded-[96px]" onClick={() => navigate('/results')}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c78197758b927850d525a94c6b07656ad37870709543a3c997985efe27743d9f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="w-5 aspect-square"
          />
        </div>
      </div>
    </div>
  );
}





function Filters() {
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [selectedOption, setSelectedOption] = useState(Array(12).fill(null));

  const toggleDropdown = (index) => {
    setSelectedDropdown(selectedDropdown === index ? null : index);
  };

  const handleOptionClick = (dropdownIndex, optionIndex, option) => {
    const newSelectedOptions = [...selectedOption];
    newSelectedOptions[dropdownIndex] = option;
    setSelectedOption(newSelectedOptions);
    setSelectedDropdown(null); // Optionally close dropdown on selection
  };

  const dropdowns = [
    { label: "Industry", options: ["Finance", "Analytics", "Health Care","AI", "Energy"] },
    { label: "Stage", options: ["Growth", "Expansion"] },
    { label: "Location", options: ["UK", "UAE", "Riyadh", "USA", "France", "Germany", "Netherlands"] },
    { label: "Founded Date", options: ["2024", "2023", "2022", "2021"] },
    { label: "Valuation", options: ["Under $1M", "$1M to $10M", "Over $10M"] },
    { label: "Total Raised", options: ["Under $500K", "$500K to $1M", "Over $1M"] },
    { label: "Revenue", options: ["No Revenue", "Under $100K", "Over $100K"] },
    { label: "Investors", options: ["Angel", "VC", "None"] },
    { label: "Verticals", options: ["Health", "Education", "Environment", "AI", "Blockchain"], upwards: true },
    { label: "Business Model", options: ["SAAS", "Subsciption", "PAAS", "Freemium", ], upwards: true },
    { label: "Founder History", options: ["First-time", "Serial Entrepreneur"], upwards: true },
    { label: "Patents", options: ["None", "1-5", "5+"], upwards: true }
  ];

  return (
    <div className="flex flex-col px-5 py-6 text-base text-gray-950 space-y-8" style={{ maxWidth: '100%', margin: '0 5%' }}>
      {["General", "Financial", "Product & Team"].map((section, sectionIndex) => (
        <div key={section}>
          <div className="text-lg font-semibold text-center mb-2">{section}</div> {/* Added margin-bottom */}
          <div className="flex gap-5 items-start px-0.5 text-base tracking-normal text-neutral-500">
            {dropdowns.slice(sectionIndex * 4, (sectionIndex + 1) * 4).map((dropdown, index) => (
              <div key={dropdown.label} className="relative flex flex-1 gap-2 py-8 pr-2 pl-4 whitespace-nowrap bg-white rounded-2xl border border-teal-500 border-solid shadow-sm">
                <button className="flex-1 my-auto" onClick={() => toggleDropdown(sectionIndex * 4 + index)}>
                  {dropdown.label}
                </button>
                <button className="shrink-0" onClick={() => toggleDropdown(sectionIndex * 4 + index)}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/28fe3586c6c0c010ea1ae42b5c6568f75fcef909124dd8772c264d9c67ddfb17?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                    className={`w-6 aspect-square transform ${selectedDropdown === sectionIndex * 4 + index ? 'rotate-180' : 'rotate-0'}`}
                    style={{ transition: 'transform 0.3s' }}
                    alt="Toggle Dropdown"
                  />
                </button>
                {selectedDropdown === sectionIndex * 4 + index && (
                  <ul className={`absolute ${dropdown.upwards ? 'bottom-full mb-2' : 'top-full mt-2'} right-0 z-10 bg-white shadow-lg rounded-lg w-48`}>
                    {dropdown.options.map((option, optionIndex) => (
                      <li key={option} className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${selectedOption[sectionIndex * 4 + index] === option ? 'bg-emerald-50 border-l-4 border-teal-500' : ''}`} onClick={() => handleOptionClick(sectionIndex * 4 + index, optionIndex, option)}>
                        {option}
                        {selectedOption[sectionIndex * 4 + index] === option && (
                          <img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/83233cf5dbef3b8e779bbfc9bdad964303bb9656e96b604a03ad2b10f96cda8c?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                            className="shrink-0 ml-2 my-auto w-3.5 aspect-[1.41] fill-violet-950"
                            alt="Selected"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="flex justify-end mt-6">
        <button className="justify-center items-center px-16 py-4 text-base font-medium tracking-normal leading-6 text-right text-white whitespace-nowrap bg-teal-500 rounded-[96px]" onClick={() => window.location.href = '/companysearchother'}>
          Search
        </button>
      </div>
    </div>
  );
}




function SearchPage() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
        <Sidebar />
        <div className="flex flex-col grow pl-[220px] pb-5">  {/* Added padding-bottom */}
            <div className="flex justify-between">
                <SearchBar />
                <Name />
            </div>
            <QuickSearch />
            <Filters />
        </div>
    </div>
  );
}


export default SearchPage;

