import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
    const [showDropdown, setShowDropdown] = React.useState(false);
    
    const toggleDropdown = () => setShowDropdown(!showDropdown);
  
    return (
      <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
        <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
          <div className="flex gap-1">
            <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
            <div className="my-auto">User Name</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 my-auto w-5 aspect-square"
          />
        </div>
        {showDropdown && (
          <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
            <ul>
              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    );
  }
  
function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}


function CompanyLogo() {
    return (
      <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
        <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
          <img
            loading="lazy"
            src="https://media.licdn.com/dms/image/D4E0BAQHGNPmC8xj6pA/company-logo_200_200/0/1705231283890/altelium_logo?e=1726704000&v=beta&t=9Ciriucf4liiBtHaPeb12-RasHm8Q0sBg6vw6v2Srvs"
            className="w-full h-full object-contain"
            alt="Altelium Logo"
          />
        </div>
        <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center w-[295px]">
            <div className="text-3xl text-white">
              Altelium
            </div>
            <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
              <div className="flex items-center whitespace-nowrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Team Size Icon"
                />
                <div>Small Team</div>
              </div>
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Industry Icon"
                />
                <div>Batteries</div>
              </div>
              <div className="flex items-center text-teal-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Website Icon"
                />
                <a href="https://www.linkedin.com/company/altelium/?originalSubdomain=uk" target="_blank" rel="noopener noreferrer" className="ml-2">
                  Visit website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  
  
  function Stats() {
    return (
      <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Valuation</div>
          </div>
          <div className="text-lg text-stone-500">$4.75M</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Latest Raise</div>
          </div>
          <div className="text-lg text-stone-500">$1.93M</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Raised To Date</div>
          </div>
          <div className="text-lg text-stone-500">$1.55M</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Funding Stage</div>
          </div>
          <div className="text-lg text-stone-500">Series A</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
      </div>
    );
  }
  

function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about3O');
  };

  const navigateToScanPage = () => {
    navigate('/Scan3O');
  };

  const navigateToContactPage = () => {
    navigate('/contact3O');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Scalability", "Monetisation", "Barriers", "Potential", "Regulation"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}



function Scan1() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="barriers" className="self-center tracking-normal">
          Barriers to Entry Analysis
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
          <p>The market for battery insurance and warranties, particularly in the context of emerging technologies like Battery Energy Storage Systems (BESS) and Electric Vehicles (EVs), presents several barriers to entry that new startups must navigate. One significant barrier is the high level of technical expertise required to understand the complexities of battery technology, risk assessment, and data analytics. Altelium, as a London-based insurtech company, has established itself as a pioneer in this field by leveraging AI-powered data analytics to provide real-time insights into battery performance and health.</p>
          <p>For startups entering the insurtech market, regulatory compliance is a critical consideration. In the UK, the Financial Conduct Authority (FCA) regulates insurance providers to ensure consumer protection, market integrity, and financial stability. Startups like Altelium must adhere to FCA guidelines on insurance distribution, pricing transparency, data protection, and customer communication. Compliance with these regulations not only builds trust with customers but also demonstrates credibility and professionalism in the market.</p>
          <p>Moreover, data privacy regulations, such as the General Data Protection Regulation (GDPR), play a crucial role in shaping how startups handle and protect sensitive customer data. Altelium's use of AI-powered data analytics necessitates a robust data protection framework to safeguard customer information and ensure compliance with GDPR requirements.</p>
          <p>In addition to regulatory considerations, startups in the insurtech sector must also navigate competitive pressures, technological advancements, and customer trust issues. Altelium's innovative approach to providing affordable insurance solutions backed by cutting-edge data analytics gives them a competitive edge in addressing these challenges.</p>
          <p>Overall, the combination of technical expertise, regulatory compliance, and innovative solutions positions Altelium as a key player in the insurtech market, overcoming barriers to entry and driving the transition towards sustainable energy solutions.</p>
        </div>
  
        <div id="advantange" className="self-center tracking-normal">
          Competitive Advantage Analysis
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Altelium Industries, a London-based insurtech startup, holds a significant competitive advantage in the market due to its innovative approach to providing insurance, warranties, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle (EV) OEMs, integrators, operators, and fleet owners. The company's use of AI-powered data analytics sets it apart from traditional insurance providers and positions it as a pioneer in the industry.</p>
          <p><strong>1. AI-Powered Data Analytics:</strong> Altelium's platform leverages AI technology to collect and analyze data from battery management systems, providing real-time insights into the performance and health of batteries. This advanced analytics capability gives Altelium a competitive edge by enabling proactive risk management and cost reduction for its clients.</p>
          <p><strong>2. Specialized Focus:</strong> By specializing in insurance and warranties for batteries, Altelium caters to a niche market segment with high growth potential. This focused approach allows the company to develop tailored solutions that meet the specific needs of battery technology stakeholders, setting it apart from more generalized insurance providers. </p>
          <p><strong>3. Green Energy Transition:</strong> Altelium's services play a crucial role in facilitating the transition from fossil fuels to green energy by ensuring the reliability and longevity of battery systems. This sustainability-focused positioning aligns with the growing demand for environmentally friendly solutions and enhances the company's appeal to eco-conscious customers.</p>
          <p><strong>4. Affordable Insurance Solutions:</strong> Altelium emphasizes the affordability of its insurance solutions without compromising on coverage. This value proposition resonates with clients seeking cost-effective ways to protect their investments in battery technologies, giving Altelium a competitive advantage in a price-sensitive market. </p>
          <p>In the insurtech sector, Altelium faces competition from traditional insurance providers as well as emerging startups offering similar services. However, the company's AI-driven approach, industry specialization, and commitment to sustainability set it apart from competitors and position it as a leader in the battery insurance market.
            Altelium Industries' competitive advantage lies in its innovative use of AI technology, specialized focus on battery insurance, contribution to the green energy transition, and commitment to affordability. These factors collectively differentiate the company in a competitive market landscape and position it for continued growth and success in the insurtech industry.</p>
        </div>
  
        <div id="potential" className="self-center tracking-normal">
          Exit Potential Analysis
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p> Altelium Industries, a London-based insurtech company specializing in providing warranties, insurance, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle Original Equipment Manufacturers (OEMs), presents a promising exit potential based on its innovative offerings and recent investment activities.</p>

<p>The recent �92 million investment in Altelium signifies strong investor confidence in the company's business model and growth prospects. This substantial funding injection not only provides the necessary capital for expansion but also enhances the company's valuation, making it an attractive target for potential acquirers or IPO opportunities in the future.</p>

<p>Altelium's unique value proposition lies in its AI-powered data analytics platform that offers real-time insights into the performance and condition of batteries, thereby reducing risks and costs for clients. This technology-driven approach positions Altelium as a key player in the transition from fossil fuels to green energy, aligning with the global shift towards sustainability and renewable energy sources.</p>

<p>Potential exit routes for Altelium could include acquisition by a larger insurtech or technology company looking to enhance its offerings in the renewable energy sector. Strategic partnerships with major players in the energy industry could also pave the way for a successful exit through acquisition or collaboration agreements.</p>

<p>Furthermore, Altelium's status as the world's first insurtech to offer insurance for batteries driven by real-time data, AI, and data analytics sets it apart in a rapidly evolving market, attracting interest from potential buyers seeking to capitalize on the growing demand for innovative solutions in the energy storage sector.</p>

<p>In conclusion, Altelium Industries' strong market positioning, technological innovation, recent investment influx, and alignment with sustainability trends indicate a promising exit potential through strategic acquisitions, partnerships, or potential IPO opportunities in the future. Investors and stakeholders should closely monitor Altelium's growth trajectory and market developments to capitalize on potential exit opportunities.</p>

        </div>
      </div>
    );
  }
  

  
  function Scan2() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="finances" className="self-center tracking-normal">
          Finances
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Altelium Ltd., a London-based insurtech company, specializes in providing warranties, insurance, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle (EV) Original Equipment Manufacturers (OEMs), integrators, operators, and fleet owners. The company's AI-powered data analytics platform offers real-time insights into battery performance, reducing risks and costs for clients.</p>

<p>Since its launch in early 2019, Altelium has raised funds in the "seven-digit" range through private placements. Notably, in March 2022, the company secured �1.5 million in seed funding from investors like Greenlight Reinsurance and Transverse Insurance Group. This investment indicates external confidence in Altelium's business model and growth potential.</p>

<p>Financial data for Altelium shows a significant decline in revenues and profitability margins from 2020 to 2021. Revenues decreased by 75%, while the EBITDA margin dropped from -90% to -2408%, reflecting challenges in financial performance. Despite these figures, the company's focus on R&D is evident, with allocated budgets for innovation.</p>

<p>Altelium's strategic partnerships with academic institutions in the UK and Germany demonstrate a commitment to leveraging expertise in battery technology. The company's data-driven approach to battery insurance and its global presence in the UK, Europe, the US, and Canada position it well in the evolving energy landscape.</p>

<p>Looking ahead, Altelium's ability to address the gradual degradation of industrial batteries and its alignment with sustainable development goals indicate a promising future. The recent funding round and emphasis on technological innovation underscore the company's potential for growth and impact in the insurtech sector, particularly in the transition to green energy solutions.</p>
  
        </div>
  
        <div id="founders" className="self-center tracking-normal">
          Founding Team
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The founding team of Altelium Industries comprises individuals with a diverse set of skills and expertise crucial for the success of the startup. Charley Grimston, the executive chairman and co-founder, brings a wealth of knowledge and experience to the table. With a clear vision for the company's direction, Grimston has played a pivotal role in shaping Altelium's strategic approach towards providing specialty insurance coverage for batteries.</p>

<p>Grimston's leadership has been instrumental in securing funding for the startup, with Altelium having raised a significant amount through a private placement. This financial backing underscores the confidence investors have in the team's capabilities and the market potential for their innovative insurance solutions.</p>

<p>In addition to Grimston, the founding team includes individuals with technical expertise in battery technology and data analytics. Collaborating with academic partners from reputable institutions such as Lancaster University and Newcastle University in the UK, Altelium has access to cutting-edge research and specialized knowledge in the field.</p>

<p>The team's focus on leveraging technology, particularly data-driven solutions, sets them apart in the insurtech industry. By harnessing AI-powered data analytics, Altelium is able to provide real-time insights into the performance and condition of batteries, offering clients greater confidence in their investments and reduced ownership costs.</p>

<p>Furthermore, Altelium's global reach, conducting business in the UK, Europe, the US, and Canada, highlights the team's ambition to address the evolving needs of the battery supply chain on a worldwide scale. With a customer base that spans various industries, the team's expertise and commitment to innovation position Altelium as a key player in the transition towards sustainable energy solutions.</p>

<p>Overall, the founding team of Altelium Industries demonstrates a strong blend of leadership, technical proficiency, and strategic vision, making them well-equipped to drive the company's growth and success in the insurtech market.</p>
  
        </div>
  
        <div id="regulation" className="self-center tracking-normal">
          Innovation
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Altelium Industries is an innovative startup based in London, England, that is revolutionizing the insurance and data analytics sector for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle (EV) OEMs, integrators, operators, and fleet owners. The company offers a Software as a Service (SaaS) platform that leverages real-time, AI-powered data analytics to provide warranties, insurance, and valuable insights into the performance and condition of batteries.</p>

<p>One of the key innovations of Altelium is its AI-powered data analytics platform, which collects data from battery management systems to offer real-time insights into the health and performance of batteries. This technology enables clients to make informed decisions, reduce risks, and lower the cost of ownership associated with battery systems.</p>

<p>Altelium's unique approach to battery insurance and data analytics has garnered significant attention in the industry. The company has partnered with global battery specialists to develop industry-first solutions, such as the GardX EVerity test, which assesses the current and future health of batteries using data modeling from multiple sources, including real-world battery performance.</p>

<p>Moreover, Altelium has established strategic partnerships with leading companies like Assurant, Inc., to advance battery electric vehicle protection and products through accelerated EV battery analytics and consumer insights. This collaboration enables Assurant to leverage Altelium's AI-driven battery data to create innovative electric vehicle solutions, including vehicle service contracts that protect EV owners from costly battery repair and replacement.</p>

<p>Overall, Altelium's innovative technology, strategic partnerships, and commitment to sustainability make it a key player in facilitating the transition from fossil fuels to green energy by providing essential insurance, warranties, and data analytics for batteries in the evolving energy landscape.</p>

        </div>
      </div>
    );
  }
  
  function Scan3() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="innovation" className="self-center tracking-normal">
          Monetisation Strategy
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The Monetisation Strategy of the startup Altelium Industries revolves around offering insurance, warranties, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle (EV) Original Equipment Manufacturers (OEMs), integrators, operators, and fleet owners. Altelium leverages its AI-powered data analytics platform to provide real-time insights into the performance and condition of batteries, thereby reducing risks and costs for its clients.</p>

<p>One of the key components of Altelium's monetisation strategy is its unique pricing model, which involves offering insurance-backed extended warranties for battery technology, covering both first and second life applications. This model allows Altelium to cater to a wide range of customers within the green energy and automotive industries, including car manufacturers looking to transition their batteries from EV to BESS applications.</p>

<p>Furthermore, Altelium's platform collects data from battery management systems and utilizes its AI engine to provide valuable insights, enabling clients to have greater confidence in their investments and reduced cost of ownership. By offering affordable insurance solutions that do not compromise on coverage, Altelium positions itself as a crucial player in facilitating the transition from fossil fuels to green energy.</p>

<p>The partnership with Tokio Marine Kiln (TMK) to deliver the world's first data-driven BESS warranty program highlights Altelium's commitment to innovation and collaboration in the green energy sector. This strategic partnership, supported by the government innovation agency Innovate UK, underscores Altelium's ability to leverage industry expertise and capital to drive growth and accelerate the adoption of renewable energy solutions.</p>

<p>Overall, Altelium's Monetisation Strategy is built on a foundation of cutting-edge technology, strategic partnerships, and a customer-centric approach, positioning the startup as a significant player in the insurtech space within the green energy revolution.</p>

        </div>
  
        <div id="market" className="self-center tracking-normal">
          Scalability
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Scalability is a crucial aspect of any technology platform, especially in the context of a rapidly evolving industry like insurtech. In the case of Altelium, scalability can be analyzed across technical, user, operational, and data dimensions.</p>

<p>From a technical scalability perspective, Altelium's platform is designed to collect and analyze real-time data from battery management systems using AI algorithms. The platform's ability to handle a large volume of data streams and provide valuable insights into battery conditions showcases its technical scalability. The platform's integration with automated pricing, issuance, and underwriting processes further demonstrates its ability to scale efficiently to meet increasing demands.</p>

<p>In terms of user scalability, Altelium's client base includes a wide range of stakeholders such as BESS operators, EV OEMs, integrators, and fleet owners. The platform's ability to cater to diverse user needs and provide tailored insurance and data analytics solutions indicates its user scalability. The partnership with Tokio Marine Kiln to deliver the world's first data-driven BESS warranty program highlights Altelium's ability to scale its offerings to meet the needs of large corporate clients.</p>

<p>Operationally, Altelium's growth from a research project at Lancaster University to a significant player in the green energy revolution reflects its operational scalability. The company's expansion to employ over 20 people and its co-location of data scientists in Lancaster's battery laboratory demonstrate its operational scalability in terms of team size and infrastructure.</p>

<p>From a data scalability perspective, Altelium's use of real-time battery data to update risk calculations and improve insurance products showcases its data scalability. The platform's ability to offer warranties for both first and second life markets, as well as service contracts and operational data information to various industry stakeholders, highlights its data scalability in terms of versatility and adaptability.</p>

<p>Overall, Altelium's scalability across technical, user, operational, and data dimensions positions it as a robust and adaptable insurtech platform capable of meeting the evolving needs of the green energy and automotive industries.</p>

        </div>
  
        <div id="monetisation" className="self-center tracking-normal">
          Traction
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">

        <p>User traction for Altelium, a London-based insurtech company specializing in warranties, insurance, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and EV OEMs, has been impressive. The company's innovative approach of using AI-powered data analytics to provide real-time insights into battery performance and condition has garnered significant attention and adoption within the industry.</p>

<p> Altelium's platform, which collects data from battery management systems and utilizes AI engines to offer valuable insights, has resonated well with clients seeking to enhance their confidence in investments and reduce ownership costs. The company's ability to bridge the gap between battery degradation processes and insurance risk calculations has been a key selling point, enabling them to offer extended warranties for battery technology in both first and second life applications.</p>

<p>The success of Altelium is further evidenced by its achievements, including raising investments worth �390,000 from founding shareholders and securing research grants totaling �806,560. The company's partnerships with industry players like Lloyd's of London and Connected Energy, as well as its involvement in projects such as the Faraday Institution's Multi-Scale Modelling project, highlight its growing influence and impact within the green energy and automotive sectors.</p>

<p>With a team of over 20 employees and a focus on maximizing knowledge exchange through co-location with Lancaster University's battery laboratory, Altelium is well-positioned to continue driving innovation in the insurtech space. The positive feedback from industry partners and customers, as well as the company's commitment to providing valuable services ranging from insured warranties to data analytics, underscore its strong user traction and potential for further growth in the future.</p>


        </div>
  
        <div id="scalability" className="self-center tracking-normal">
          VC Fit 
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Altelium Industries, an insurtech company based in London, England, specializing in providing warranties, insurance, and data analytics for Battery Energy Storage Systems (BESS), Battery Cells, and Electric Vehicle (EV) Original Equipment Manufacturers (OEMs), integrators, operators, and fleet owners, presents a compelling case for Venture Capital investment.</p>

<p>Altelium's innovative SaaS platform, powered by AI-driven data analytics, addresses a critical need in the transition from fossil fuels to green energy by offering insurance solutions for batteries. The company's ability to collect real-time data from battery management systems and provide valuable insights into battery performance and health sets it apart in the market. This technology not only reduces risk and costs for clients but also enables them to have greater confidence in their investments.</p>

<p>The recent �92 million investment in Altelium further underscores its potential for growth and scalability. This significant funding injection, combined with the company's success in renewable energy and battery data analytics, demonstrates a track record of developing practical solutions with the right support.</p>

<p>Venture Capital firms looking to invest in innovative technologies that drive sustainability and address the growing demand for green energy solutions would find Altelium Industries an attractive prospect. The company's focus on facilitating insurance for batteries through AI-powered data analytics aligns well with the current trends in the renewable energy sector and positions it as a key player in the transition to a sustainable future. With a strong value proposition, proven technology, and a clear market need, Altelium Industries presents a promising opportunity for Venture Capital investment.</p>
        </div>
      </div>
    );
  }
  

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
          <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;