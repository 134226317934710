import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}

function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearchfinance')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}


function CompanyLogo() {
    return (
      <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
        <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
          <img
            loading="lazy"
            src="https://static.wixstatic.com/media/fcd170_2fee051485764e8996766772a704a7e6~mv2.png/v1/crop/x_65,y_0,w_930,h_1060/fill/w_85,h_85,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PayNetX%20Logo.png"
            className="w-full h-full object-contain"
            alt="PayNetX Logo"
          />
        </div>
        <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center w-[295px]">
            <div className="text-3xl text-white">
              PayNetX
            </div>
            <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
              <div className="flex items-center whitespace-nowrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Team Size Icon"
                />
                <div>Small Team</div>
              </div>
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Industry Icon"
                />
                <div>FinTech</div>
              </div>
              <div className="flex items-center text-teal-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="my-auto w-3 aspect-square"
                  alt="Website Icon"
                />
                <a href="https://www.paynetx.com/" target="_blank" rel="noopener noreferrer" className="ml-2">
                  Visit website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

  function Stats() {
    return (
      <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Valuation</div>
          </div>
          <div className="text-lg text-stone-500">Unknown</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Latest Raise</div>
          </div>
          <div className="text-lg text-stone-500">Unknown</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Raised To Date</div>
          </div>
          <div className="text-lg text-stone-500">Unknown</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
        <div className="flex flex-col items-center gap-3 max-w-[150px]">
          <div className="flex items-center text-sm text-gray-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
              className="shrink-0 aspect-square w-[25px] mr-2"
            />
            <div>Funding Stage</div>
          </div>
          <div className="text-lg text-stone-500">Pre-seed</div>
          <div className="h-[3px] bg-teal-500 w-full" />
        </div>
      </div>
    );
  }
  

function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about2F');
  };

  const navigateToScanPage = () => {
    navigate('/Scan2F');
  };

  const navigateToContactPage = () => {
    navigate('/contact2F');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="foundingteam" className="self-center tracking-normal">
          Founding Team
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
          <p>The founding team of PayNetX comprises individuals with diverse and extensive backgrounds, bringing a wealth of experience and expertise to the startup. Manos Batsis, the Co-Founder and Chief Technology Officer (CTO), is a Computer Scientist with over 20 years of experience in building payment infrastructure. His prior contributions to R3 Corda Venture Developments, a prominent blockchain platform for financial services, showcase his deep understanding of cutting-edge technologies and their applications in the financial sector. At PayNetX, Manos plays a pivotal role in driving the development of innovative payment and technology solutions.</p>
          <p>As the Chief Product Owner, Manos is responsible for shaping the product vision, driving innovation, overseeing design processes, managing risks, analyzing metrics, and ensuring the successful development, launch, and continuous enhancement of projects at PayNetX. His leadership in these areas underscores his strategic thinking, technical acumen, and ability to navigate complex challenges in the fintech landscape.</p>
          <p>The team's collective expertise positions PayNetX as a frontrunner in the global fintech industry, with a focus on developing operational solutions tailored to address longstanding issues within the financial ecosystem. By leveraging Manos Batsis' deep industry knowledge and experience, PayNetX is well-equipped to drive forward its mission of accelerating the world's transition to seamless, cost-efficient, and borderless value transfer.</p>
          <p>Overall, the founding team's blend of skills, industry recognition, and commitment to innovation bodes well for the future success and impact of PayNetX in revolutionizing the global financial landscape.</p>
        </div>
  
        <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
          Finances
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Finance- Based on the information provided, PayNetX appears to be a FinTech company focused on developing innovative payment and technology solutions, particularly in the area of global financial transactions. The company's core product, the Private-Permission based Decentralised Trigger Chain (P2DTC), is a custom-built Distributed Ledger Technology that aims to facilitate atomic settlement of traditional and emerging forms of value on a single, interoperable infrastructure. This technology is designed to improve performance, automate compliance reporting, reduce operating costs, and enhance liquidity management in real-time.</p>
          <p>PayNetX's mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer aligns with industry trends towards digital innovation in payments, clearing, and settlements. The company's participation in initiatives such as the Bank for International Settlements (BIS) and the Institute of International Finance's Project Agor� demonstrates its commitment to testing interoperability and advancing financial market infrastructures.</p>
          <p>While specific financial data such as revenue streams, cost structures, and profitability margins are not explicitly provided, the company's focus on enhancing cross-border payments, instant settlements, and financial market infrastructure suggests a strong potential for revenue generation. PayNetX's ability to surpass G20 targets in cross-border payments without reliance on cryptocurrencies or stablecoins positions it as a key player in the evolving global financial ecosystem.</p>
          <p>Overall, PayNetX's strategic positioning, technological capabilities, and industry recognition indicate a promising outlook for the company's financial performance. Continued participation in collaborative initiatives and successful implementation of its innovative solutions are likely to drive growth and establish PayNetX as a leading player in the FinTech sector.</p>
        </div>
  
        <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
          Market Size
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p><strong>Market Trends Analysis:</strong></p>
          <p>The global Blockchain Market is experiencing significant growth, with the market size estimated to be around $7.4 billion in 2022 and projected to reach over $94.0 billion by 2027, indicating a CAGR of approximately 66.2% during the forecast period. This growth is primarily driven by increasing venture capital funding and investments in blockchain technology. The adoption of blockchain solutions for various business applications such as payments, smart contracts, documentation, and digital identity has gained immense popularity in recent years.</p>
          <p>North America is expected to hold the highest market share in the blockchain market, with a large number of blockchain suppliers in the region contributing to its dominance. Key players in the global blockchain industry include IBM, AWS, Oracle, Huawei, Accenture, Wipro, Infosys, TCS, SAP, Intel, and others.</p>
          <p>Technological advancements and the rising demand for real-time data analysis, enhanced visibility, and proactive maintenance are key trends influencing the blockchain market. Additionally, increasing government initiatives to boost demand for blockchain platforms and services, along with the high adoption of blockchain solutions for various applications, are driving market growth.</p>
          <p><strong>Overall Analysis:</strong></p>
          <p>The startup showcased, PayNetX, is well-positioned to benefit from the growing trends in the blockchain market. PayNetX's innovative approach to revolutionizing global payments aligns with the increasing demand for efficient and secure payment systems driven by blockchain technology. The startup's unique features, such as supporting over 50,000 transactions per second with instant settlement times and low transaction costs, cater to the market's need for fast and cost-effective payment solutions.</p>
          <p>Furthermore, PayNetX's focus on bridging traditional financing with decentralized financing through its hybrid platform resonates with the market shift towards blockchain adoption in financial services. The startup's mission to set new standards in global payments aligns with the industry's trend towards simplifying processes, enhancing transparency, security, and immutability through blockchain technology.</p>
          <p>In conclusion, PayNetX stands to benefit from the favorable market trends in the blockchain industry, positioning itself as a leader in revolutionizing global payments with its innovative solutions and capabilities. By leveraging the growing demand for blockchain-based payment systems and addressing the challenges in the global financial ecosystem, PayNetX has the potential to establish itself as a prominent player in the evolving landscape of global payments.</p>
        </div>
      </div>
    );
  }
  
  function Scan2() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Innovation
        </div>
        <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Innovation- PayNetX, a UK-based startup, has developed a groundbreaking global hybrid platform that aims to revolutionize traditional and decentralized financing by addressing the integration challenges between the two. The company's innovation lies in its proprietary technology called P2DTC (Private-Permission based Decentralised Trigger Chain), a custom-built Distributed Ledger Technology (DLT) that serves as the Global Underlying Interledger Communication Layer. This technology facilitates the atomic settlement of both traditional and emerging forms of value on a single, interoperable infrastructure.</p>
          <p>One of the key advantages of PayNetX's platform is its ability to improve performance, automate KYC/AML/CFT reporting, unlock previously blocked capital, reduce operating costs, eliminate counterparty risks and settlement delays. Furthermore, the platform provides instant settlement results and real-time intraday liquidity management data, offering a level of efficiency and transparency previously unseen in the industry.</p>
          <p>The startup's technology has garnered recognition and acknowledgment within the industry, with over 10,000 senior executives from central and commercial banks worldwide receiving updates and insights on PayNetX's mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer. Additionally, PayNetX has been selected to participate in prestigious programs such as the Visa Innovation Program Europe, further validating the innovative nature of its solutions.</p>
          <p>By participating in initiatives like Project Agor� and aligning with the goals of organizations like the Bank for International Settlements (BIS) and the Committee on Payments and Market Infrastructures (CPMI), PayNetX is positioned as a key player in advancing the global financial ecosystem towards frictionless cross-border payments and digital innovation. The company's commitment to technological advancement and its focus on enhancing global financial infrastructure underscore its potential to drive significant positive impact in the industry.</p>
        </div>
  
        <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Traction
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>User traction for PayNetX appears to be strong based on the provided information. The company's Chief Product Owner, Manos Batsis, brings over 20 years of experience in building payment infrastructure, including significant contributions to R3 Corda Venture Developments. This expertise is crucial in driving the development of innovative payment and technology solutions at PayNetX.</p>
          <p>PayNetX's focus on becoming a Global FinTech leader is evident in its mission to address challenges in the global financial ecosystem. The company's proprietary technology, the P2DTC (Private-Permission based Decentralised Trigger Chain), is highlighted as a key component that enables atomic settlement, performance improvement, KYC/AML/CFT automation, and real-time intraday liquidity management. These features are designed to reduce operating costs, eliminate counterparty risks, and provide instant settlement results.</p>
          <p>Furthermore, PayNetX's active participation in industry initiatives such as the Bank for International Settlements (BIS) and the Institute of International Finance demonstrates its commitment to driving innovation in cross-border payments and digital settlements. The company's involvement in Project Agor� showcases its capabilities in interoperability and instant cross-border settlement, positioning it as a key player in the evolving financial landscape.</p>
          <p>The company's engagement with central and commercial banks for Proof of Concept (PoC) partnerships further indicates a strong interest from financial institutions in leveraging PayNetX's solutions for cost-efficient and transparent cross-border transactions.</p>
          <p>Overall, PayNetX's user traction is supported by its technological advancements, industry recognition, and strategic partnerships, indicating a promising trajectory towards achieving its mission of accelerating the world's transition to seamless, cost-efficient, and borderless value transfer.</p>
        </div>
  
        <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Scalability
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p><strong>Scalability Analysis:</strong></p>
          <p><strong>Technical Scalability:</strong></p>
          <p>PayNetX's P2DTC (Private-Permission based Decentralised Trigger Chain) is a custom-built Distributed Ledger Technology that facilitates atomic settlement of traditional and emerging forms of value. This technology showcases high technical scalability by improving performance, automating reporting, reducing costs, and providing instant settlement results. The ability to handle a large volume of transactions efficiently demonstrates strong technical scalability.</p>
          <p><strong>User Scalability:</strong></p>
          <p>The company's focus on enhancing cross-border payments and digital innovation aligns with global financial inclusion goals. By surpassing G20 targets and offering instant cross-border settlement solutions, PayNetX shows user scalability by catering to the needs of various central and commercial banks globally. The open call for expression of interest for the Instant Cross-Border Settlements Proof of Concept further indicates a user-centric approach to scalability.</p>
          <p><strong>Operational Scalability:</strong></p>
          <p>PayNetX's participation in initiatives like Project Agor� demonstrates operational scalability by showcasing the ability to bridge existing and upcoming currencies, systems, and forms of value. The company's commitment to accelerating the world's transition to seamless value transfer highlights operational efficiency and adaptability to changing market demands, indicating strong operational scalability.</p>
          <p><strong>Data Scalability:</strong></p>
          <p>The use of innovative DLT-powered financial market infrastructures and real-time intraday liquidity management data signifies robust data scalability. PayNetX's capability to deliver instant cross-border settlement results in any currency pair worldwide showcases the handling of vast amounts of data efficiently. The company's focus on interoperability and data management further supports its data scalability.</p>
          <p>Overall, PayNetX exhibits strong scalability across technical, user, operational, and data aspects, positioning itself as a leading Global FinTech player with the potential to drive significant advancements in the global financial ecosystem.</p>
        </div>
  
        <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Monetisation Strategy
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>PayNetX, a cutting-edge financial organization, aims to transform the way value moves globally by significantly reducing transaction costs and facilitating access to global liquidity. The startup's monetization strategy is centered around its innovative payment and technology solutions, particularly its P2DTC (Private-Permission based Decentralised Trigger Chain), a custom-built Distributed Ledger Technology that enables atomic settlement of traditional and emerging forms of value on a single, interoperable infrastructure.</p>
          <p>One key aspect of PayNetX's monetization strategy is its focus on global financial inclusion, aiming to accelerate the transition to seamless, cost-efficient, and borderless value transfer. The startup targets senior executives in the management, payments, treasury, innovation, and strategy divisions of central and commercial banks worldwide, positioning itself as a Global FinTech leader that offers operational solutions to address challenges in the financial ecosystem.</p>
          <p>PayNetX also engages in Proof of Concept (PoC) initiatives, such as the Instant Cross-Border Settlements PoC, where it invites potential partner banks to participate in demonstrating its capabilities in terms of cost, speed, transparency, and access. By surpassing the targets set in the G20 cross-border payments roadmap without reliance on cryptocurrencies or CBDCs, PayNetX showcases its ability to create a positive global economic impact.</p>
          <p>Furthermore, the startup actively participates in industry initiatives such as Project Agor�, demonstrating its commitment to testing interoperability and bridging existing and upcoming currencies and systems. By showcasing its capabilities in instant cross-border settlement and real-time intraday liquidity management data, PayNetX positions itself as a leading innovator in the financial market infrastructure space.</p>
          <p>Overall, PayNetX's monetization strategy revolves around offering cutting-edge solutions to global financial institutions, leveraging its expertise in DLT technology, cross-border payments, and real-time settlement to drive adoption and generate revenue through partnerships, PoC initiatives, and industry collaborations.</p>
        </div>
      </div>
    );
  }
  
  function Scan3() {
    return (
      <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
        <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Competitive Advantage
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>PayNetX, led by experienced professionals like Manos Batsis, the Co-Founder & CTO, and other key team members, has established a strong competitive advantage in the FinTech industry. The company's innovative payment and technology solutions, particularly the P2DTC (Private-Permission based Decentralised Trigger Chain), showcase its commitment to addressing long-standing challenges in the global financial ecosystem.</p>
          <p>One of PayNetX's key strengths lies in its proprietary Distributed Ledger Technology, which provides a unique Global Underlying Interledger Communication Layer. This technology enables atomic settlement of various forms of value on a single, interoperable infrastructure, offering benefits such as improved performance, automated compliance reporting, reduced operating costs, and real-time liquidity management data.</p>
          <p>Furthermore, PayNetX's proactive approach to industry recognition and participation in initiatives like the Bank for International Settlements (BIS) and the Institute of International Finance's Project Agor� demonstrates its commitment to driving innovation and interoperability in the financial market infrastructure.</p>
          <p>The company's recent initiatives, such as the Instant Cross-Border Settlements Proof of Concept and its focus on enhancing cross-border payments and digital innovation, position PayNetX as a frontrunner in the industry. By surpassing G20 targets and showcasing capabilities in cost-efficiency, speed, transparency, and access without relying on cryptocurrencies or CBDCs, PayNetX is setting new standards in global value transfer.</p>
          <p>Overall, PayNetX's competitive advantage stems from its deep expertise, innovative technology solutions, strategic partnerships, and proactive engagement in industry initiatives. These factors position the company as a leader in accelerating the transition to seamless, cost-efficient, and borderless value transfer, making it a formidable player in the FinTech landscape.</p>
        </div>
  
        <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Barriers to Entry
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Barriers to Entry Analysis:</p>
          <p><strong>1. Regulatory Considerations:</strong> The financial industry is heavily regulated, and any startup entering this market must comply with various regulatory requirements. PayNetX operates in the FinTech sector, which is subject to stringent regulations related to data security, compliance frameworks, and financial standards like ISDA CDM and ISO 20022. Compliance with these regulations can be a significant barrier for new entrants due to the complexity and costs associated with ensuring adherence to regulatory standards.</p>
          <p><strong>2. Technology Expertise:</strong> PayNetX leverages Distributed Ledger Technologies (DLT) and Artificial Intelligence (AI) to develop innovative payment solutions. The expertise required to work with these cutting-edge technologies can be a barrier for new entrants who may not have the necessary skills or experience in this field. Building a team with a deep understanding of blockchain technologies and financial infrastructures is crucial for success in this market.</p>
          <p><strong>3. Capital Requirements:</strong> Developing and testing technology-driven solutions for the global banking community requires significant financial resources. PayNetX's focus on digital transformation and operational solutions necessitates substantial investments in research, development, and infrastructure. Access to capital can be a barrier for startups looking to enter the market and compete with established players like PayNetX.</p>
          <p><strong>4. Market Competition:</strong> PayNetX has already established a strong presence in the market with a customer base of 760,000 users. Competing with a well-known and successful company like PayNetX can be challenging for new entrants trying to gain market share and differentiate their offerings. Building brand recognition and trust among financial institutions and customers is a barrier that startups need to overcome.</p>
          <p><strong>5. Network Effects:</strong> PayNetX's global banking network is rapidly growing, creating network effects that strengthen its position in the market. New entrants may struggle to attract financial institutions to join their network, as established players like PayNetX offer proven solutions and a wide network of partners. Overcoming network effects and building a robust ecosystem of users and partners can be a significant barrier for startups in the financial technology sector.</p>
          <p>In conclusion, entering the market as a FinTech startup like PayNetX involves navigating complex regulatory requirements, building expertise in cutting-edge technologies, securing sufficient capital, competing with established players, and overcoming network effects. Success in this market requires a strategic approach to address these barriers and differentiate offerings to meet the evolving needs of the global financial ecosystem.</p>
        </div>
  
        <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Exit Potential
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>The analysis of the Exit Potential for PayNetX can be evaluated based on various factors highlighted in the provided information. PayNetX, as a Global FinTech leader, is positioned to revolutionize the financial ecosystem with its innovative payment and technology solutions. The company's unique offering, the P2DTC (Private-Permission based Decentralised Trigger Chain), is a custom-built Distributed Ledger Technology that facilitates atomic settlement of traditional and emerging forms of value on a single, interoperable infrastructure. This technology not only improves performance but also automates KYC/AML/CFT reporting, reduces operating costs, and eliminates counterparty risks and settlement delays.</p>
          <p>One key indicator of PayNetX's potential for a successful exit is its industry recognition and acknowledgment of its mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer. The company's involvement in initiatives such as the Bank for International Settlements (BIS) and the Institute of International Finance's Project Agor� demonstrates its commitment to testing interoperability and advancing global financial inclusion.</p>
          <p>Furthermore, PayNetX's strategic partnerships with organizations like R3 Corda Venture Developments showcase its ability to collaborate with industry leaders and leverage resources for growth. The company's focus on enhancing cross-border payments and digital innovation aligns with key themes identified by the BIS Committee on Payments and Market Infrastructures, indicating a strong market fit and potential for scalability.</p>
          <p>In terms of possible exit routes, PayNetX could consider options such as acquisition by a larger financial institution seeking to enhance its technological capabilities, an initial public offering (IPO) to access public markets and raise capital for expansion, or strategic partnerships with global banks and financial institutions to scale its solutions internationally.</p>
          <p>Overall, PayNetX's innovative technology, industry recognition, strategic partnerships, and alignment with global financial trends position it favorably for a successful exit, whether through acquisition, IPO, or strategic partnerships.</p>
        </div>
  
        <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Industry Regulations
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Industry regulations play a crucial role in shaping the operations and compliance requirements for companies like PayNetX operating in the financial technology (FinTech) sector. PayNetX, as a Global FinTech leader, is subject to various regulatory considerations that impact its product development, innovation, and market adoption strategies.</p>
          <p>One key regulatory consideration for PayNetX is related to financial regulations governing payment infrastructure and technology solutions. Given its focus on cross-border payments and digital innovation, PayNetX must adhere to regulations set forth by regulatory bodies such as the Bank for International Settlements (BIS) and the Committee on Payments and Market Infrastructures (CPMI). These regulations aim to ensure transparency, security, and efficiency in payment systems, aligning with PayNetX's mission to accelerate seamless and cost-efficient value transfers globally.</p>
          <p>Additionally, as a provider of Distributed Ledger Technology (DLT) solutions, PayNetX must comply with data protection and privacy regulations to safeguard customer information and ensure regulatory compliance in different jurisdictions. Regulations such as the General Data Protection Regulation (GDPR) in the European Union and data protection laws in other regions require companies to implement robust data security measures and privacy practices.</p>
          <p>Moreover, regulatory frameworks related to anti-money laundering (AML) and counter-terrorism financing (CFT) are critical for PayNetX to prevent financial crimes and ensure compliance with international standards. PayNetX's P2DTC technology must align with AML/CFT reporting requirements and implement KYC (Know Your Customer) procedures to mitigate risks associated with illicit financial activities.</p>
          <p>Furthermore, PayNetX's participation in industry initiatives like Project Agor� and its collaboration with regulatory bodies demonstrate its commitment to regulatory compliance and fostering innovation within a compliant framework. By engaging with regulators and industry stakeholders, PayNetX can navigate complex regulatory landscapes, drive market adoption, and contribute to the evolution of global financial ecosystems in a compliant and sustainable manner.</p>
          <p>In conclusion, PayNetX's success in the FinTech industry hinges on its ability to navigate industry regulations effectively, uphold compliance standards, and leverage regulatory frameworks to drive innovation and market growth. By staying abreast of evolving regulatory requirements and proactively addressing compliance challenges, PayNetX can position itself as a trusted and compliant leader in the global FinTech landscape.</p>
        </div>
        
        <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          VC Fit
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Based on the information provided, PayNetX appears to be a promising candidate for Venture Capital investment due to several key factors. Firstly, the company is led by a team with extensive experience and expertise in the financial technology sector. The Chief Product Owner has a background in innovative payment solutions, while the Co-Founder and CTO has over 20 years of experience in building payment infrastructure, including contributions to R3 Corda Venture Developments, a leading blockchain platform for financial services.</p>
          <p>PayNetX's focus on developing cutting-edge technology solutions, such as its custom-built Distributed Ledger Technology (P2DTC), which facilitates instant settlement and real-time intraday liquidity management, demonstrates its commitment to addressing challenges in the global financial ecosystem. The company's mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer aligns with current industry trends and demands for improved financial infrastructure.</p>
          <p>Furthermore, PayNetX's participation in industry initiatives, such as the Bank for International Settlements' Project Agor�, showcases its commitment to innovation and collaboration within the financial technology space. The company's ability to surpass targets set by global organizations like the G20 in cross-border payments without reliance on cryptocurrencies or stablecoins indicates its potential for significant impact and growth in the industry.</p>
          <p>Overall, PayNetX's strong leadership, innovative technology solutions, industry recognition, and strategic partnerships make it a compelling investment opportunity for Venture Capital firms looking to support a promising FinTech company poised for global leadership and impact.</p>
        </div>
  
        <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Risk Assessment
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>Key risks for PayNetX include technological challenges, regulatory hurdles, and market acceptance.</p>
          <p>Technological risk involves ensuring that their wealth management software is reliable, safe, and scalable.</p>
          <p>Regulatory risk centers around gaining certification and complying with various financial regulations.</p>
          <p>Market risk involves the acceptance of their technology by wealth managers and financial advisors, and potential competition from more established players.</p>
        </div>
  
        <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
          Mitigation Strategies
        </div>
        <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
          <p>To mitigate technological risks, PayNetX could continue partnering with established financial institutions for technical expertise and validation.</p>
          <p>For regulatory risks, proactive engagement with financial authorities and participation in regulatory development is crucial.</p>
          <p>Market risks could be mitigated by diversifying applications and creating strategic partnerships for market access.</p>
        </div>
      </div>
    );
  }
  

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}

function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;