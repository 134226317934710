import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">Salekh Mahmood</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearch')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}
function CompanyLogo() {
  return (
    <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
      <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/dabeb2a2d7165baac53a820b971a560b74d53afaa5f445214b552a9786e223e5?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-full aspect-square"
        />
      </div>
      <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center w-[295px]">
          <div className="text-3xl text-white">
            Jetopterra
          </div>
          <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
            <div className="flex items-center whitespace-nowrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <div>500-1000</div>
            </div>
            <div className="flex items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <div>Aero Technology</div>
            </div>
            <div className="flex items-center text-teal-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                className="my-auto w-3 aspect-square"
              />
              <div>Visit website</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Stats() {
  return (
    <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Valuation</div>
        </div>
        <div className="text-lg text-stone-500">$100M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Latest Raise</div>
        </div>
        <div className="text-lg text-stone-500">$20M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Raised To Date</div>
        </div>
        <div className="text-lg text-stone-500">$300M</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
      <div className="flex flex-col items-center gap-3 max-w-[150px]">
        <div className="flex items-center text-sm text-gray-600">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
            className="shrink-0 aspect-square w-[25px] mr-2"
          />
          <div>Funding Stage</div>
        </div>
        <div className="text-lg text-stone-500">Series B</div>
        <div className="h-[3px] bg-teal-500 w-full" />
      </div>
    </div>
  );
}

function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/specificresult');
  };

  const navigateToScanPage = () => {
    navigate('/scan');
  };

  const navigateToContactPage = () => {
    navigate('/contactpage');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Founders", "Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation", "Network", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }} >
    <div id="founders" className="self-center tracking-normal">
      Founders
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap ">
        <ul>
          <li>
            Dr. Denis Dancanet: As CEO of Jetoptera, Dr. Dancanet brings a
            unique blend of expertise in quantitative finance and computer
            science. He holds a Ph.D. in computer science from Carnegie Mellon
            University and triple majored in computer science, mathematics, and
            philosophy at the University of Pennsylvania. His career trajectory
            includes significant roles in the finance industry, notably as a
            partner at PDT Partners and as a Managing Director at Morgan
            Stanley. This background equips him with a deep understanding of
            complex systems, data analysis, and strategic financial planning,
            crucial for navigating the aerospace startup landscape.
          </li>
        </ul>
        <ul>
          <li>
            Andrei Evulet: Co-founder and the technical brain behind Jetoptera's
            innovations, Evulet's 15 years at GE Aviation are instrumental in
            shaping the company's technological direction. He was pivotal in
            developing technology for the GE9X, the world's largest jet engine,
            used in the Boeing 777X. His extensive experience in aerospace
            engineering, particularly in propulsion systems, positions him as a
            key figure in developing Jetoptera's Fluidic Propulsion System™.
            Evulet's portfolio includes around 40 patents, underscoring his
            creative and technical prowess in aerospace innovations.
          </li>
        </ul>
        <br />
        <span className="font-bold">Competitive Landscape Analysis:</span>
        <ul>
          <li>
            Jaunt Air Mobility: Led by seasoned professionals in aerospace,
            Jaunt benefits from the acquisition of Carter Aviation's IP,
            bringing years of R&D into slowed-rotor technology.
          </li>
        </ul>
        <ul>
          <li>
            Lilium GmbH: Co-founded by aerospace engineers from the Technical
            University of Munich, Lilium's team combines expertise in aviation
            engineering with a fresh perspective from the academic world.
          </li>
        </ul>
        <ul>
          <li>
            Volocopter GmbH: Founded with the vision of creating an electric
            multicopter for urban travel, their team's background is a blend of
            aviation engineering and entrepreneurial spirit.
          </li>
        </ul>
      </div>

      <div id="finances" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Financial Health:
        <br />
        With 660+ investors and nearly $1.5M raised in series B, there is
        significant interest in the company. 
        <br />
        Their revenue last year was around £1,500,000 and is expected to rise
        year on year as the interest in this industry grows. However, due to
        significant operating expenses (almost £3m), their net income showed a
        loss of £1,539,000. Investment into the company would tackle this issue
        of operating expenses.
        <br />
        Jetoptera's financial health is bolstered by strategic partnerships and
        investments, indicative of a robust business model poised for growth in
        the aerospace sector.
        <br />
        While some specific financial details are proprietary, their ability to
        attract partnerships with industry leaders like GE Aviation and
        Honeywell signals strong financial backing and confidence from major
        players.
        <br />
        The company's innovative technology, potential military applications,
        and commercial scalability are likely attracting diverse funding
        sources, essential for long-term financial stability in the high-cost
        aerospace industry.
      </div>
      
      <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Market
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        The eVTOL aircraft market is projected to grow significantly, from
        $11.19 billion in 2022 to an estimated $35.95 billion by 2032. This
        represents a compound annual growth rate (CAGR) of 12.38% over the
        forecast period. This growth is propelled by several factors:
        <br />
        <br />
        Urbanization and Traffic Congestion: With increasing urban population
        and traffic congestion, there is a rising demand for efficient
        transportation solutions. eVTOL aircraft offer a promising alternative
        to traditional modes of transportation, providing faster travel times
        and bypassing ground traffic.
        <br />
        <br />
        Technological Advancements: Improvements in battery technology, electric
        propulsion, and autonomous flight systems are making eVTOL aircraft more
        feasible and efficient.
        <br />
        <br />
        Environmental Concerns: As awareness and regulatory pressure regarding
        environmental issues increase, the demand for cleaner, electrically
        powered vehicles grows. eVTOLs offer a sustainable solution to urban
        transportation challenges.
        <br />
        <br />
        Regulatory Developments: Governments and aviation authorities are
        progressively developing frameworks to integrate eVTOLs into national
        airspace, paving the way for commercial operations.
        <br />
        <br />
        Jetoptera, with its innovative propulsion system, positions itself in a
        niche segment of this market, targeting applications that require
        quieter, safer, and more efficient VTOL capabilities. The company's
        ability to scale its technology from drones to larger passenger aircraft
        widens its market potential.
        <br />
        <br />
        The eVTOL market is characterized by several emerging trends:
        <br />
        Shift Towards Urban Air Mobility (UAM): There is a growing focus on UAM
        as a solution to urban transportation challenges. This involves the
        development of eVTOL aircraft for passenger and cargo transport within
        urban and suburban areas.
        <br />
        Increasing Partnerships and Collaborations: Companies are forming
        strategic alliances for technology sharing, joint ventures, and
        collaborative research and development. These partnerships span across
        industries, including aerospace, automotive, and technology sectors.
        <br />
        Advancements in Autonomous Flight Technology: The integration of AI and
        machine learning in flight control systems is advancing, moving towards
        fully autonomous eVTOL operations. This reduces the reliance on human
        pilots and increases operational efficiency.
        <br />
        Focus on Safety and Noise Reduction: As eVTOLs are designed for
        operation in urban areas, reducing noise pollution and ensuring safety
        in densely populated areas are critical. Technologies that address these
        concerns, like Jetoptera's Fluidic Propulsion System™, are gaining
        traction.
        <br />
        Investment in Infrastructure: Development of vertiports, charging
        stations, and air traffic management systems for eVTOLs is underway,
        indicating a commitment to supporting UAM infrastructure.
        <br />
        Jetoptera's technology aligns well with these trends, particularly in
        noise reduction and efficiency, which are crucial for urban operations.
        <br />
        <br />
      </div>
    </div>
  );
}


function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's innovation centers around its Fluidic Propulsion System™,
        which differentiates it in the eVTOL market. This system offers several
        advantages:
        <br />
        Bladeless Propulsion: Unlike traditional rotors or fans, Jetoptera's
        system uses fluid dynamics for thrust generation, reducing mechanical
        complexity and noise.
        <br />
        Increased Efficiency and Safety: The absence of exposed rotating parts
        enhances safety, especially in urban environments. The propulsion system
        is also more energy-efficient compared to conventional methods.
        <br />
        Scalability: The technology is adaptable to various aircraft sizes, from
        small drones to larger passenger vehicles.
        <br />
        Competitors are innovating in different directions:
        <br />
        Jaunt Air Mobility: Focuses on slowed-rotor technology for enhanced
        efficiency and stability.
        <br />
        Lilium: Utilizes ducted electric vectored thrust for its unique 7-seater
        eVTOL design.
        <br />
        Volocopter: Emphasizes simplicity and redundancy in its multicopter
        designs for urban air taxi services.
        <br />
        Innovation remains a key driver in the eVTOL market, with each company
        bringing unique solutions to the challenges of urban air mobility.
        <br />
      </div>

      <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Traction
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's User Traction:
        <br />
        Jetoptera's approach to gaining market traction is centered around its
        innovative Fluidic Propulsion System™.
        <br />
        The company has generated interest in military and commercial sectors,
        particularly for applications where noise reduction and safety are
        paramount.
        <br />
        Demonstrations of their technology, including a quarter-scale model of
        the J-2000, have showcased the practicality and potential of their
        system.
        <br />
        Their focus now is on scaling up prototypes and partnering with
        established companies like GE Aviation and Honeywell.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Gained traction through its acquisition of Carter
        Aviation's IP, positioning it as a leader in slowed-rotor technology.
        <br />
        Lilium GmbH: Attracted significant investor attention, earning a
        &quot;unicorn&quot; status, with plans for urban air mobility services
        in multiple cities by 2025.
        <br />
        Volocopter GmbH: Achieved several milestones, including crewed flights
        and reservations for demonstration flights, indicating strong user
        interest.
      </div>

      <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Scalability{" "}
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Scalability:
        <br />
        The scalability of Jetoptera's technology lies in its adaptability to
        various aircraft sizes and applications, from drones to manned VTOL
        aircraft.
        <br />
        The modular nature of their propulsion system allows for customization
        based on specific user needs and market demands.
        <br />
        Their partnership strategy, including collaborations with established
        aerospace companies, provides a scalable path for technology development
        and deployment.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Focused on eVTOL aircraft for Advanced Air Mobility
        (AAM), addressing multiple markets with ROSA™ technology.
        <br />
        Lilium GmbH: Targeting mass production with plans to manufacture
        hundreds of aircraft annually by the time commercial services begin.
        <br />
        Volocopter GmbH: Designed for intra-city travel with the potential to
        deploy fleets in urban areas worldwide.
      </div>

      <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Monetisation Strategy:
        <br />
        Jetoptera's primary monetization strategy revolves around licensing its
        Fluidic Propulsion System™ to aerospace manufacturers.
        <br />
        They aim to become the propulsion platform for the next generation of
        air mobility, focusing on both military and commercial sectors.
        <br />
        Revenue streams could include partnerships, technology licensing, and
        potentially direct sales of specialized VTOL aircraft.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Plans to monetize through the manufacture and sale
        of all-electric eVTOL aircraft for various applications.
        <br />
        Lilium GmbH: Intends to operate as both an aircraft manufacturer and an
        air taxi service provider, generating revenue from passenger fares and
        possibly freight services.
        <br />
        Volocopter GmbH: Aims to provide urban air taxi services, leveraging its
        eVTOL technology for passenger transportation within cities
      </div>
    </div>
  );
}


function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Competitive Advantage:
        <br />
        Jetoptera's unique Fluidic Propulsion System™ sets it apart in the eVTOL
        market, offering a quieter and safer alternative to traditional
        propulsion methods.
        <br />
        The company's focus on versatility and scalability provides a
        competitive edge, catering to a wide range of applications.
        <br />
        Their strategic partnerships with industry leaders like GE Aviation and
        Honeywell enhance their credibility and capability in the aerospace
        sector.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Their ROSA™ technology and acquisition of Carter
        Aviation's IP give them an edge in slowed-rotor compound aircraft.{" "}
        <br />
        Lilium GmbH: The unique design of their 7-seater jet and their status as
        a well-funded &quot;unicorn&quot; company position them strongly in the
        urban air mobility market.
        <br />
        Volocopter GmbH: Their focus on safety, simplicity, and noise reduction
        makes their multicopter design attractive for urban air taxi services.
      </div>

      <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Barriers to Entry:
        <br />
        The high cost and complexity of aerospace technology development present
        significant barriers to entry.
        <br />
        Regulatory hurdles, including safety certifications and integration into
        existing air traffic systems, are major challenges.
        <br />
        The need for substantial investment in R&D, testing, and partnerships
        with established players is critical to overcome these barriers.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Faces similar regulatory and technological
        challenges, with an added focus on proving the viability of slowed-rotor
        technology.
        <br />
        Lilium GmbH: Must navigate the complexities of manufacturing and
        operating an entirely new class of eVTOL aircraft, along with
        establishing urban air mobility infrastructure.
        <br />
        Volocopter GmbH: The challenge lies in ensuring the safety and public
        acceptance of their multicopter design, along with meeting regulatory
        standards for urban air taxi services.
      </div>

      <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Potential
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Exit Potential:
        <br />
        The uniqueness of Jetoptera's technology presents significant exit
        potential, either through acquisition by a larger aerospace company or
        an IPO.
        <br />
        The burgeoning interest in VTOL and drone technologies makes them an
        attractive target for companies looking to expand their footprint in
        these sectors. 
        <br />
        The exit strategy could be influenced by the pace of regulatory
        approvals, market adoption, and the evolution of their partnerships.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Acquisition or strategic merger with a larger
        aerospace or transportation company could be a potential exit strategy.
        <br />
        Lilium GmbH: Given its market valuation and investment profile, an IPO
        could be a feasible exit option.
        <br />
        Volocopter GmbH: May explore similar exit strategies, with IPOs being a
        likely route given their market presence and achievements.
      </div>

      <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Regulation
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Regulatory Considerations:
        <br />
        Compliance with aviation regulatory standards is a critical aspect of
        Jetoptera's operations, particularly as they navigate the complexities
        of introducing a new propulsion technology.
        <br />
        Their collaboration with industry leaders suggests a proactive approach
        to meeting safety and certification requirements.
        <br />
        The evolving landscape of eVTOL regulations will significantly impact
        their development timeline and market entry strategy.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Must navigate FAA and other international aviation
        regulations, especially concerning their unique slowed-rotor technology.
        <br />
        Lilium GmbH: Faces challenges in certifying a new aircraft type, with
        stringent safety and environmental standards to meet.
        <br />
        Volocopter GmbH: The company's progress towards achieving EASA
        certification indicates their commitment to complying with regulatory
        requirements for urban air mobility.
      </div>
      
      <div id="network" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Network
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Access to Networks:
        <br />
        Jetoptera's collaborations with GE Aviation and Honeywell provide access
        to extensive networks in the aerospace industry, crucial for technology
        development, regulatory navigation, and market entry.
        <br />
        These partnerships likely offer invaluable insights, resources, and
        credibility, enhancing their market positioning and development
        capabilities.
        <br />
        Access to military and commercial aviation networks through these
        partnerships could significantly accelerate their market penetration and
        adoption.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Connections in the aerospace industry, especially
        following the acquisition of Carter Aviation's IP, provide valuable
        networks for development and market entry.
        <br />
        Lilium GmbH: Their high-profile investors and status as a
        &quot;unicorn&quot; company grant them access to influential networks in
        both the financial and aerospace sectors.
        <br />
        Volocopter GmbH: Demonstrated access to a broad network, including city
        governments and infrastructure partners, essential for their urban air
        taxi service model.
      </div>

      <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's VC Fit:
        <br />
        Jetoptera's innovative technology and market potential make it a strong
        candidate for venture capital (VC) investment.
        <br />
        The company's focus on a disruptive technology in a growing market
        aligns well with VC interests in high-growth potential and scalable
        businesses.
        <br />
        VCs specializing in aerospace, defense, and green technologies would
        find Jetoptera's proposition particularly appealing.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Its advanced eVTOL technology could attract VCs
        interested in sustainable urban mobility solutions.
        <br />
        Lilium GmbH: Already a &quot;unicorn&quot;, Lilium is an attractive
        prospect for VCs focused on cutting-edge technology and large-scale
        market disruption.
        <br />
        Volocopter GmbH: With its urban air taxi vision, Volocopter may appeal
        to VCs interested in new transportation modalities and smart city
        technologies.
      </div>

      <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Risk
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Risk Assessment:
        <br />
        Key risks for Jetoptera include technological challenges, regulatory
        hurdles, and market acceptance.
        <br />
        Technological risk involves ensuring that their propulsion system is
        reliable, safe, and scalable.
        <br />
        Regulatory risk centers around gaining certification for a novel
        aircraft type in various jurisdictions.
        <br />
        Market risk involves the acceptance of their technology by the defense
        and commercial sectors, and potential competition from more established
        players.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Faces similar technological and regulatory risks,
        with additional competition risks in the eVTOL market.
        <br />
        Lilium GmbH: High financial risk due to significant investment
        requirements, alongside regulatory and market risks.
        <br />
        Volocopter GmbH: Risks include regulatory challenges, particularly for
        urban air taxi operations, and technological risks related to the safety
        and efficiency of their multicopter design.
      </div>

      <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Mitigation Strategies
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        Jetoptera's Mitigation Strategies:
        <br />
        To mitigate technological risks, Jetoptera could continue partnering
        with established aerospace companies for technical expertise and
        validation.
        <br />
        For regulatory risks, proactive engagement with aviation authorities and
        participation in regulatory development for eVTOL technologies is
        crucial.
        <br />
        Market risks could be mitigated by diversifying applications (both
        defense and commercial) and creating strategic partnerships for market
        access.
        <br />
        Competitor Analysis:
        <br />
        Jaunt Air Mobility: Could mitigate risks by leveraging its unique
        slowed-rotor technology for specific market niches and focusing on
        regulatory compliance.
        <br />
        Lilium GmbH: Risk mitigation could involve staged development and
        testing to ensure safety and regulatory compliance, alongside strategic
        partnerships for market entry.
        <br />
        Volocopter GmbH: Strategies include early engagement with urban centers
        for infrastructure development and continued focus on safety to gain
        public and regulatory trust.
      </div>
    </div>
  );
}

function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}



function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;