import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-5 pb-3 whitespace-nowrap border-r border-gray-200 border-solid bg-slate-100 fixed top-0 h-screen overflow-y-auto" style={{ width: '220px' }}>
        <div className="self-start ml-9 text-4xl font-bold text-teal-500 lowercase max-md:ml-2.5">
            scoutdd
        </div>
        <div className="flex flex-col px-6 py-2.5 mt-8 text-base font-medium tracking-normal leading-6 text-gray-600 max-md:px-5">
          <div className="flex gap-2 px-6 py-4 text-gray-600 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/dashboard')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e2e62c976263c705a6e49a15e5e5978da178dbf9a1ea2b9f4fe4f743c2fb234?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Dashboard"
                  style={{ filter: 'grayscale(100%) brightness(0)' }} // Black icon for Dashboard
              />
              <div>Dashboard</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-3 text-teal-500 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/searchpage')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/db5049c70dd5e381ab189f1597fec3e845d676f71dbb55647f4e0840f1ff3f8f?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Search"
                  style={{ filter: 'hue-rotate(-50deg) saturate(5)' }} // Green icon for Search
              />
              <div>Search</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/saved')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f566cce3564249f132020cad0707c369ff9c1ab6b2c6796446189c70a0c6b45b?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Saved"
              />
              <div>Saved</div>
          </div>
          <div className="flex gap-2 px-6 py-4 mt-32 hover:bg-teal-100 cursor-pointer max-md:px-5 max-md:mt-10" onClick={() => navigate('/preferences')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/13f0908eca03055832f220e91e48db9d934c4dc4645916019860ceafe0275dbf?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Preferences"
              />
              <div>Preferences</div>
          </div>
          <div className="flex gap-2 px-6 py-4 hover:bg-teal-100 cursor-pointer max-md:px-5" onClick={() => navigate('/settings')}>
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/990baf6f9b4ed52e268d25a8446da9c9dbf8df6275ed345f4f25c37d9b5af7bb?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                  className="shrink-0 w-6 aspect-square"
                  alt="Settings"
              />
              <div>Settings</div>
          </div>
      </div>
    </div>
  );
}
  

function Name() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="flex flex-col justify-center py-6 pr-6 pl-6 text-base font-medium tracking-normal leading-6 border-b border-gray-200 border-solid bg-slate-100 max-w-[255px] text-slate-800">
      <div className="flex gap-1 justify-between pr-2" onClick={toggleDropdown}>
        <div className="flex gap-1">
          <div className="shrink-0 p-2.5 w-9 h-9 bg-neutral-100 rounded-[281.25px]" />
          <div className="my-auto">User Name</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a0bf907e5d8e86a7b00cefabb73532232379fb68b47e69c197b379cff554bf7?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg">
          <ul>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => window.location.href = '/landingpage'}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}


function SearchBar() {
  return (
    <div className="flex items-center justify-start mt-6 px-6 py-1 border-2 border-gray-200 border-solid rounded-[1000px] bg-slate-100" style={{ width: '650px', marginLeft: '0.5cm' }}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d46b1f9b6e689468f0b79ef9e3291d39f6ab665bdf7dd3ac7cf689e4fe3f122?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
        className="aspect-square w-[18px] mr-2"
      />
      <input
        type="text"
        placeholder="Search for the startup, industry, investor you seek"
        className="flex-1 bg-transparent outline-none text-sm text-gray-600"
      />
    </div>
  );
}



function BackTo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-4 mt-8 max-md:flex-wrap cursor-pointer"
      style={{ marginLeft: '2mm' }} 
      onClick={() => navigate('/companysearch')} 
    >
      <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-gray-200 border-solid rounded-[444px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26afbd73eddc22a1ac1bc0d8cf147781eec08626b3a583dc5f4153f10243632?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
          className="w-6 aspect-square"
        />
      </div>
      <div className="flex-1 my-auto text-base tracking-normal leading-6 text-slate-800 max-md:max-w-full">
        Back to Companies
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
      <div className="flex flex-col items-center self-stretch max-w-[727px] mt-10 mx-auto max-md:flex-wrap">
          <div className="flex justify-center items-center bg-white rounded-lg border border-solid border-slate-100 h-[121px] w-[121px]">
              <img
                  loading="lazy"
                  src="https://images.squarespace-cdn.com/content/v1/63d8070df90eaf03f4104837/7887c6f0-2bf9-469a-9867-36bc088042ec/Zyggy+Logo02.png?format=1500w"
                  className="w-full h-full object-contain"
                  alt="Zyggy Logo"
              />
          </div>
          <div className="flex justify-center items-center mt-2 w-full text-justify max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col items-center w-[295px]">
                  <div className="text-3xl text-white">
                      Zyggy
                  </div>
                  <div className="flex flex-col items-center gap-5 mt-8 text-sm text-gray-400">
                      <div className="flex items-center whitespace-nowrap">
                          <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd20dbc5cf66321ebcb16d4e8ef837f103827e1a9db23d35995c352bdbe32ec8?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                              className="my-auto w-3 aspect-square"
                              alt="Team Size Icon"
                          />
                          <div>Small Team</div>
                      </div>
                      <div className="flex items-center">
                          <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe30b371ed1429fbbbf25bb606825372d00a4f17973fbd386d1d6a46b5b7a42a?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                              className="my-auto w-3 aspect-square"
                              alt="Industry Icon"
                          />
                          <div>FinTech</div>
                      </div>
                      <div className="flex items-center text-teal-500">
                          <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7996a04fce68d9fb7c2f2b2928a649866f5a2f2233e3ce8e5b8ff7855f013200?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                              className="my-auto w-3 aspect-square"
                              alt="Website Icon"
                          />
                          <a href="https://www.zyggy.co/" target="_blank" rel="noopener noreferrer" className="ml-2">
                              Visit website
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

function Stats() {
  return (
      <div className="flex justify-center gap-10 mt-8 px-5 max-w-full">
          <div className="flex flex-col items-center gap-3 max-w-[150px]">
              <div className="flex items-center text-sm text-gray-600">
                  <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                      className="shrink-0 aspect-square w-[25px] mr-2"
                  />
                  <div>Valuation</div>
              </div>
              <div className="text-lg text-stone-500">Unknown</div>
              <div className="h-[3px] bg-teal-500 w-full" />
          </div>
          <div className="flex flex-col items-center gap-3 max-w-[150px]">
              <div className="flex items-center text-sm text-gray-600">
                  <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                      className="shrink-0 aspect-square w-[25px] mr-2"
                  />
                  <div>Latest Raise</div>
              </div>
              <div className="text-lg text-stone-500">0</div>
              <div className="h-[3px] bg-teal-500 w-full" />
          </div>
          <div className="flex flex-col items-center gap-3 max-w-[150px]">
              <div className="flex items-center text-sm text-gray-600">
                  <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                      className="shrink-0 aspect-square w-[25px] mr-2"
                  />
                  <div>Raised To Date</div>
              </div>
              <div className="text-lg text-stone-500">Unknown</div>
              <div className="h-[3px] bg-teal-500 w-full" />
          </div>
          <div className="flex flex-col items-center gap-3 max-w-[150px]">
              <div className="flex items-center text-sm text-gray-600">
                  <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2474c621f6293a55565ee6e1a82df92c4da5336473cc45bdf932830c391fdd60?apiKey=f2701ed1217a4202b9e82b22944a8abc&"
                      className="shrink-0 aspect-square w-[25px] mr-2"
                  />
                  <div>Funding Stage</div>
              </div>
              <div className="text-lg text-stone-500">Seed</div>
              <div className="h-[3px] bg-teal-500 w-full" />
          </div>
      </div>
  );
}

function Tabs() {
  const navigate = useNavigate();

  const navigateToSpecificResultPage = () => {
    navigate('/about3F');
  };

  const navigateToScanPage = () => {
    navigate('/Scan3F');
  };

  const navigateToContactPage = () => {
    navigate('/contact3F');
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center gap-4 mt-8 px-3 py-1 max-w-full text-base leading-6 whitespace-nowrap">
        <button onClick={navigateToSpecificResultPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-neutral-400 focus:outline-none">
          About
        </button>
        <button onClick={navigateToScanPage} className="justify-center px-3 py-1 text-teal-500 bg-emerald-400 bg-opacity-20 rounded-[32px] focus:outline-none">
          Scan
        </button>
        <button onClick={navigateToContactPage} className="justify-center px-3 py-1 bg-slate-100 rounded-[32px] text-zinc-500 focus:outline-none">
          Contact
        </button>
      </div>
    </div>
  );
}

function Tags() {
  // List of all tags, capitalized
  const tags = ["Finances", "Market", "Innovation", "Traction", "Scalability", "Monetisation", "Advantage", "Barriers", "Potential", "Regulation", "VC Fit", "Risk", "Mitigation Strategies"];

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clickedIndex, setClickedIndex] = useState(-1);

  // Function to handle right arrow click
  const handleRightClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < tags.length - 1 ? prevIndex + 1 : prevIndex));
  };

  // Function to handle left arrow click
  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  // Number of tags to display at once
  const visibleTagsCount = 5;
  
  // Compute visible tags based on current index
  const visibleTags = tags.slice(currentIndex, currentIndex + visibleTagsCount);

  // Function to scroll to the selected tag with an offset of -80 pixels
  const scrollToTag = (tag, index) => {
    const element = document.getElementById(tag.toLowerCase());
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      // Set the clicked index
      setClickedIndex(index);
    }
  };
  
  return (
    <div className="flex flex-col gap-0 justify-center p-2.5 w-full rounded-xl bg-slate-100 max-md:flex-wrap max-md:max-w-full sticky top-0 z-10" style={{ alignSelf: 'center' }}>
      <div className="flex items-center justify-between w-full p-1">
        {currentIndex > 0 && (
          <button onClick={handleLeftClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
          </button>
        )}
        <div className="flex grow items-center justify-center gap-3">
        {visibleTags.map((tag, index) => (
          <div
            key={tag}
            onClick={() => scrollToTag(tag, index)}
            className={`px-4 py-2 ${
              index === clickedIndex ? 'bg-gray-200' : 'bg-gray-300'
            } text-gray-800 text-bold whitespace-nowrap rounded-full shadow-md cursor-pointer`}
          >
            {tag}
          </div>
        ))}
        </div>
        {currentIndex < tags.length - visibleTagsCount && (
          <button onClick={handleRightClick} className="flex-none px-4 py-3 font-bold text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </button>
        )}
      </div>
    </div>
  );
}


function Scan1() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-12 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="finances" className="self-center tracking-normal">
        Finances
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap">
        <p>Finance - Based on the information provided, ZYGGY FINANCE LIMITED appears to be a FinTech company focused on developing innovative payment and technology solutions, particularly in the area of cross-border payments and peer-to-peer transactions within Sub-Saharan Africa. The company's core product facilitates seamless and efficient financial transactions, enhancing liquidity management in real-time.</p>
        <p>ZYGGY FINANCE LIMITED's mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer aligns with industry trends towards digital innovation in payments, clearing, and settlements. The company's strategic positioning, technological capabilities, and industry recognition indicate a promising outlook for its financial performance.</p>
        <p>ZYGGY FINANCE LIMITED has gained recognition and validation through its participation in prestigious programs like Barclays Eagle Labs and partnerships with global financial institutions. These collaborations not only enhance Zyggy's credibility but also provide access to a broader network and resources, further strengthening its financial standing and growth prospects.</p>
      </div>

      <div id="market" className="gap-0 self-center tracking-normal text-slate-800 max-md:flex-wrap max-md:max-w-full">
        Market Size
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Market Size Analysis:</strong></p>
        <p>Zyggy is a fintech startup focusing on facilitating cross-border payments for Africans in the diaspora and within Sub-Saharan Africa. The company offers services such as P2P payments, virtual cards, and cross-border payment solutions to over 40 African countries and 100 countries worldwide. Zyggy's innovative approach to financial transactions has garnered attention and interest from users globally.</p>
        <p>The market size for cross-border payments and fintech services in Africa is rapidly expanding. According to industry reports, the global fintech market is projected to reach $1.40 billion in 2022, with significant growth expected in the coming years. The increasing demand for digital payment solutions, especially in emerging markets like Africa, presents a lucrative opportunity for companies like Zyggy to capitalize on this growing trend.</p>
        <p><strong>Competitive Analysis:</strong></p>
        <p>In the fintech sector, Zyggy faces competition from both traditional financial institutions and other fintech startups offering similar services. Companies like TransferWise, WorldRemit, and Paystack are some of the key players in the cross-border payment space in Africa. Zyggy's unique selling proposition lies in its focus on the African diaspora and its user-friendly interface that caters to the specific needs of its target market.</p>
        <p><strong>Overall Analysis:</strong></p>
        <p>The market trends indicate a growing demand for digital payment solutions, especially in regions like Africa where traditional banking services may be limited. Zyggy's expansion into cross-border payments and P2P transactions aligns well with these trends, positioning the startup for success in a rapidly evolving market.</p>
        <p>However, to stay competitive and capture a significant market share, Zyggy will need to focus on user acquisition, retention, and building trust among its target audience. Leveraging partnerships with banks, expanding its service offerings, and enhancing its technology infrastructure will be crucial for Zyggy to establish itself as a key player in the fintech industry.</p>
        <p>In conclusion, the market size for fintech services in Africa is growing, presenting a favorable environment for startups like Zyggy to thrive. By capitalizing on market trends, addressing competitive challenges, and continuously innovating its services, Zyggy has the potential to become a leading player in the cross-border payment space in Africa.</p>
      </div>
    </div>
  );
}

function Scan2() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="innovation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Innovation
      </div>
      <div className="justify-center px-5 py-4 mt-2 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Zyggy, a fintech startup, is making waves with its innovative approach to facilitating cross-border payments and peer-to-peer transactions in Sub-Saharan Africa. The startup's key innovation lies in its platform that allows Africans in the diaspora to make direct cross-border payments to bank accounts in 46 countries within the region. This addresses a significant pain point for many individuals who previously faced challenges in sending money back home efficiently and securely.</p>
        <p>Moreover, Zyggy's offering extends to enabling people within Sub-Saharan Africa to make peer-to-peer payments and utilize virtual cards, further enhancing the convenience and accessibility of financial transactions. The platform's availability on both Apple Store and Google Play ensures widespread accessibility to users across different devices and operating systems.</p>
        <p>One of the standout features of Zyggy is its multi-currency wallet that supports international payments, providing users with a seamless experience for conducting transactions globally. The platform also offers a peer-to-peer dollar exchange feature, enhancing its utility for users engaging in international commerce or remittances.</p>
        <p>Zyggy's emphasis on reliability, ease of use, and security sets it apart in the competitive fintech landscape. The startup's commitment to simplifying financial transactions, both locally and internationally, positions it as a game-changer in the industry. With features like free virtual dollar cards, multi-currency wallets, and subscription management capabilities, Zyggy is poised to revolutionize the way individuals and businesses manage their finances.</p>
      </div>

      <div id="traction" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Traction
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>User traction for Zyggy appears to be strong based on the provided information. The company's innovative approach to cross-border payments, peer-to-peer transactions, and virtual cards has attracted significant interest and engagement from its target audience. Zyggy's focus on providing a seamless and secure platform for financial transactions has resonated well with users, leading to increased adoption and user satisfaction.</p>
        <p>Furthermore, Zyggy's active participation in industry initiatives and accelerator programs, such as the Barclays Eagle Labs, demonstrates its commitment to innovation and collaboration within the fintech space. These initiatives provide Zyggy with valuable exposure, resources, and networking opportunities, further enhancing its traction and growth prospects.</p>
        <p>Overall, Zyggy's user traction is supported by its technological advancements, industry recognition, and strategic partnerships, indicating a promising trajectory towards achieving its mission of facilitating efficient and secure financial transactions in Sub-Saharan Africa and beyond.</p>
      </div>

      <div id="scalability" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Scalability
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p><strong>Scalability Analysis:</strong></p>
        <p><strong>Technical Scalability:</strong></p>
        <p>Zyggy's P2DTC (Private-Permission based Decentralised Trigger Chain) is a custom-built Distributed Ledger Technology that facilitates atomic settlement of traditional and emerging forms of value. This technology showcases high technical scalability by improving performance, automating reporting, reducing costs, and providing instant settlement results. The ability to handle a large volume of transactions efficiently demonstrates strong technical scalability.</p>
        <p><strong>User Scalability:</strong></p>
        <p>The company's focus on enhancing cross-border payments and digital innovation aligns with global financial inclusion goals. By surpassing G20 targets and offering instant cross-border settlement solutions, Zyggy shows user scalability by catering to the needs of various central and commercial banks globally. The open call for expression of interest for the Instant Cross-Border Settlements Proof of Concept further indicates a user-centric approach to scalability.</p>
        <p><strong>Operational Scalability:</strong></p>
        <p>Zyggy's emphasis on no failed transactions and limitless opportunities for shopping indicates operational scalability in terms of reliability and efficiency in handling transactions. The platform's ability to connect businesses to the world through cross-border payments highlights operational scalability in facilitating seamless business transactions across borders.</p>
        <p><strong>Data Scalability:</strong></p>
        <p>The use of innovative DLT-powered financial market infrastructures and real-time intraday liquidity management data signifies robust data scalability. Zyggy's capability to deliver instant cross-border settlement results in any currency pair worldwide showcases the handling of vast amounts of data efficiently. The company's focus on interoperability and data management further supports its data scalability.</p>
        <p>Overall, Zyggy exhibits strong scalability across technical, user, operational, and data aspects, positioning itself as a leading Global FinTech player with the potential to drive significant advancements in the global financial ecosystem.</p>
      </div>

      <div id="monetisation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Monetisation Strategy
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Zyggy's monetization strategy revolves around providing a comprehensive suite of financial services to its users, primarily focusing on facilitating cross-border payments and peer-to-peer transactions within Sub-Saharan Africa. The startup offers various features such as virtual cards, multi-currency wallets, and P2P dollar exchange, catering to both Africans in the diaspora and individuals residing within Sub-Saharan Africa.</p>
        <p>One of the key elements of Zyggy's monetization strategy is the provision of value-added services that streamline financial transactions for its users. By offering features like cross-border payments in 40 African countries and access to payments in 100 countries worldwide, Zyggy aims to attract a diverse user base seeking convenient and efficient payment solutions.</p>
        <p>Zyggy employs a freemium model, where users can access basic services for free while premium features such as virtual dollar cards and multi-currency wallets are available for a fee. This pricing model allows Zyggy to generate revenue from users who require advanced functionalities, ensuring a steady income stream for the startup.</p>
        <p>Furthermore, Zyggy leverages partnerships with global financial institutions and accelerators like Barclays Eagle Labs to expand its reach and enhance its credibility in the fintech industry. These collaborations not only provide Zyggy with access to a wider network of users but also open up opportunities for potential revenue-sharing agreements and strategic alliances.</p>
        <p>Overall, Zyggy's monetization strategy is centered around offering a seamless and secure platform for conducting financial transactions, with a focus on generating revenue through premium services, strategic partnerships, and a user-friendly interface. By capitalizing on the growing demand for efficient cross-border payment solutions, Zyggy is well-positioned to capitalize on the evolving fintech landscape in Sub-Saharan Africa and beyond.</p>
      </div>
    </div>
  );
}

  
function Scan3() {
  return (
    <div className="flex flex-col gap-4 px-5 pt-4 mb-10 text-xl font-bold leading-8 text-slate-800 max-w-[840px]" style={{ alignSelf: 'center' }}>
      <div id="advantage" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Competitive Advantage
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Competitive Advantage: Zyggy, a fintech startup, is positioned to gain a competitive advantage in the market through its innovative approach to facilitating cross-border payments and peer-to-peer transactions within Sub-Saharan Africa. The startup's focus on providing Africans in the diaspora with the ability to make direct payments to bank accounts in 46 countries in the region sets it apart from traditional payment platforms. This targeted service caters to a specific demographic with unique needs, giving Zyggy a niche advantage in the market.</p>
        <p>Furthermore, Zyggy's offering of virtual cards and P2P payment capabilities within Sub-Saharan Africa enhances its competitive edge by providing a comprehensive suite of financial services to its users. The availability of the Zyggy app on both Apple Store and Google Play ensures accessibility and convenience for a wide range of users, further solidifying its competitive position in the market.</p>
        <p>In addition, Zyggy's expansion plans to enable cross-border payments in 100 countries worldwide demonstrate its ambition to grow beyond its current market and tap into global opportunities. This strategic vision positions Zyggy as a forward-thinking player in the fintech industry, capable of adapting to evolving market trends and customer needs.</p>
        <p>The startup's emphasis on security, highlighted by its end-to-end security measures and fraud prevention protocols, instills trust and confidence among users, further enhancing its competitive advantage. By offering a seamless and secure platform for various financial transactions, Zyggy differentiates itself from competitors and establishes itself as a reliable and trustworthy financial services provider.</p>
        <p>Overall, Zyggy's unique focus on cross-border payments, virtual cards, P2P transactions, global expansion plans, and strong security measures collectively contribute to its competitive advantage in the fintech market, positioning it for continued growth and success in the future.</p>
      </div>

      <div id="barriers" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Barriers to Entry
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Barriers to Entry Analysis:</p>
        <p><strong>1. Regulatory Considerations:</strong> The financial industry is heavily regulated, and any startup entering this market must comply with various regulatory requirements. ZYGGY FINANCE LIMITED operates in the FinTech sector, which is subject to stringent regulations related to data security, compliance frameworks, and financial standards like ISDA CDM and ISO 20022. Compliance with these regulations can be a significant barrier for new entrants due to the complexity and costs associated with ensuring adherence to regulatory standards.</p>
        <p><strong>2. Technology Expertise:</strong> ZYGGY FINANCE LIMITED leverages Distributed Ledger Technologies (DLT) and Artificial Intelligence (AI) to develop innovative payment solutions. The expertise required to work with these cutting-edge technologies can be a barrier for new entrants who may not have the necessary skills or experience in this field. Building a team with a deep understanding of blockchain technologies and financial infrastructures is crucial for success in this market.</p>
        <p><strong>3. Capital Requirements:</strong> Developing and testing technology-driven solutions for the global banking community requires significant financial resources. ZYGGY FINANCE LIMITED's focus on digital transformation and operational solutions necessitates substantial investments in research, development, and infrastructure. Access to capital can be a barrier for startups looking to enter the market and compete with established players like ZYGGY FINANCE LIMITED.</p>
        <p><strong>4. Market Competition:</strong> ZYGGY FINANCE LIMITED has already established a strong presence in the market with a customer base of 760,000 users. Competing with a well-known and successful company like ZYGGY FINANCE LIMITED can be challenging for new entrants trying to gain market share and differentiate their offerings. Building brand recognition and trust among financial institutions and customers is a barrier that startups need to overcome.</p>
        <p><strong>5. Network Effects:</strong> ZYGGY FINANCE LIMITED's global banking network is rapidly growing, creating network effects that strengthen its position in the market. New entrants may struggle to attract financial institutions to join their network, as established players like ZYGGY FINANCE LIMITED offer proven solutions and a wide network of partners. Overcoming network effects and building a robust ecosystem of users and partners can be a significant barrier for startups in the financial technology sector.</p>
        <p>In conclusion, entering the market as a FinTech startup like ZYGGY FINANCE LIMITED involves navigating complex regulatory requirements, building expertise in cutting-edge technologies, securing sufficient capital, competing with established players, and overcoming network effects. Success in this market requires a strategic approach to address these barriers and differentiate offerings to meet the evolving needs of the global financial ecosystem.</p>
      </div>

      <div id="potential" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Exit Potential
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>The exit potential for Zyggy, a fintech company focusing on cross-border payments and financial services in Africa, appears promising based on its innovative offerings and market positioning. Zyggy's core services include facilitating cross-border payments for Africans in the diaspora to bank accounts in Sub-Saharan Africa, as well as enabling P2P payments, multi-currency wallets, and virtual cards for individuals within the region. These services cater to the growing demand for efficient and secure financial transactions in Africa, a region with significant untapped potential for fintech solutions.</p>
        <p>One key factor contributing to Zyggy's exit potential is its ability to address the pain points of customers, such as delays and failed transactions, by providing a seamless and reliable platform for conducting financial transactions. The company's emphasis on accessibility, ease of use, and a wide range of features, including multi-currency wallets and peer-to-peer exchanges, positions it well to capture a significant market share in the rapidly evolving fintech landscape.</p>
        <p>In terms of possible exit routes, Zyggy could explore various options, including acquisition by a larger financial institution looking to expand its digital payment capabilities in Africa, a strategic partnership with a global payment processor or technology company, or even an initial public offering (IPO) to raise capital for further expansion and growth.</p>
        <p>Furthermore, Zyggy's participation in prestigious programs like the accelerator program and its recognition by industry players like Barclays Eagle Labs indicate a strong foundation and potential for scalability. By leveraging its network, technology, and market insights, Zyggy can continue to enhance its offerings, attract investors, and ultimately position itself for a successful exit in the future.</p>
        <p>Overall, Zyggy's focus on innovation, customer-centric approach, and strategic partnerships bode well for its exit potential, making it an attractive investment opportunity in the fintech sector.</p>
      </div>

      <div id="regulation" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Industry Regulations
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Industry regulations play a crucial role in shaping the operations and compliance requirements for companies like Zyggy operating in the financial technology (FinTech) sector. Zyggy, as a Global FinTech leader, is subject to various regulatory considerations that impact its product development, innovation, and market adoption strategies.</p>
        <p>One key regulatory consideration for Zyggy is related to financial regulations governing payment infrastructure and technology solutions. Given its focus on cross-border payments and digital innovation, Zyggy must adhere to regulations set forth by regulatory bodies such as the Bank for International Settlements (BIS) and the Committee on Payments and Market Infrastructures (CPMI). These regulations aim to ensure transparency, security, and efficiency in payment systems, aligning with ZYGGY FINANCE LIMITED's mission to accelerate seamless and cost-efficient value transfers globally.</p>
        <p>Additionally, as a provider of Distributed Ledger Technology (DLT) solutions, ZYGGY FINANCE LIMITED must comply with data protection and privacy regulations to safeguard customer information and ensure regulatory compliance in different jurisdictions. Regulations such as the General Data Protection Regulation (GDPR) in the European Union and data protection laws in other regions require companies to implement robust data security measures and privacy practices.</p>
        <p>Moreover, regulatory frameworks related to anti-money laundering (AML) and counter-terrorism financing (CFT) are critical for ZYGGY FINANCE LIMITED to prevent financial crimes and ensure compliance with international standards. ZYGGY FINANCE LIMITED's P2DTC technology must align with AML/CFT reporting requirements and implement KYC (Know Your Customer) procedures to mitigate risks associated with illicit financial activities.</p>
        <p>Furthermore, ZYGGY FINANCE LIMITED's participation in industry initiatives like Project Agor� and its collaboration with regulatory bodies demonstrate its commitment to regulatory compliance and fostering innovation within a compliant framework. By engaging with regulators and industry stakeholders, ZYGGY FINANCE LIMITED can navigate complex regulatory landscapes, drive market adoption, and contribute to the evolution of global financial ecosystems in a compliant and sustainable manner.</p>
        <p>In conclusion, ZYGGY FINANCE LIMITED's success in the FinTech industry hinges on its ability to navigate industry regulations effectively, uphold compliance standards, and leverage regulatory frameworks to drive innovation and market growth. By staying abreast of evolving regulatory requirements and proactively addressing compliance challenges, ZYGGY FINANCE LIMITED can position itself as a trusted and compliant leader in the global FinTech landscape.</p>
      </div>

      <div id="vc fit" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        VC Fit
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Based on the information provided, Zyggy appears to be a promising candidate for Venture Capital investment due to several key factors. Firstly, the company is led by a team with extensive experience and expertise in the financial technology sector. The Chief Product Owner has a background in innovative payment solutions, while the Co-Founder and CTO has over 20 years of experience in building payment infrastructure, including contributions to R3 Corda Venture Developments, a leading blockchain platform for financial services.</p>
        <p>ZYGGY FINANCE LIMITED's focus on developing cutting-edge technology solutions, such as its custom-built Distributed Ledger Technology (P2DTC), which facilitates instant settlement and real-time intraday liquidity management, demonstrates its commitment to addressing challenges in the global financial ecosystem. The company's mission to accelerate the world's transition to seamless, cost-efficient, and borderless value transfer aligns with current industry trends and demands for improved financial infrastructure.</p>
        <p>Furthermore, ZYGGY FINANCE LIMITED's participation in industry initiatives, such as the Bank for International Settlements' Project Agor�, showcases its commitment to innovation and collaboration within the financial technology space. The company's ability to surpass targets set by global organizations like the G20 in cross-border payments without reliance on cryptocurrencies or stablecoins indicates its potential for significant impact and growth in the industry.</p>
        <p>Overall, ZYGGY FINANCE LIMITED's strong leadership, innovative technology solutions, industry recognition, and strategic partnerships make it a compelling investment opportunity for Venture Capital firms looking to support a promising FinTech company poised for global leadership and impact.</p>
      </div>

      <div id="risk" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Risk Assessment
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>Key risks for ZYGGY FINANCE LIMITED include technological challenges, regulatory hurdles, and market acceptance.</p>
        <p>Technological risk involves ensuring that their payment solutions are reliable, safe, and scalable.</p>
        <p>Regulatory risk centers around gaining certification and complying with various financial regulations.</p>
        <p>Market risk involves the acceptance of their technology by financial institutions and individual users, and potential competition from more established players.</p>
      </div>

      <div id="mitigation strategies" className="gap-0 self-center tracking-normal max-md:flex-wrap max-md:max-w-full">
        Mitigation Strategies
      </div>
      <div className="justify-center px-5 py-4 w-full text-base font-light text-black rounded-xl border-2 border-gray-200 border-solid max-md:flex-wrap max-md:max-w-full">
        <p>To mitigate technological risks, ZYGGY FINANCE LIMITED could continue partnering with established financial institutions for technical expertise and validation.</p>
        <p>For regulatory risks, proactive engagement with financial authorities and participation in regulatory development is crucial.</p>
        <p>Market risks could be mitigated by diversifying applications and creating strategic partnerships for market access.</p>
      </div>
    </div>
  );
}

  
function RefactoredFooter() {
  return (
<footer class="flex justify-center items-center px-16 py-10 text-teal-500 bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-bold lowercase text-4xl">
        scoutdd
      </div>
      <div class="col-md-8 flex justify-end">
      <div className="flex gap-5 bg-white">
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Menu
              </div>
              <div className="mt-1">Home</div>
              <div className="mt-1">About</div>
            </div>
            <div className="flex-auto flex-col self-start text-base tracking-tight">
              <div className="text-2xl font-medium tracking-tighter leading-7">
                Contact
              </div>
              <div className="mt-1">London</div>
              <div className="mt-1">info@scoutdd.com</div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-0 pb-0">
      <div class="col text-center ">
        <p className="m-0">&copy; 2023 Company Name. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
);
}



function ParentLayout() {
  return (
    <div className="flex bg-slate-100 min-h-screen">
      <Sidebar />
      <div className="flex flex-col grow pl-[220px]">
              <div className="flex justify-between">
          <SearchBar />
          <Name />
        </div>
        <BackTo/>
        <CompanyLogo/>
        <Stats/>
        <Tabs/>
        <Tags/>
        <Scan1/>
        <Scan2/>
        <Scan3/>
        <RefactoredFooter />
      </div>
      </div>
  );
}

export default ParentLayout;